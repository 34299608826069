import * as React from 'react';
import { Grid } from '@mui/material';
import '../../css/terms-and-conditions.scss';

const Row = ({ info }) => {
    const { heading, details } = info;
    return (
        <Grid container className='tac-table-info-row'>
            <Grid item className='tac-table-info-row-heading' xs={6} sm={6} md={6} lg={6}>{heading}</Grid>
            <Grid item className='tac-table-info-row-details' xs={6} sm={6} md={6} lg={6}>{details}</Grid>
        </Grid>
    );
}

const TableInfo = ({ data })=>{
    return (
        <Grid container className='tac-table-info-table'>
            {data.map((item, index) => (<Row key={index} info={item} />))}
        </Grid>
    );
}

const SubBasicInfo = ({key,info}) =>{
    const { heading, details, tabledata } = info;
    return (
        <Grid key={key} className='tac-sub-basic-info'>
            <Grid className='tac-sub-basic-info-heading'>{heading}</Grid>
            <div className='tac-sub-basic-info-details' dangerouslySetInnerHTML={{ __html: details }} />
            {
                tabledata.length > 0 ?<TableInfo data={tabledata} /> :""
            }
        </Grid>
    )
}

const SubBasicInfo2 = ({ key, info }) => {
    const { heading, details, data, className } = info;
    return (
        <Grid key={key} className={`tac-sub-basic-info2 ${className}`}>
            <Grid className='tac-sub-basic-info2-heading'>{heading}</Grid>
            {details && <Grid className='tac-sub-basic-info2-details' dangerouslySetInnerHTML={{ __html: details }} />}
            {
                data ? data.map((item, index) => {  
                    return (<SubBasicInfo key={index} info={item} />);
                }): ""
            }
        </Grid>
    )
}

const BasicInfo = ({ key, info })=>{
    const { heading, data } = info;
    return (
        <Grid container key={key} className='tac-basic-info'>
            {heading ? <Grid item className='tac-basic-info-heading'>{heading}</Grid>: ""}
            {
                data.map((item, index) => {
                    return (<SubBasicInfo2 key={index} info={item} />)
                })
            }
        </Grid>
    );
}

export default BasicInfo;