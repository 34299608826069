import { ActionTypesList } from "../common/contstants";

const blogsReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_REQUEST:
            return { ...state, blogList: { isFetching: true, list: [] } }
        case ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_FAILED:
            return { ...state, blogList: { isFetching: true, list: [] } }
        case ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_SUCCESS:
            return { ...state, blogList: { isFetching: true, list: action.data } }
        case ActionTypesList.FETCH_HOME_BLOGS:
            return {...state, homeBlogList: action.data}
        default:
            return state;
    }
}

export default blogsReducer;