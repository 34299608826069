import { Button, Grid } from '@mui/material';
import * as React from 'react';
import '../../css/position-banner.scss'
import { useNavigate } from 'react-router';
import { getRoutePath, mapLevel, mapPosLocation, mapYears } from '../../common/contstants';
import downArrow from '../../assets/Home/Down.svg'
import arrowPrev from '../../assets/arrow-previous.svg';
import { useIntl } from 'react-intl';
function JobBanner(props) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { job: { position, experience, employmentType, languages, desiredSkills } } = props;
  const splitData = position.split(' ');
  return (
    <div className='job-banner-container'>
      <div className='job-banner-bg'></div>
      <div className='job-banner'>
        <Button className='btn-back-all-pos' onClick={() => navigate(getRoutePath('career'))}>{formatMessage({ id: "job_detail_button" })}</Button>
        <Button className='btn-back-all-pos-icon' onClick={() => navigate(getRoutePath('career'))}>
          <img src={arrowPrev} alt='back' />
        </Button>

        <Grid container
          alignItems={'center'}
          style={{ marginTop: '2%' }}
        >
          <Grid md={6} lg={7} sm={12} xs={12}>
            <div className='lbl-job-title'>
              <span>{splitData[0]}</span>&nbsp;
              <span>
                {splitData.slice(1).join(' ')}
              </span>
            </div>
          </Grid>
          <Grid md={6} lg={5} sm={12} xs={12}>
            <div className='banner-high-card'>
              <Grid container rowGap={3}>
                <Grid lg={12} md={12} sm={12} xs={12} className='job-row-wrapper'>
                  <div className='high-title'>{formatMessage({ id: "job_detail_list_1" })}:</div>
                  <div className='high-title-value'>{mapPosLocation[employmentType]}</div>
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} className='job-row-wrapper'>
                  <div className='high-title'>{formatMessage({ id: "job_detail_list_2" })}:</div>
                  <div className='high-title-value'>{mapYears[experience]}</div>
                </Grid>
                {
                  desiredSkills && desiredSkills.map((aInfo, index) => {
                    return <Grid key={index} lg={12} md={12} sm={12} xs={12} className='job-row-wrapper'>
                      <div className='high-title'>{aInfo.skill}:</div>
                      <div className='high-title-value'>{mapYears[aInfo.experience] || aInfo.experience}</div>
                    </Grid>
                  })
                }
                {
                  languages && languages.map((aInfo, index) => {
                    return <Grid key={index} lg={12} md={12} sm={12} xs={12} className='job-row-wrapper'>
                      <div className='high-title'>{aInfo.name[0].toUpperCase()}{aInfo.name.slice(1)}:</div>
                      <div className='high-title-value'>{mapLevel[aInfo.level]}</div>
                    </Grid>
                  })
                }
              </Grid>

            </div>
          </Grid>
        </Grid>

      </div >
      <div className='down-arrow'>
        <a href='#jobDetail' alt="Navigate to parners">
          <img className='down-arrow-img' src={downArrow} alt="down arrow" />
        </a>
      </div>
    </div>

  );
}
export default JobBanner;
