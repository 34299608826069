import { connect } from 'react-redux';
import { getClients, getPartners } from '../Actions/clients';
import { getHomeActiveWorkList } from '../Actions/works';
import { DefaultCTAUpdated } from '../common/contstants';
import About from '../components/About';

const ourWorkCardLimit = 2;

const mapStateToProps = state => ({
    clients: state.home.clients,
    partners: state.home.partners,
    worksList: state.home.worksList,
    CTA: DefaultCTAUpdated
})

const mapDispatchToProps = (dispatch) => ({
    getClients: () => dispatch(getClients()),
    getPartners: (type) => dispatch(getPartners(type)),
    getHomeActiveWorkList: () => dispatch(getHomeActiveWorkList(ourWorkCardLimit))
})

export default connect(mapStateToProps, mapDispatchToProps)(About);