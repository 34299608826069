import { ActionTypesList, apiURls } from "../common/contstants"

export function registerContact(payload) {
    const url = apiURls.registerContact();

    var requestOptions = {
        method: 'POST',
        body: payload
    };
    console.log('-=-=-requestoptions=-', requestOptions, payload)
    return (dispatch) => {
        fetch(url,
            {
                mode: 'cors',
                ...requestOptions

            })
            .then(response => response.json())
            .then(res => {
                let contactRes = {}
                if (res.errors) {
                    contactRes.error = res.errors[0].userMessage
                } else {
                    contactRes = res;

                }
                dispatch({
                    type: ActionTypesList.CREATE_CONTACT_RESPONSE,
                    data: contactRes
                })
                setTimeout(() => {
                    dispatch({
                        type: ActionTypesList.CREATE_CONTACT_RESPONSE,
                        data: {}
                    })
                }, 10000)
            })
            .catch((err) => {
                dispatch({
                    type: ActionTypesList.CREATE_CONTACT_RESPONSE,
                    data: {
                        error: err.message
                    }
                })
                setTimeout(() => {
                    dispatch({
                        type: ActionTypesList.CREATE_CONTACT_RESPONSE,
                        data: {}
                    })
                }, 10000)
            })
    }
    // return (dispatch) => {
    //     dispatch({
    //         type: ActionTypesList.CREATE_CONTACT_RESPONSE,
    //         data: []
    //     })
    // }
}