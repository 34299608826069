import { ActionTypesList } from "../common/contstants";

const worksReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypesList.ALL_ACTIVE_WORKS_LIST_REQUEST:
        case ActionTypesList.WORK_LIST_BY_SERVICEID_FETCH_REQUEST:
            return { ...state, worksList: { isFetching: true, list: [] } }
        case ActionTypesList.ALL_ACTIVE_WORKS_LIST_FAILED:
        case ActionTypesList.WORK_LIST_BY_SERVICEID_LIST_REQUEST_FAILED:
            return { ...state, worksList: { isFetching: true, list: [] } }
        case ActionTypesList.ALL_ACTIVE_WORKS_LIST_SUCCESS:
        case ActionTypesList.WORK_LIST_BY_SERVICEID_REQUEST_SUCCESS:
            return { ...state, worksList: { isFetching: true, list: action.data } }
        default:
            return state;
    }
}

export default worksReducer;