function StyleOnTouchEvent(classList) {
    const arrowElement = document.querySelectorAll(classList);

    if (arrowElement) {
        for (let i = 0; i < arrowElement.length; i++) {
            arrowElement[i].addEventListener('touchstart', () => {
                arrowElement[i].classList.add('on-touch');
            });
        
            arrowElement[i].addEventListener('touchend', () => {
                arrowElement[i].classList.remove('on-touch');
            });
        }
        return arrowElement;
    }
    
}

export default StyleOnTouchEvent;