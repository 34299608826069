import { Component } from "react";
import Banner from "./Banner";
import '../../css/blog.scss';
import CTAComponentUpdated from "../General/CTAComponentUpdated";
import StoryList from './StoryList';
import _ from 'lodash';
import { HelmetWrapper } from "../HelmetWrapper";

export default class Work extends Component {
    metaData = {
        title: 'blog_page_title', 
        description: 'blog_page_description', 
        isStatic: true
    }
    componentDidMount() {
        this.props.fetchActiveBlogList();
    }

    render() {
        const { CTA, blogList } = this.props;
        return (
            <div className="root-blog">
                <HelmetWrapper data={this.metaData} />
                <Banner />
                <StoryList storyListData={!_.isEmpty(blogList) ? _.get(blogList, 'list', []) : []} />
                {CTA && <CTAComponentUpdated info={CTA} />}
                {/* <HomeSignUp /> */}
            </div>
        )
    }
}