import { Button } from '@mui/material';
import * as React from 'react';
import '../../css/career-banner.scss'
import { useNavigate } from 'react-router';
import downArrow from '../../assets/Home/Down.svg'
import { getRoutePath } from '../../common/contstants';
import { useIntl } from 'react-intl';
function CareerBanner() {
  const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const title = "career_banner_title";

    const navToPos = () => {
        document.getElementById("career-jobs").scrollIntoView();
    }
    const path = getRoutePath('contact');
    return (
        <div className='career-banner'>
            <div className='career-banner-title'>
                <div data-aos="fade-up" className='c-title fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({id:title}) }}></div>
            </div>
            <div data-aos="fade-up" className='career-banner-desc fade-el'>
                {formatMessage({id:'career_banner_desc'})}
            </div>
            <div>
            <Button className='btn-see-pos' onClick={navToPos}>{formatMessage({id:'career_banner_button1'})}</Button>
            <Button className='btn-talk' onClick={() => navigate(`${path}`)}>{formatMessage({id:'career_banner_button2'})}</Button>
            </div>
            <div className='down-arrow'>
                <a href='#partners' alt="Navigate to parners">
                    <img className='down-arrow-img' src={downArrow} alt="down arrow" />
                </a>
            </div>
        </div >
    );
}
export default CareerBanner;
