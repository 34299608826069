import { Grid, Button } from '@mui/material';
import * as React from 'react';
import '../../css/vision.scss';
import { useIntl } from 'react-intl';
export default function Vision({ blogs }) {
  // const url = 'https://skiy31.de/de/gespraech-mit-kursfinder/'
  const blog = blogs[0]
  const { formatMessage } = useIntl();
  return (
    <div id="vision">
      <div className='bg-gradiant-vision'></div>
      <div data-aos="fade-up" className='vision-title fade-el'>
        { formatMessage({ id: "vision_title" }) }
      </div>
      <div className='vision-card'>
        <Grid container>
          <Grid container md={6} sm={6} lg={6} className="vision-text-container">
            <Grid data-aos="fade-up" md={12} sm={12} lg={12} className='vision-subheading'>
              {/* 5 MIN READ */}
              {blog.reading_time}
            </Grid>
            <Grid className='vision-heading' >
              <span data-aos="fade-up" className='cursor fade-el' onClick={() => window.open(blog.url, '_blank')} dangerouslySetInnerHTML={{ __html: blog.title }}></span>
            </Grid>
            <Grid className='vision-content'>
              <span data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: blog.description }}></span>
              {/* We thought we were prepared for anything. We thought absolutely nothing can go wrong… */}
            </Grid>
            <Grid className='vision-button-container'><Button className='vision-button' onClick={() => window.open(blog.url, '_blank')}> { formatMessage({ id: "read_more" }) }</Button></Grid>
          </Grid>
          <Grid md={6} sm={6} lg={6} className="vision-image-container">
            <img src={blog.blog_image} className='vision-image' style={{}} alt="Vision" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}