import React, { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import OurWorkList from '../Home/OurWorkList';
import { HelmetWrapper } from "../HelmetWrapper";
import { Tags } from '../General';
import '../../css/all-works.scss';
import { useIntl } from 'react-intl';
export default function AllWorks({ ourWorkData }) {
  const { formatMessage } = useIntl();
  const [selectedTag, setTag] = useState("all");
  const [workList, updateWorkList] = useState(ourWorkData);
  const [showMenu, setMenuStatus] = useState(false);
  const [metaData, setMetaData] = useState({ title: '', description: '', isStatic: false });

  useEffect(() => {
    setMetaData({ title: 'work_page_title', description: 'work_page_description', isStatic: true });
  }, [ourWorkData]);

  const toggleMenu = () => {
    setMenuStatus(!showMenu)
  };

  const selectWorkList = (tag) => {
    setTag(tag);
    onMenuChange(tag);
  }
  const onMenuChange = (tag) => {
    if (tag !== "all") {
      let selectedWorkList = ourWorkData.filter((work) => {
        for (let item of work.tags.split(",")) {
          if (item.trim().toLowerCase().indexOf(tag.toLowerCase()) >= 0) {
            return true;
          }
        }
        return false;
      });
      updateWorkList([...selectedWorkList]);
    }
    else {
      updateWorkList([...ourWorkData]);
    }
  }
  return (
    <div id="all-works">
      <HelmetWrapper data={metaData} />
      <Stack direction='row' className='all-work-tags-stack desktop-menu' columnGap={4} rowGap={{ sm: 3 }} alignContent='center' flexWrap={'wrap'}>
        {
          Object.keys(Tags).map((key) => (
            <Button key={key} className={`our-work-button ${selectedTag === key ? 'all-work-selected-button' : ''}`} onClick={() => { selectWorkList(key) }}>
              {formatMessage({ id: Tags[key] })}
            </Button>))
        }
      </Stack>
      <Stack className='all-work-tags-stack mobile-menu' columnGap={4} rowGap={{ sm: 3 }} alignContent='center' flexWrap={'wrap'}>


        <div id="custom-select" className={`custom-select ${showMenu ? 'open' : ''}`}>

          <summary className="radios" onClick={toggleMenu}>
            {
              Object.keys(Tags).map((key, index) => {
                return (
                  index === 0 && selectedTag === 'all'
                    ? (<input
                      key={index}
                      type="radio"
                      name="item"
                      id={`item-${index}`}
                      title={formatMessage({ id: `${Tags[key]}` })}
                      value={formatMessage({ id: `${Tags[key]}` })}
                      onChange={e => { }}
                      checked
                    />)
                    : <input
                      key={index}
                      type="radio"
                      name="item"
                      id={`item-${index}`}
                      title={formatMessage({ id: `${Tags[key]}` })}
                      value={formatMessage({ id: `${Tags[key]}` })}
                    />
                )
              })
            }
          </summary>
          <ul className={`work-list ${showMenu ? 'open' : ''}`}>
            {
              Object.keys(Tags).map((key, index) => (
                <li key={index} onClick={() => { selectWorkList(key) }}>
                  <label htmlFor={`item-${index}`}>{formatMessage({ id: Tags[key] })}</label>
                </li>
              ))
            }
          </ul>
        </div>

      </Stack>
      <OurWorkList ourworklist={workList} />
    </div>
  )
}