import * as React from 'react';
import { Grid } from '@mui/material';
import Services from './Services';
import remoteTeams from '../../assets/Home/remote-team.jpeg';
import software from '../../assets/Home/software-development.svg';
import branding from '../../assets/Home/Branding.svg';
import uiux from '../../assets/Home/ui-ux-design.jpeg';
import { useIntl } from 'react-intl';

// const RemoteTeams = (<img src={remoteTeams} className='service-image-remote-teams' style={{ width: "100%", boxSizing: "border-box", maxHeight: "599px", maxWidth: "462px" }} alt="remote teams" />);
// const Software = (<img src={software} className='service-image-software' style={{ width: "100%", boxSizing: "border-box", maxHeight: "521px", maxWidth: "521px" }} alt="software" />);
// const UIUX = (<img src={uiux} className='service-image-uiux' style={{ width: "100%", boxSizing: "border-box", maxHeight: "559px", maxWidth: "523px" }} alt="ui ux" />);
// const Branding = (<img src={branding} className='service-image-branding' style={{ width: "100%", boxSizing: "border-box", maxHeight: "516px", maxWidth: "475px" }} alt="branding" />);


const PARTICLE_CONFIG = {
  maxWidth: 250,
  mouseForce: "-3",
  particleSize: 2,
  renderer: "webgl",
  color: "#fff",
  particleGap: 2,
};

const ServicesData = [
  {
    id: "remoteTeams",
    // heading: "Remote teams",
    heading: 'service_remote_teams_text',
    tags: ["development", "project management", "design"],
    description: "service_remote_teams_desc",
    buttons: [{ text: "service_remote_teams_button", linkText: 'Remote teams' }],
    image: remoteTeams,
    customeParticle: PARTICLE_CONFIG
  },
  {
    id: "software",
    // heading: "Software development",
    heading: "service_soft_dev_text",
    tags: [],
    description: "service_soft_dev_desc",
    buttons: [{ text: "service_soft_dev_button1", linkText: 'Website' }, { text: "service_soft_dev_button2", linkText: 'service_soft_dev_button2' }, { text: "service_soft_dev_button3", linkText: 'service_soft_dev_button3' }, { text: "service_soft_dev_button4", linkText: 'e-commerce' }],
    image: software,
    customeParticle: PARTICLE_CONFIG
  },
  {
    id: "uiux",
    // heading: "UI/UX Design",
    heading: "service_ui_ux_text",
    tags: [],
    description: "service_ui_ux_desc",
    buttons: [{ text: "service_ui_ux_button", linkText: 'UI/UX' }],
    image: uiux,
    customeParticle: PARTICLE_CONFIG
  },
  {
    id: "branding",
    // heading: "Brand Strategy & Identity",
    heading: "service_branding_text",
    tags: ["brand strategy", "brand identity"],
    description: "service_branding_desc",
    buttons: [{ text: "service_branding_button", linkText: 'Branding' }],
    image: branding,
    customeParticle: PARTICLE_CONFIG
  }
];

export default function ServiceList(props) {
  const { services } = props;
  const { formatMessage } = useIntl();
  if (ServicesData.length > 0) {
    return (
      <Grid container id="service-list" className='service-list '>
        <Grid data-aos="fade-up" className='heading fade-el' md={12} lg={12} sm={12} xs={12}>{ formatMessage({ id: 'service_title' })}</Grid>
        <Grid container rowSpacing={2} rowGap={{ lg: 16, md: 0, xs: 6, sm: 0 }} className='services'>
          {
            ServicesData.map((service) => {
              const { id } = service;
              return (
                <Services key={id} service={service} services={services} />
              )
            })
          }
        </Grid>
      </Grid>
    )
  }
}