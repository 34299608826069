import * as React from 'react';
import CareerConnect from './CareerConnect';
import CareerContactForm from './ContactForm';
import '../../css/career-contact.scss'

function CareerContact(props) {

    return (
        <div className='career-contact'>
            {/* <Grid container>
                <Grid md={12}>
                    <CareerConnect />
                </Grid>
                <Grid md={12} >
                        <CareerContactForm />
                </Grid>
            </Grid> */}
            <div className='contact-container'>
                <CareerConnect />
            </div>
            <div className='contact-form-container'>
                <CareerContactForm {...props} />
            </div>
        </div>

    );
}
export default CareerContact;