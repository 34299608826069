import { Component } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import AboutBanner from "../About/Banner";
import HomePartners from "../Home/Partners";
import OurWorkList from "../Home/OurWorkList";
import Clients from "../Home/Clients";
import '../../css/about.scss'
import AboutEmployees from "./Employees";
import CTAComponentUpdated from "../General/CTAComponentUpdated";
import AboutFounder from "./Founder";
import AboutOffices from "./Offices";
import Focus from "./Focus";
import AboutVideo from "./Video";
import particlesData from '../../common/particlesjs-config.json';
import _ from 'lodash';
import { HelmetWrapper } from "../HelmetWrapper";

export default class About extends Component {
    metaData = {
        title: 'about_page_title', 
        description: 'about_page_description', 
        isStatic: true
    }
    particlesInit = async (main) => {
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets 
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready 
        // starting from v2 you can add only the features you need reducing the bundle size 
        await loadFull(main);
    };
    particlesLoaded = (container) => {
        // console.log(container);
    };
    componentDidMount() {
        this.props.getClients();
        this.props.getPartners('all');
        this.props.getHomeActiveWorkList();
    }
    render() {
        const { clients, partners, CTA, worksList } = this.props;
        const ourworkList = _.get(worksList, 'list', []);
        return (
            <div className="root-about">
                <HelmetWrapper data={this.metaData} />
                <div className="about-bg1">
                    <Particles
                        id="tsparticles"
                        init={this.particlesInit}
                        loaded={this.particlesLoaded}
                        height='100%'
                        width="100%"
                        options={particlesData}
                    />
                    <div className="about-bg2">
                        <div className="top-bg">
                            <AboutBanner />
                            <HomePartners partners={partners} limit={6}/>
                        </div>
                    </div>
                </div>
                {/* <SvgLineNavigation
                    viewBox="0 0 1796 15775"
                    pathConfig={{
                        d:"M952 0C952 87 960.402 239.6 886.002 328C793.002 438.5 723.502 525.5 819.002 642.5C914.502 759.5 1055.5 841.5 1044.5 969.5C1033.5 1097.5 821.707 1118.54 780.002 1195C738.001 1272 818.502 1362 931.502 1399C1044.5 1436 1107.5 1565.5 1044.5 1646C981.502 1726.5 792.53 1785.04 766.501 1879C746.001 1953 801.502 1993.5 903.502 2021.5C1005.5 2049.5 1170 2206 1170 2461.5C1170 2717 523.5 2914 397 3094C279.168 3261.67 359.502 3630 658.502 3792.5C957.502 3955 1558 3918.5 1543 4332.5C1528 4746.5 782.002 4685.5 788.502 5011C795.002 5336.5 1324.5 5581.5 1315.5 5846C1306.5 6110.5 493.502 6377 181.502 6654.5C-130.498 6932 33.8076 7084.27 148.501 7110.5C295.002 7144 1726 7219.5 1788 7530.5C1840.64 7794.53 1229 7867 1030.5 7945C861.1 8011.57 673.001 8222.5 797.001 8463C921.001 8703.5 1412.5 8870.5 1402 9245.5C1391.5 9620.5 630.001 9757 550.001 9967.5C470.001 10178 1116.5 10249.5 1107.5 10442.5C1098.5 10635.5 336 10846 346.5 11203.5C357 11561 1725.5 11304.5 1792.5 11576C1859.5 11847.5 190 11949.5 125 12118.5C60.0001 12287.5 584.501 12577.5 578.001 12838C571.501 13098.5 21.0004 13544.5 157.5 13818C294 14091.5 933.501 14130 925.001 14444.5C924.667 14606 853.8 14992.7 573 15247.5C292.2 15502.3 605.667 15704.7 797.5 15774",
                    }}
                /> */}
                <Focus />
                <AboutFounder />
                <AboutVideo />
                <AboutEmployees />
                <AboutOffices />
                {clients && partners &&  <Clients clients={clients} partners={partners} />}
                <OurWorkList ourworklist={ourworkList} showTitle={true} showButton={true} />
                { CTA && <CTAComponentUpdated info={CTA} /> }
                {/* <HomeSignUp /> */}
            </div>
        )
    }
}