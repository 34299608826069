import { ActionTypesList } from "../common/contstants";

const serviceReducer = (state = { serviceInfo: {}, servicesList: [] }, action) => {
    switch (action.type) {
        case ActionTypesList.SUCCESS_FETCH_SERVICE_DETAILS:
            return { ...state, serviceInfo: action.data }
        case ActionTypesList.SUCCESS_FETCH_SERVICE_LIST:
            return { ...state, servicesList: JSON.parse(JSON.stringify(action.data)) }
        default:
            return state;
    }
}

export default serviceReducer;