import * as React from 'react';
import '../../css/cs-major-tasks.scss'
import { Grid } from '@mui/material';

function MajorTasks(props) {
    const { data: { title, sub_title, sub_content, data } } = props;
    return (
        <div className='cs-major-tasks'>
            <div data-aos="fade-up" className='title fade-el'>{title}</div>
            {(sub_title || sub_content) && <div data-aos="fade-up" className='desc fade-el'>{sub_title || sub_content}</div>}
            <Grid container direction={'column'} >
                {
                    data.map(((val, index) => {
                        return <Grid container key={`cs-major-tasks-${index}`} data-aos="fade-up" className='task-wrapper fade-el'>
                            <Grid md={1.5}>
                                <div className='lt-gradient'></div>
                            </Grid>
                            <Grid container md={10} alignItems={'center'} alignContent={'center'} >
                                <div className='tasks-container'>
                                    <div className='sub-desc' dangerouslySetInnerHTML={{ __html: val.text }}>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    }))
                }

            </Grid>
        </div >
    );
}
export default MajorTasks;
