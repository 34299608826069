import { Component } from "react";
import Banner from "./Banner";
import '../../css/work.scss'
import CTAComponentUpdated from "../General/CTAComponentUpdated";
import AllWorks from "./AllWorks";

export default class Work extends Component {
    componentDidMount() {
        this.props.getAllActiveWorkList();
    }

    render() {
        const { CTA, worksList } = this.props;
        return (
            <div className="root-work">
                <Banner />
                {worksList && worksList.length && <AllWorks ourWorkData={worksList} />}
                {worksList && worksList.length && CTA && <CTAComponentUpdated info={CTA} />}
                {/* <HomeSignUp /> */}
            </div>
        )
    }
}