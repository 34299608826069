import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import '../../css/contact-form.scss'
import attachmentImg from '../../assets/multimedia.svg'
import { useState } from 'react';
import { isValidEmail, isValidFullName, isNumberOnly } from '../../common/contstants';
import { useIntl } from 'react-intl';
function ContactUsForm(props) {
  const { formatMessage } = useIntl();
  const { contactRes, registerContact } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIssubmitted] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    // .doc,.pdf,.docx,.jpg,.png,.csv
    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "text/csv",
      "image/png",
      "image/jpeg"
    ]
    if (e.target.files) {
      if (allowedFileTypes.includes(e.target.files[0].type)) {
        setFile(e.target.files[0]);
        setError('');
      } else {
        setError(formatMessage({ id: 'attachment_validation' }));
      }

    }
  };

  function onSubmit(e) {

    e.preventDefault();
    setIssubmitted(true)
    if (!name || !email || !isValidEmail(email) || (phone && isNaN(phone))) {
      return;
    }
    /// Make api call
    console.log(name, email, phone, message)
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("name", name);
    if (phone)
      formdata.append("phone", phone);
    if (message)
      formdata.append("message", message);
    if (file)
      formdata.append("attachment", file, file.name);
    setbuttonDisable(true)
    registerContact(formdata);
  }
  if (name && (contactRes.message || contactRes.error)) {
    setName('');
    setEmail('');
    setPhone('');
    setFile('');
    setMessage('')
    setIssubmitted(false);
    setbuttonDisable(false);
  }
  return (
    <div>
      <Box display="flex" flexDirection='row' justifyContent="flex-end">
        <div className='career-contact-form contact-us-form'>

          {contactRes.message && <div className='message-style success-msg'>{formatMessage({ id: contactRes.message })}</div>}
          {contactRes.error && <div className='message-style error-msg'>{contactRes.error}</div>}
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Grid container
              // columnGap={5}
              className='custom-form-row'
            // justifyContent="flex-end" 
            >
              <Grid md={12} sm={10} xs={12}>
                <TextField
                  error={isSubmitted && (!name || (name && !isValidFullName(name)))}
                  helperText={isSubmitted && (!name ? formatMessage({ id: 'name_validation' }) : (!isValidFullName(name) ? formatMessage({ id: 'name_message' }) : ''))}
                  value={name}
                  required id="standard-basic" onChange={(e) => setName(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_us_field_1_label' })} variant="standard" />
              </Grid>
            </Grid>
            <Grid container
              // columnGap={5}
              className='custom-form-row'
            // justifyContent="flex-end" 
            >
              <Grid md={12} sm={10} xs={12}>
                <TextField
                  error={isSubmitted && (!email || (email && !isValidEmail(email)))}
                  helperText={isSubmitted && (!email ? formatMessage({ id: 'email_validation' }) : (!isValidEmail(email) ? formatMessage({ id: 'email_message' }) : ''))}
                  type='email' required id="standard-basic" onChange={(e) => setEmail(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_us_field_2_label' })} variant="standard"
                  value={email}
                />
              </Grid>
            </Grid>

            <Grid container
              // columnGap={5}
              // justifyContent="flex-end" 
              className='custom-form-row'
            >
              <Grid md={12} sm={10} xs={12}>
                <TextField
                  error={isSubmitted && phone && !isNumberOnly(phone)}
                  helperText={isSubmitted && phone && (!isNumberOnly(phone) ? formatMessage({ id: 'phone_validation' }) : '')}
                  value={phone}
                  id="standard-basic" onChange={(e) => setPhone(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_us_field_3_label' })} variant="standard" />
              </Grid>
            </Grid>

            <Grid container
              className='custom-form-row'
            // justifyContent="flex-end" 
            >
              <Grid md={12} sm={10} xs={12}>
                <TextField id="standard-basic"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_us_field_4_label' })} variant="standard" />
              </Grid>
            </Grid>


            <div className='custom-form-row custom-form-row-file'>

              <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept=".doc,.pdf,.docx,.jpg,.png,.csv" type="file" onChange={handleFileChange} />
                <img className='attachment-icon' src={attachmentImg} alt="attachment" />
              </IconButton>
              <span className='lbl-attachment'>{(file && file.name) ? `${file.name} ` + formatMessage({ id: 'attached' }) : formatMessage({ id: 'contact_us_field_5_label' })} </span>
              {error && <div className='contact-error-cont error-msg'>{error}</div>}

            </div>

            <div style={{ position: 'relative' }}>
              <Button className='button' type='submit' disabled={buttonDisable}>{formatMessage({ id: 'contact_us_submit_button' })}</Button>
              {/* <div className='button-radiant'></div> */}
            </div>
          </form>
        </div>
      </Box>
    </div >
  );
}
export default ContactUsForm;
