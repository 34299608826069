import * as React from 'react';
import downArrow from '../../assets/Home/Down.svg'
import '../../css/service-banner.scss'

function ServiceBanner(props) {
  const { name, desc } = props;
  return (
    <div className='service-page-banner'>
      {name && (<>

        <div className='service-page-banner-content'>
          <div className='service-page-banner-title o-color'>
            <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: localStorage.getItem('localeCode') == 'en' ? name.split(".").join(".<br/>") : name }}></div>
          </div>
          <div data-aos="fade-up" className='service-page-banner-desc' dangerouslySetInnerHTML={{ __html: desc }}></div>
        </div>
        <div className='down-arrow'>
          <a href='#partners' alt="Navigate to parners">
            <img className='down-arrow-img' src={downArrow} alt="down arrow" />
          </a>
        </div>
      </>)}

    </div >
  );
}
export default ServiceBanner;
