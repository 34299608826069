import { Grid } from '@mui/material';
import '../../css/cs-billers.scss';
import DynamicHtmlBind from './DynamicContent';
import { useIntl } from 'react-intl';
function CSBillers(props) {
  const { formatMessage } = useIntl();
    const { data } = props;
    return <div className="cs-billers">
        <div data-aos="fade-up" className='head fade-el'>{formatMessage({id: 'who_worked'})}</div>
        <Grid container>
            <Grid md={7} className='biller-wrapper'>
                <div data-aos="fade-up" className='cs-billers-html fade-el'>
                    <DynamicHtmlBind data={data} />
                </div>
            </Grid> 
            <Grid md></Grid>
        </Grid>
    </div>
}

export default CSBillers;