import * as React from 'react';
import '../../css/cs-testimonial.scss'
import { Grid } from '@mui/material';
import { DefaultPlayer as Video } from 'react-html5video';
function CSTestimonial(props) {
    const { data: { images, text } } = props;
    const pageId = props?.id;
    return (
        <div  id={`cs-${pageId}`}  className='cs-testimonial'>
            <Grid container columnGap={10}  data-aos="fade-up" className='cs-testimonial-grid fade-el' alignItems={'center'}>
                {
                    images.length > 0 && <Grid className={`cs-testimonial-logo-container ${images.length > 1 ? 'cs-testimonial-logos-cont' : ''}`}>
                        <Grid container columnGap={8} className='cs-testimonial-logo-grid'>
                            {
                                images.map(((val, index) => {
                                    return <Grid 
                                        key={`cs-testimonial-logo-${index}`} 
                                        className={`logo-container-parent ${images.length >= 2 ? 'logos-container-parent' : ''}`}>
                                        <div className='logo-container fade-el' data-aos="fade-up">
                                            {
                                                val.url.indexOf('.mp4') > -1 ?
                                                    <Video
                                                        id="video-medical-webapp-solutions"
                                                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                                        onCanPlayThrough={() => {}}>
                                                        <source src={val.url} type="video/mp4" />
                                                    </Video> : <img src={val.url} data-aos="fade-up" alt="testimonial logo" />
                                            }

                                        </div>
                                    </Grid>
                                }))
                            }
                        </Grid>
                    </Grid>
                }
                <Grid md sm> 
                    <span data-aos="fade-up" className='title fade-el' dangerouslySetInnerHTML={{ __html: text }}></span>
                </Grid>

            </Grid>
        </div >
    );
}
export default CSTestimonial;
