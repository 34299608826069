import { Grid, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import '../../css/contact.scss';
import { getRoutePath } from '../../common/contstants';
import contactUsMobile from '../../assets/contacts-with-text.png';
import closeButtonIcon from '../../assets/close-button-icon.svg';
import tickButtonIcon from '../../assets/tick-button-icon.svg';
import { useIntl } from 'react-intl';

export default function ContactUS({ info, marginBottom }) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { title, description, image, btnText, btnLink, titleMb, btnText2 } = info;
  return (
    <div className={`contact ${marginBottom ? 'margin-bottom' : ''}`}>
      <div className='contact-card'>
        <Grid container style={{ height: '100%' }} className='contact-inner-container'>
          <Grid md={6} sm={12} xs={12} lg={8.2} style={{ height: '100%' }} className='contact-inner-content'>
            <Grid
              data-aos="fade-up"
              className={`contact-heading fade-el ${(typeof titleMb !== "undefined" && titleMb != '') ? 'desktop-title' : ''}`}
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: title }) }}
            ></Grid>
            {(typeof titleMb !== "undefined" && titleMb != '') && <Grid
              data-aos="fade-up"
              className='contact-heading mobile-title fade-el'
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: titleMb }) }}
            ></Grid>}
            <Grid
              data-aos="fade-up"
              className='contact-content fade-el'
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" }
              }}
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: description }) }}></Grid>
            <Grid md={12} sm={12} lg={{ display: 'none' }} className='contact-phone-container-mobile'>
              <img data-aos="fade-up" src={contactUsMobile} className='contact-image-modified-mobile' alt="Contact Us Mobile" />

              <div data-aos="fade-up" className='consultation-choice fade-el'>
                <span className='consultation-choice-btn'>
                  <img src={closeButtonIcon} className='contact-image-modified' alt="Refuse" />
                </span>
                <span className='consultation-choice-btn' onClick={() => { navigate(getRoutePath(btnLink)) }}>
                  <img src={tickButtonIcon} className='contact-image-modified' alt="Go to contact us page" />
                </span>
              </div>
            </Grid>
            <Grid className='contact-button-container'>
              <Button className='contact-button' data-aos="fade-up" onClick={() => { navigate(getRoutePath(btnLink)) }}>
                <span className='contact-button-mobile'>{formatMessage({ id: btnText })}</span>
                <span className='contact-button-desktop'>{formatMessage({ id: typeof btnText2 == "undefined" ? "GET STARTED" : btnText2 })}</span>
              </Button>
            </Grid>
          </Grid>
          <Grid md={6} sm={12} lg={3.8} style={{ position: 'relative', height: '100%', textAlign: "right" }} className='contact-phone-container'>
            <img src={image} className='contact-image-modified' style={{ boxSizing: "border-box", marginRight: "5%" }} alt="Contact Us" />
          </Grid>

        </Grid>
      </div>
    </div>
  );
}