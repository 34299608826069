import * as React from 'react';
import { Chip, Stack } from '@mui/material';
import _ from 'lodash';


export default function Tags({ tags, stackClass, chipClass }) {
    let tagList = _.chunk(tags, 2);

    return (
        <>
            {tagList.map((tagArr, index) => (
                <Stack key={index} direction="row" spacing={1} className={stackClass}>
                    {
                        tagArr.map(tag => <Chip key={tag} className={`${chipClass} chip-tags`} style={{ backgroundColor: "#FFFFFF", padding: "0px 5px" }} label={tag} />)
                    }
                </Stack>
            ))}
        </>
    );
};