import { configureStore } from '@reduxjs/toolkit';
import homeReducer from '../reducers/home';
import serviceReducer from '../reducers/service';
import careerReducer from '../reducers/career';
import contactReducer from '../reducers/contact';
import blogsReducer from '../reducers/blog';
import worksReducer from '../reducers/works';
import csReducer from '../reducers/cs';
export const store = configureStore({
  reducer: {
    home: homeReducer,
    service: serviceReducer,
    career: careerReducer,
    contact: contactReducer,
    blogs: blogsReducer,
    works: worksReducer,
    cs: csReducer
  },
});
