import { connect } from 'react-redux';
import { DefaultCTAUpdatedV2 } from '../common/contstants';
//import { getPartners } from '../Actions/clients';
import { fetchActiveBlogList} from '../Actions/blog';
import Blog from '../components/Blog';
import _ from 'lodash';


const mapStateToProps = state => ({
    //partners: state.home.partners,
    blogList: _.get(state,'blogs.blogList', {}),
    CTA: DefaultCTAUpdatedV2
})

const mapDispatchToProps = (dispatch) => ({
    //getPartners: () => dispatch(getPartners()),
    fetchActiveBlogList: () => dispatch(fetchActiveBlogList())
})

export default connect(mapStateToProps, mapDispatchToProps)(Blog);