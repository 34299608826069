import * as React from 'react';
import '../../css/cs-solution.scss'
import { Grid } from '@mui/material';

function SolutionService(props) {
    const { data: { title, description, data, list_type } } = props;
    return (
        <div data-aos="fade-up" className={`cs-solution fade-el ${!list_type ? 'cs-solution-main-no-list-data' : ''}`}>
            <div data-aos="fade-up" className='title fade-el'>{title}</div>
            <div data-aos="fade-up" className={`sub-desc fade-el ${!list_type ? 'cs-solution-no-list-data' : ''}`} dangerouslySetInnerHTML={{ __html: description }}></div>
            {
                list_type === 'countdown_list' &&
                <Grid container direction={'row'} columnGap={15} >
                    {
                        data.map(((val, index) => {
                            return <Grid key={index} md={3} data-aos="fade-up" className='gradient-wrapper fade-el'>
                                <div>
                                    <div className='val-radient'></div>
                                    <div className='val-container'>
                                        <div className='val'>
                                            {val.count}
                                        </div>
                                        <div className='val-text' dangerouslySetInnerHTML={{ __html: val.text }}></div>
                                    </div>
                                </div>

                            </Grid>
                        }))
                    }

                </Grid>
            }
            {list_type === 'bullet' &&
                <Grid container direction={'column'} >
                    {
                        data.map(((val, index) => {
                            return <Grid container key={`cs-major-tasks-${index}`} className='task-wrapper'>
                                <Grid md={1.5} >
                                    <div data-aos="fade-up" className='lt-gradient fade-el'></div>
                                </Grid>
                                <Grid container md={10} alignItems={'center'} alignContent={'center'}>
                                    <div data-aos="fade-up" className='tasks-container fade-el'>
                                        <div className='sub-desc' dangerouslySetInnerHTML={{ __html: val.text }}>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        }))
                    }

                </Grid>
            }
        </div >
    );
}
export default SolutionService;
