// import _ from 'lodash';
import { ActionTypesList, apiURls } from '../common/contstants';

const clearSubscribeInfo = (res) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypesList.SUBSCRIBE_RESPONSE,
      data: res
    })
  }
}
const subscribeNewsLetter = (mail) => {
  const url = apiURls.subscribe();

  var formdata = new FormData();
  formdata.append("email", mail);

  var requestOptions = {
    method: 'POST',
    body: formdata
  };
  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',
        ...requestOptions

      })
      .then((response) => Promise.all([response.status, response.json()]))
      .then(([statusCode, res]) => {
        let subscribeRes = {}
        if (res.data.errors) {
          subscribeRes.error = res.data.errors[0].userMessage
        } else {
          const message = (statusCode === 200 || statusCode === 409) ? res.message.replace(':)', `😊`) : res.message;
          subscribeRes = {
            data: res.data,
            status: statusCode,
            message: message
          }
        }
        dispatch({
          type: ActionTypesList.SUBSCRIBE_RESPONSE,
          data: subscribeRes
        })
      })
      .catch((err) => {
        dispatch({
          type: ActionTypesList.SUBSCRIBE_RESPONSE,
          data: {
            message: err.message
          }
        })
      })
  }
}


function getLanguages() {
  const url = apiURls.languages();

  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',

      })
      .then(response => response.json())
      .then(res => {
        const languages = (res.data || []).sort((a, b) => a.id - b.id)
        dispatch({
          type: ActionTypesList.LANGUAGE_RESPONSE,
          data: languages
        })
      })
      .catch((err) => {
        dispatch({
          type: ActionTypesList.LANGUAGE_RESPONSE,
          data: {
            error: err.message
          }
        })
      })
  }
}


export {
  subscribeNewsLetter, clearSubscribeInfo,
  getLanguages
};