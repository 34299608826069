import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import '../../css/service-faq.scss';


export default function Faq(props) {
    const { info: { data, title } } = props;
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div id="service-faq">
            <div data-aos="fade-up" className='service-faq-heading fade-el'>{title}</div>
            <div data-aos="fade-up" className='service-faq-accordion-container fade-el'>
                {
                    data.map((item, index) => {
                        const id = `panel-${index}`;
                        const { head, text } = item;
                        return (
                            <Accordion elevation={0} expanded={expanded === id} key={id} className='service-faq-accordion' onChange={handleChange(id)}>
                                <AccordionSummary className='service-faq-accordion-summary' expandIcon={(expanded === id) 
                                    ? (<RemoveIcon className='service-faq-accordion-icon' />) : (<AddIcon className='service-faq-accordion-icon' />)} id={`${id}-header`}>
                                    <div className='service-faq-question'>{head}</div>
                                </AccordionSummary>
                                <AccordionDetails className='service-faq-answer'>
                                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
        </div>
    )
}