import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import map_image from "../../assets/animation/images/map.png";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useIntl } from 'react-intl';
export default function LocationsMap() {
  const { formatMessage } = useIntl();
  const locations = [
    "Mannheim, Germany",
    "Deidesheim, Germany",
    "Lviv, Ukraine",
    "Coimbatore, India",
  ];
  const location = useLocation();
  function onLocationClick(e, index) {
    const locationTextIds = [
      "mannheim-loc",
      "deidesheim-loc",
      "lviv-loc",
      "coimbatore-loc",
    ];
    const dot = document.getElementById("loc-dot");
    const mapLocation = document.getElementById("map-loc-dot");
    const locationMap = getMapLocations();
    var currentSelectedIndex = 0;

    currentSelectedIndex = index;
    const element = document.getElementById(locationTextIds[currentSelectedIndex]);

    dot.style = `top: ${element?.offsetTop + 15}px`;
    mapLocation.style = `top: ${locationMap[currentSelectedIndex].top}; left: ${locationMap[currentSelectedIndex].left}`;
  }

  useEffect(() => {
    const cities = locations.map((l) => l?.split(",")[0]?.toLowerCase());
    const country = location?.state?.country?.split("- ")?.[1]?.toLowerCase();
    const foundLocationIndex = cities.indexOf(country);

    if (foundLocationIndex !== -1) {
      onLocationClick(null, foundLocationIndex);

      document.getElementById("location-map-globe")?.scrollIntoView({
        behavior: "smooth",
      });
    }
    window.history.replaceState({}, document.title);
  }, [location?.state]);

  return (
    <Grid container id="location-map-globe" className='location-map-globe'>
      <Grid item position={"relative"} container xs={12} sm={7} lg={7} md={8}>
        <Grid className="location-data-wrapper" container justifyContent={"center"}>
          <Grid item xs={1}>
            <div className="dot selected-location" id="loc-dot"></div>
          </Grid>
          <Grid className="grid-list location-container" item xs={11}>
            <Grid
              spacing={5}
              ml={1}
              container
              justifyContent={"center"}
              direction={"column"}
              className="location-wrapper"
            >
              <Grid
                data-aos="fade-up"
                item
                xs={12}
                container
                direction={"column"}
                id="mannheim-loc"
                className="location-card fade-el"
              >
                <span
                  className="location-name"
                  onClick={(e) => onLocationClick(e, 0)}
                >
                  {formatMessage({ id: "location_1" })}{" "}
                  {/* <img src={germany_flag} class="location-flag" />{" "} */}
                </span>
                <span className="location-address">
                {formatMessage({ id: "location_1_address" })}{" "}
                </span>
              </Grid>
              <Grid
                data-aos="fade-up"
                item
                xs={12}
                container
                direction={"column"}
                id="deidesheim-loc"
                className="location-card fade-el"
              >
                <span
                  className="location-name"
                  onClick={(e) => onLocationClick(e, 1)}
                >
                 {formatMessage({ id: "location_2" })}{" "}
                  {/* <img src={germany_flag} class="location-flag" />{" "} */}
                </span>
                <span className="location-address">{formatMessage({ id: "location_2_address" })}</span>
              </Grid>
              <Grid
                data-aos="fade-up"
                item
                xs={12}
                container
                direction={"column"}
                id="lviv-loc"
                className="location-card fade-el"
              >
                <span
                  className="location-name"
                  onClick={(e) => onLocationClick(e, 2)}
                >
                  {formatMessage({ id: "location_3" })}
                  {/* <img src={ukrain_flag} class="location-flag" />{" "} */}
                </span>
                <span className="location-address">
                {formatMessage({ id: "location_3_address" })}{" "}
                </span>
              </Grid>
              <Grid
                data-aos="fade-up"
                item
                xs={12}
                container
                direction={"column"}
                id="coimbatore-loc"
                className="location-card fade-el"
              >
                <span
                  className="location-name"
                  onClick={(e) => onLocationClick(e, 3)}
                >
                  {formatMessage({ id: "location_4" })}{" "}
                  {/* <img src={india_flag} class="location-flag" />{" "} */}
                </span>
                <span className="location-address">
                {formatMessage({ id: "location_4_address" })}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        md={4}
        lg={5}
        overflow={"hidden"}
        {...(isMobile && {
          container: true,
          justifyContent: "center",
          mt: 5,
        })}
      >
        <div className="map">
          <div class="dot map-location" id="map-loc-dot"></div>
          <img src={map_image} height="100%" width="100%" alt="map" />
        </div>
      </Grid>
    </Grid>
  );
}

function getMapLocations(){
  let locationMap = [
    { top: "22%", left: "17%" },
    { top: "22%", left: "17%" },
    { top: "19%", left: "20%" },
    { top: "49%", left: "52%" },
  ];

  if(window.screen.width >= 1900){
    locationMap = [
      { top: "27%", left: "20%" },
      { top: "27%", left: "20%" },
      { top: "22%", left: "25%" },
      { top: "50%", left: "54.5%" },
    ];
  }else if (window.screen.width >= 300 && window.screen.width <= 640){
    locationMap = [
      { top: "15%", left: "10%" },
      { top: "15%", left: "10%" },
      { top: "13%", left: "15%" },
      { top: "40%", left: "45%" },
    ];
  }else if (window.screen.width >= 641 && window.screen.width <= 899){
    locationMap = [
      { top: "16%", left: "8%" },
      { top: "16%", left: "8%" },
      { top: "11%", left: "12%" },
      { top: "38%", left: "42%" },
    ];
  }else if (window.screen.width >= 900 && window.screen.width <= 1200){
    locationMap = [
      { top: "16%", left: "8%" },
      { top: "16%", left: "8%" },
      { top: "11%", left: "12%" },
      { top: "41%", left: "44%" },
    ];
  }

  return locationMap;
}
