import * as React from 'react';
import FounderLeftBlock from '../../common/FounderLeftBlock';
import '../../css/founder.scss'
import { Grid } from '@mui/material';
import konstantinProfile from '../../assets/founder/konstantin.png';
import MichaelProfile from '../../assets/founder/michael.png';
import { useIntl } from 'react-intl';
const FounderInfo = [
    {
        name: "Konstantin Shcherbina",
        position: "about_founder1_desi",
        description: "about_founder1_desc",
        image: konstantinProfile
    },
    {
        name: "Michael Kuffler",
        position: "about_founder2_desi",
        description: `about_founder2_desc`,
        image: MichaelProfile
    }
]

function AboutFounder() {
  const { formatMessage } = useIntl();
    return (
        <div className='about-found'>
            <div data-aos="fade-up" className='about-found-title fade-el'>
              { formatMessage({id: 'about_founder_title'})}
            </div>

            <div className='about-found-main'>
                <Grid container rowGap={5}>
                <FounderLeftBlock info={FounderInfo[0]}/>
                <FounderLeftBlock info={FounderInfo[1]}/>
                </Grid>
            </div >
        </div>
    );
}
export default AboutFounder;
