import '../../css/offices.scss'
import "../../assets/animation/css/address-animation.css";
import { useIntl } from 'react-intl';
function BestOfCountries() {
  const { formatMessage } = useIntl();
    return (
        <div className="best-of-countries">
            <h2 className="best-of-countries-title">{formatMessage({id: 'best_of_country_text1'})}</h2>
            <p className="best-of-countries-desc">
            {formatMessage({id: 'best_of_country_text2'})}
            </p>
        </div>
    );
} 

export default BestOfCountries;