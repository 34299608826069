import * as React from 'react';
import shafeekProfile from '../../assets/employees/shafeek.png';
import athiraProfile from '../../assets/employees/athira.png';
import dennyProfile from '../../assets/employees/denny.png';
import dhavalProfile from '../../assets/employees/dhaval.png';
import dimaProfile from '../../assets/employees/dima.png';
import innaProfile from '../../assets/employees/inna.png';
import kostiantynProfile from '../../assets/employees/kostiantyn.png';
import manaProfile from '../../assets/employees/mariia.png';
import naliniProfile from '../../assets/employees/nalini.png';
import viraProfile from '../../assets/employees/vira.png';
import sofiaProfile from '../../assets/employees/sofia.png';
import tanviProfile from '../../assets/employees/tanvi.png';
import arrowNext from '../../assets/arrow-next.svg';
import arrowPrev from '../../assets/arrow-previous.svg';
import '../../css/employees.scss';
import { Grid } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { useIntl } from 'react-intl';
const AutoPlaySwipeableViews = SwipeableViews;

const profiles = [
  {
    profile: shafeekProfile,
    name: 'Shafeek Abdul Salam',
    position: 'emp1_position',
    Offlocation: 'emp1_location',
    summary: `emp1_summary`
  }, {
    profile: naliniProfile,
    name: 'Nalini Umapathy',
    position: 'emp2_position',
    Offlocation: 'emp2_location',
    summary: `emp2_summary`

  }, {
    profile: innaProfile,
    name: 'Inna Ostapenko',
    position: 'emp3_position',
    Offlocation: 'emp3_location',
    summary: `emp3_summary`

  }, {
    profile: dimaProfile,
    name: 'Dima Siefier',
    position: 'emp4_position',
    Offlocation: 'emp4_location',
    summary: `emp4_summary`

  }, {
    profile: athiraProfile,
    name: 'Athira Mohan',
    position: 'emp5_position',
    Offlocation: 'emp5_location',
    summary: `emp5_summary`

  }, {
    profile: dennyProfile,
    name: 'Denny Rosenbusch',
    position: 'emp6_position',
    Offlocation: 'emp6_location',
    summary: `emp6_summary`

  }, {
    profile: dhavalProfile,
    name: 'Dhaval Patel',
    position: 'emp7_position',
    Offlocation: 'emp7_location',
    summary: `emp7_summary`

  }, {
    profile: sofiaProfile,
    name: 'Sofia Ivasyshyn',
    position: 'emp8_position',
    Offlocation: 'emp8_location',
    summary: `emp8_summary`

  }, {
    profile: viraProfile,
    name: 'Vira Chornous',
    position: 'emp9_position',
    Offlocation: 'emp9_location',
    summary: `emp9_summary`

  }, {
    profile: tanviProfile,
    name: 'Tanvi Chandna',
    position: 'emp10_position',
    Offlocation: 'emp10_location',
    summary: `emp10_summary`

  }, {
    profile: kostiantynProfile,
    name: 'Kostiantyn Kolomak',
    position: 'emp11_position',
    Offlocation: 'emp11_location',
    summary: `emp11_summary`

  }, {
    profile: manaProfile,
    name: 'Maria Danets',
    position: 'emp12_position',
    Offlocation: 'emp12_location',
    summary: `emp12_summary`

  },
]
function AboutEmployees() {
  const [hoverIdx, setHoverIdx] = React.useState()

  const hoverInEvent = (idx) => {
    setHoverIdx(idx)
  }
  const hoverOutEvent = (e) => {
    setHoverIdx();
  }


  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = profiles.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const { formatMessage } = useIntl();
  return (
    <div className='about-employees'>
      <div data-aos="fade-up" className='about-emp-title fade-el'>
        {formatMessage({ id: 'employee_gb_title' })}
      </div>

      <div className='about-emp-main'>
        {/* Desktoop view */}
        <Grid container rowGap={8} justifyContent='space-between' sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          {
            profiles.map((profileInfo, idx) => {
              return <>
                <Grid itemkey={idx} className='employee-card-wrapper'>
                  <div style={{ position: 'relative' }} className='employee-card'>
                    <div className={`about-client-container ${idx === hoverIdx ? 'hover-effect' : ''}`} onMouseEnter={() => hoverInEvent(idx)} onMouseLeave={hoverOutEvent}>
                      <img className='about-client-profile-img' src={profileInfo.profile} alt='profile' />
                      <div className='about-client-profile-effect'></div>
                    </div>
                    <div className='about-client-name'>{profileInfo.name}</div>
                    <div className={`${idx === hoverIdx ? 'd-block' : 'd-none'} client-summary-details`}
                      onMouseEnter={() => hoverInEvent(idx)} onMouseLeave={hoverOutEvent}>
                      <div className='client-position'>{formatMessage({ id: profileInfo.position })}</div>
                      <div className='client-location'>{formatMessage({ id: profileInfo.Offlocation })}</div>
                      <div className='client-summary' dangerouslySetInnerHTML={{ __html: formatMessage({ id: profileInfo?.summary }) }}></div>
                    </div>
                  </div>
                </Grid>
              </>
            })
          }
        </Grid>
        {/* Mobile view - Carousel Slider */}
        <Box sx={{ maxWidth: 400, display: { xs: 'block', sm: 'block', md: 'none' }, margin: '0 auto' }} className='carousel-container'>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            className='carousel-container-swipeable-views'
          >
            {profiles.map((step, index) => (
              <div
                key={step.name}
                className='carousel-container-image-wrapper'
              >
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      height: 'auto',
                      display: "block",
                      maxWidth: 400,
                      overflow: "hidden",
                      width: "auto",
                      maxHeight: '100%'
                    }}
                    src={step.profile}
                    alt={step.name}
                    className='carousel-container-image'
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>

          <div className='carousel-profile-wrapper'>
            <div>
              <Typography className='carousel-profile-name'>{profiles[activeStep].name}</Typography>
              <Typography className='carousel-profile-position'>{formatMessage({ id: profiles[activeStep].position })}</Typography>
            </div>
          </div>

          <Typography className='carousel-profile-summary' dangerouslySetInnerHTML={{ __html: formatMessage({ id:profiles[activeStep].summary}) }}></Typography>

          <MobileStepper
            position="static"
            className='carousel-container-stepper'
            nextButton={
              <button
                type='button'
                onClick={handleNext}
                className={`carousel-container-nav ${activeStep === maxSteps - 1 ? 'disabled' : ''}`}
              >
                <img src={arrowNext} alt='next' />
              </button>
            }
            backButton={
              <button
                type='button'
                className={`carousel-container-nav ${activeStep === 0 ? 'disabled' : ''}`}
                onClick={handleBack}>
                <img src={arrowPrev} alt='back' />
              </button>
            }
          />
        </Box>
      </div>
    </div >
  );
}
export default AboutEmployees;
