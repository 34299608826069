import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/quotes.scss'
import quotesImg from '../../assets/Home/quotes.svg'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { useIntl } from 'react-intl';
function QuotesTemplate(props) {
  const { formatMessage } = useIntl();
  const { quotes: { title, desc }, } = props;
  return (
    <div className='career-quotes'>
      <div data-aos="fade-up" className='career-quotes-title fade-el'>{formatMessage({ id: title })}</div>
      <div className='career-quotes-content'>
        <Grid container columnGap={{ lg: 12, md: 5, sm: 5 }} rowGap={{ xs: 5 }}>
          <Grid data-aos="fade-up" lg={5.5} md={5.5} sm={5.5} className='video-wrapper fade-el'>
            <Video
              controls={['PlayPause', 'Seefk', 'Time', 'Volume', 'Fullscreen']}
              onCanPlayThrough={() => {
                // Do stuff
              }}>
              <source src="https://di5m48esz32j5.cloudfront.net/videos/Inna_with_text.mp4" type="video/mp4" />
            </Video>
          </Grid>
          <Grid lg={5.5} md={5.8} sm={5.5}>
            <div>
              <div>
                <img src={quotesImg} className="client-quotes" alt='quotes' />
              </div>
              <div data-aos="fade-up" className='item-desc fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: desc }) }}></div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div >
  );
}
export default QuotesTemplate;
