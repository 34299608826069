import { connect } from 'react-redux';
import { getServiceDetails, getServicesList } from '../Actions/services';
import ResponsiveAppBar from '../components/Header';
import { getLanguages, getWorkCheck } from '../Actions/home';
import { getAllActiveWorkList, getHomeActiveWorkList, getWorkListByServiceId } from '../Actions/works';
import { fetchActiveBlogList, fetchHomeBlogs } from '../Actions/blog';
import { getClients, getPartners } from '../Actions/clients';
const pages = [
  { title: 'About Us', link: 'about-us', key: 'header_about_text' },
  {
    title: 'Services',
    type: 'dropdown',
    key: 'header_service_text',
    menu: []
  },
  { title: 'Work', link: 'work', key: 'header_work_text' },
  { title: 'Careers ', link: 'career', key: 'header_career_text' },
  { title: 'Blog', link: 'blog', key: 'header_blog_text' },
  { title: 'Contact Us', link: 'contact', key: 'header_contact_text' }
];
const serviceMap = (services, work) => {
  pages[1].menu = services || [];
  pages[1].work = work || [];
  return pages;
}
const mapStateToProps = (state, props) => {
  return ({
    service: state.service.servicesList,
    languages: state.home.languages,
    ...props,
    pages: serviceMap(state.service.servicesList, state.works.worksList),
  })
}

const mapDispatchToProps = (dispatch, a) => ({
  getServicesList: () => dispatch(getServicesList()),
  getLanguages: () => dispatch(getLanguages()),
  getHomeActiveWorkList: () => dispatch(getHomeActiveWorkList()),
  getHomeBlogs: () => dispatch(fetchHomeBlogs()),
  getClients: () => dispatch(getClients()),
  getServiceDetails: () => dispatch(getServiceDetails()),
  getWorkListByServiceId: () => dispatch(getWorkListByServiceId()),
  getAllActiveWorkList: () => dispatch(getAllActiveWorkList()),
  getPartners: () => dispatch(getPartners()),
  fetchActiveBlogList:() => dispatch(fetchActiveBlogList())
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveAppBar);