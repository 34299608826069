import { connect } from 'react-redux';
import { getClients, getPartners } from '../Actions/clients';
import { getHomeActiveWorkList } from '../Actions/works';
import Home from '../components/home';
import { MainCTA } from '../common/contstants';
import { fetchHomeBlogs } from '../Actions/blog';

const mapStateToProps = state => ({
    data: state.home.data,
    clients: state.home.clients,
    partners: state.home.partners,
    worksList: state.home.worksList,
    CTA: MainCTA,
    homeBlogs: state.blogs.homeBlogList,
    services: state.service.servicesList,
})

const mapDispatchToProps = (dispatch) => ({
    getClients: () => dispatch(getClients()),
    getPartners: () => dispatch(getPartners()),
    getHomeActiveWorkList: ()=>dispatch(getHomeActiveWorkList()),
    getHomeBlogs: () => dispatch(fetchHomeBlogs())
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);