import { Button, Chip } from '@mui/material';
import * as React from 'react';
// import '../../css/jobs.scss'
import '../../css/job-card.scss'
import { useNavigate } from 'react-router';
import { ActiveTypesMapping, getRoutePath } from '../../common/contstants';
// import quotesImg from '../../assets/Home/quotes.svg'
import { useIntl } from 'react-intl';

function JobCard(props) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { jobInfo } = props;
  return (
    <div className='job-card'>
      <div className='job-card-content'>
        <div className='job-card-position'>{jobInfo.position}</div>
        <div>
          <Chip className='job-category' label={formatMessage({ id: ActiveTypesMapping[jobInfo.employmentType] })} />
          {jobInfo.region && <Chip className='job-category' label={jobInfo.region.country} />}
          {/* <Chip className='job-category' label={jobInfo.categories.type} /> */}
        </div>
      </div>
      <Button className='btn-view-job' sx={{ borderColor: '#FF7900', color: '#FFFFFF' }} variant="outlined"
        onClick={() => navigate(`${getRoutePath('career')}/${jobInfo.localId}`)}
      >{formatMessage({ id: 'career_view_job_button' })}</Button>

    </div>
  );
}
export default JobCard;


