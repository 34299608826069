import { ActionTypesList } from "../common/contstants";

const csReducer = (state = { csInfo: {}}, action) => {
    switch (action.type) {
        case ActionTypesList.FETCH_CS_DETAILS:
            return { ...state, csInfo: action.data }
        default:
            return state;
    }
}

export default csReducer;