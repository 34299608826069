import { Component } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Banner from "./Banner";
import HomePartners from "../Home/Partners";
import '../../css/career.scss'
import particlesData from '../../common/particlesjs-config.json'
import ReasonTemplate from "./WhyWorkWithUs";
import {ReactComponent as MoneyImg } from '../../assets/WorkWithUs/money-animation.svg';
import {ReactComponent as FriendsImg } from '../../assets/WorkWithUs/friends-animation.svg';
import {ReactComponent as CatImg } from '../../assets/WorkWithUs/cat-animation.svg';
import videoThumbnail from '../../assets/Career/video-thumbnail.png'
import QuotesTemplate from "./Quotes";
import Jobs from "./Jobs";
import EmployeesSay from "./EmployeesSay";
import CareerContact from "./Contact";
import { HelmetWrapper } from "../HelmetWrapper";

export default class Career extends Component {
    metaData = {
        title: 'career_page_title', 
        description: 'career_page_description', 
        isStatic: true
    }
    componentDidMount() {
        this.props.getPartners('all')
        this.props.getJobs()
    }

    particlesInit = async (main) => {
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets 
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready 
        // starting from v2 you can add only the features you need reducing the bundle size 
        await loadFull(main);
    };
    particlesLoaded = (container) => {};
    reasonData = {
        title: 'career_reason_title',
        data: [
            {
                title: "career_reason_list1_title",
                url: <MoneyImg />,
                className: "money-svg",
                desc: 'career_reason_list1_desc'
            },
            {
                title: `career_reason_list2_title`,
                url: <FriendsImg />,
                className: "friends-svg",
                desc: 'career_reason_list2_desc'
            },
            {
                title: 'career_reason_list3_title',
                url: <CatImg />,
                className: "cat-svg",
                desc: 'career_reason_list3_desc'
            }
        ]
    }

    quotesInfo = {
        title: 'career_question_title',
        thumbnail: videoThumbnail,
        video: videoThumbnail,
        desc: `career_question_desc`
    }
    render() {
        const { partners, jobs, careerCreateRes, createCareer } = this.props;
        
        return (
            <div className="root-career">
                <HelmetWrapper data={this.metaData} />
                <div className="career-bg1">
                    <Particles
                        id="tsparticles"
                        init={this.particlesInit}
                        loaded={this.particlesLoaded}
                        height='100%'
                        width="100%"
                        options={particlesData}
                    />
                    <div className="career-bg2">

                        <div className="top-bg">
                            <Banner />
                            <HomePartners partners={partners}  limit={6}/>
                        </div>
                    </div>
                </div>
                {/* <SvgLineNavigation
                    viewBox="0 0 1797 8904"
                    pathConfig={{
                        d:"M904.088 1C811.088 111.5 724.088 280.5 819.588 397.5C915.088 514.5 1056.09 596.5 1045.09 724.5C1034.09 852.5 822.293 873.543 780.588 950C738.587 1027 819.088 1117 932.088 1154C1045.09 1191 1108.09 1320.5 1045.09 1401C982.088 1481.5 793.116 1540.04 767.087 1634C746.587 1708 802.088 1748.5 904.088 1776.5C1006.09 1804.5 1170.59 1961 1170.59 2216.5C1170.59 2472 366.5 2680.5 421.5 3460.5M862 6073.5C658 6162 494.088 6132 182.088 6409.5C-129.912 6687 34.3936 6839.27 149.087 6865.5C295.587 6899 1726.59 6974.5 1788.59 7285.5C1841.22 7549.53 1229.59 7622 1031.09 7700C861.686 7766.57 673.587 7977.5 797.587 8218C921.587 8458.5 1413.09 8625.5 1402.59 9000.5C1392.09 9375.5 630.586 9512 550.586 9722.5C470.586 9933 1117.09 10004.5 1108.09 10197.5C1099.09 10390.5 336.586 10601 347.086 10958.5C357.586 11316 1726.09 11059.5 1793.09 11331C1860.09 11602.5 190.586 11704.5 125.586 11873.5C60.586 12042.5 585.087 12332.5 578.587 12593C572.087 12853.5 21.5864 13299.5 158.086 13573C294.586 13846.5 934.087 13885 925.587 14199.5",
                    }}
                /> */}
                <div className="root-career-content">
                    <ReasonTemplate info={this.reasonData} />
                    <QuotesTemplate quotes={this.quotesInfo} />
                    { jobs && jobs.length &&  <Jobs jobs={jobs} /> }
                    <EmployeesSay />
                </div>

                <CareerContact careerCreateRes={careerCreateRes} createCareer={createCareer} />
                {/* <Connect /> */}
                {/* <HomeSignUp /> */}
            </div>
        )
    }
}