import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/service-content-card.scss'


function ServiceContentCardItem(props) {

    const { info } = props;
    return (
        <div className='service-content-card-item'>
            <div className='service-content-card-content'>
                <div className='service-content-card-title'>{info.head}</div>
                <div className='service-content-card-desc' dangerouslySetInnerHTML={{ __html: info.text }}>
                </div>
            </div>
        </div>
    );
}

function ServiceContentCard(props) {
    const { info, section9 } = props;
    return (
        <div className='service-content-card'>
            <div data-aos="fade-up" className='service-content-main-title fade-el'>{info.title}</div>

            {info.sub_title && <div data-aos="fade-up" className='service-content-sub-title item-space fade-el'>{info.sub_title}</div>}

            <Grid container columnGap={{ md: 8, sm: 5 }} className='item-space'>
                {
                    info.data && info.data.length && info.data.map((itemInfo, idx) => {
                        return (
                            <Grid data-aos="fade-up" className='fade-el' lg={5.5} md={12} sm={12} xs={12} key={idx} justify="space-between" alignItems="stretch" style={{display: 'flex'}}>
                                <ServiceContentCardItem info={itemInfo} />
                            </Grid>
                        )
                    })
                }

            </Grid>

            {
                section9 && (<div>
                    {section9.title && <div data-aos="fade-up" className='service-content-sub-title item-space fade-el'>{section9.title}</div>}

                    <Grid container columnGap={{ md: 8, sm: 5 }} className='brand-identity-wrapper'>
                        {
                            section9.data && section9.data.length && section9.data.map((itemInfo, idx) => {
                                return (
                                    <Grid data-aos="fade-up" className='fade-el' lg={5.5} md={12} sm={12} xs={12} key={idx} justify="space-between" alignItems="stretch" style={{display: 'flex'}}>
                                        <ServiceContentCardItem info={itemInfo} />
                                    </Grid>
                                )
                            })
                        }

                    </Grid>

                    {
                        section9.tail_text && <div data-aos="fade-up" className='service-content-card-end-desc fade-el' dangerouslySetInnerHTML={{__html: section9.tail_text}}></div>
                    }
                </div>)
            }
        </div>
    )
}
export default ServiceContentCard;


