import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/service-page-list-items.scss'

function ServiceListItem(props) {
    const { icon, head, text, isFullview } = props;
    return (
        <Grid container data-aos="fade-up" className='service-card fade-el'>
            <Grid lg={0.75} md={1.5} sm={2}>
                <img src={icon} alt={head} className="service-list-item-icon" />
            </Grid>
            <Grid md={isFullview ? 10 : 8} sm={isFullview ? 10 : 8}>
                <div data-aos="fade-up" className='service-list-item-content fade-el'>
                    <div className="service-list-item-title" >{head}</div>
                    <div className="service-list-item-desc" dangerouslySetInnerHTML={{ __html: text }} ></div>
                </div>
            </Grid>
        </Grid>
    )
}

function ServiceListItems(props) {
    const { info, isFullview } = props;
    return (
        <div className='service-page-list-items'>
            <div className='service-page-list-main-text'>
                <div data-aos="fade-up" className='service-page-list-main-title fade-el'>{info.title}</div>
                {info.sub_title && <div data-aos="fade-up" className='service-page-list-sub-title fade-el'>{info.sub_title}</div>}
            </div> 
            {
                info.data && info.data.length &&
                <Grid container columnGap={{ md: 8, sm: 5 }} rowGap={{ sm: 8, md: 4 }} className="service-card-wrapper">
                    {
                        info.data.map((itemInfo, idx) => {
                            return (
                                <ServiceListItem key={idx} {...itemInfo} isFullview={isFullview} />
                            )
                        })
                    }

                </Grid>
            }
        </div >
    );
}
export default ServiceListItems;
