import * as React from "react";
import { Grid, Button, Box } from "@mui/material";
import "../css/page-not-found.scss";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import Header from "../containers/header";

export default function PageNotFound() {
  const navigate = useNavigate();
  const {formatMessage: f} = useIntl();

  return (
    <>
      <Header />
      <Box
        position={"absolute"}
        width="100%"
        display={"flex"}
        justifyContent={"center"}
        top={0}
        height={"100vh"}
      >
        <svg className="error-404-svg" viewBox="0 0 1920 1080">
          <path
            id="motionPath"
            fill="none"
            stroke="#EF781A"
            d="M968.4,1072.5c-31.4-36.8-63.9-77-77-124.5c-12.6-45.9,0.3-92,34.7-124.8c32.3-30.7,77.9-48.3,122.4-48.1
    c44.9,0.3,89.6,21.3,106.1,65.3c6.8,18.1,8.5,38.5,2.5,57c-7.1,22.2-25.2,37.2-45.9,46.5c-23.6,10.6-49.8,16.5-75.1,21.1
    c-27.1,5-54.6,7.5-82.2,7.6c-42.5,0.2-94.7-2.4-127.3-33.7c-35.8-34.5-19.3-87.2-34.4-129.8c-13.6-38.4-47.2-66.9-84.6-81.1
    c-23-8.7-47.6-12.5-72.2-13.1c-23.8-0.6-48.5,1-71.6,7c-37.4,9.8-77.5,40.6-71.2,83.9c5.8,39.5,46.5,62.5,83.3,64.5
    c25.1,1.4,50-4.5,73.8-12c23.6-7.5,46.8-16.3,69.5-26.3c46.2-20.4,89.3-46,130.6-75c37.7-26.5,76.2-55.4,121.1-68.2
    c43.7-12.4,90.1-4.6,123.9,26.8c31.8,29.5,44.6,74.4,26.5,114.8c-16.8,37.5-57.9,66.4-100,54.9c-38.7-10.6-70.7-52.5-61.7-93.3
    c9.9-45.1,58.1-68,96.3-84.9c96.5-42.8,200.7-68.4,306-75.2c50.3-3.3,103.8-5.1,152.4,10.8c43.4,14.2,83.7,47.1,93.9,93.5
    c5.1,23.1,2.2,52-16.7,68.4c-17.3,15-43.9,18.1-65.9,19.1c-41.5,1.8-82.7-9.3-119-29.1c-77-42-129-118.7-143.2-204.8
    c-15.8-95.6,10.7-194.7,57.9-277.9c24-42.3,53.9-83.7,95.5-110.2c40.2-25.7,88.9-36.5,136.3-33.7c45.3,2.7,93.6,17.6,125.1,51.9
    c33.3,36.2,39.1,90.6,38.7,137.7c-0.7,91.5-35.7,182.6-97.1,250.5c-61.2,67.8-148.3,110.5-239,120.3
    c-88.2,9.5-194-10.9-250.5-85.4c-53.1-69.9-47.7-173.8,6.9-241.1c27.3-33.6,65.1-58,106.2-71.1c40.7-13,89.9-17.5,130.6-2.2
    c22.5,8.5,40.8,24.7,48.7,47.7c7,20.4,6,42.9,1.8,63.8c-4.4,21.4-12.2,42.1-21.8,61.7c-9.7,19.9-21.7,39.3-38.2,54.3
    c-38.3,34.6-90.6,28.4-132.6,4.3c-42.8-24.5-72.4-65.2-107-99.2c-32-31.5-69.9-55.4-113.9-66.2c-40.4-9.9-84.2-8.4-122.7,8
    c-70.5,30-126.5,115.5-97.3,192.5c6.9,18.2,19.2,34.1,36.6,43.3c18,9.5,39.3,11.5,59.3,8.8c37.8-5.2,72.7-29.3,90.6-63.1
    c17.4-32.9,13.3-71-12.1-98.5c-27.7-29.9-70.9-43.9-110-50.5c-22.8-3.9-46.4-4.2-68.6,3.1c-17.9,5.9-34.1,16.1-48,28.9
    c-30.4,28.1-48.3,65.9-59.6,105.1c-12.7,44.1-23.7,90.4-64.6,117.2c-34.9,22.9-79,27.5-119.8,26.9c-41.4-0.6-83.6-5.4-123.5-16.3
    c-39.1-10.7-77.6-28.7-104.6-59.8c-55.4-63.9-38.5-166.7,26.3-217.7c69.4-54.7,179.6-47.3,242.6,13.9
    c29.1,28.2,50.5,68.2,48.7,109.6c-1.7,39.9-26.7,73.3-62.3,90.1c-34.5,16.2-75.7,17.8-112,7c-21.6-6.5-46.1-17.6-55.9-39.4
    c-9.7-21.6-4.7-48.1,6.7-68.1c20.1-35.1,60.5-55.6,99.3-61.7c52.4-8.2,105.4,7.9,156,19.8c43.5,10.2,88.8,18,133.3,9.2
    c41.4-8.2,76.6-32.7,95.1-71.1c18.5-38.5,18.3-83,3-122.5c-15.5-40.2-45.8-78-83.7-99.1c-33.2-18.5-79.7-21.2-107.3,9.1
    c-27.2,29.9-20,75.7,4.6,104.5c25.6,30,65.1,42.8,103.6,35.9c42.6-7.6,79.3-35.6,103.9-70.4c26.9-38,41-83.5,51-128.5
    C931,110.3,936.5,58.5,936.5,6.7c0-0.8-1.3-0.8-1.2,0c0,57.1-6.7,114.2-20.3,169.7c-12.1,48.9-30.6,98.7-66.2,135.5
    c-33,34-83.3,57.6-131.1,45.6c-42.1-10.6-80-49.8-77-95.2c1.8-27,17.8-49.9,43.2-59.5c22.9-8.7,48-6.2,70,3.7
    c44.7,20.1,80.2,65.2,95.5,111c15,44.8,10.1,96-18.2,134.6c-29.7,40.5-79.4,54.3-127.6,53.4c-94.7-1.7-192.8-62.2-285.8-20.1
    c-34,15.4-64.1,44.3-69.1,82.7c-2.7,21,3,41.8,19.7,55.6c14.9,12.4,34.7,19.6,53.4,23.6c68,14.8,154.2-15.9,163.4-93.5
    c4.5-38.6-12.3-77.2-37-106.1c-27-31.6-64.3-51.7-105-59.5c-40.9-7.9-84.7-3.3-122.2,15.3c-35.5,17.6-62.7,47.6-77.3,84.4
    c-14.2,35.9-16.3,77.3-2,113.5c14.3,36.3,44.3,63.6,78.7,80.8c36.3,18.2,76.8,26.7,116.9,31.4c41.1,4.8,83.9,7.1,124.7-1.7
    c39.4-8.5,74.3-29.3,93.5-65.7c10.9-20.5,16.7-43.3,22.9-65.5c6.3-22.4,13.4-44.2,24.7-64.6c21-38.1,54.7-72.1,98.8-80.4
    c27.8-5.2,56.6,0,83.5,7.5c20.8,5.8,41.4,14,59.3,26.3c18.2,12.5,33.5,29.8,39.8,51.3c6,20.7,2.4,42.8-7.4,61.8
    c-20.8,40.3-65.5,66.3-110.8,64.4c-25.7-1.1-49.9-12-64.4-33.7c-13.1-19.5-17.8-43.9-16.6-67.2c2.1-43.8,25-84.8,57.7-113.3
    c74.9-65.3,188-50.7,260.8,10.2c38,31.8,66.5,73.6,106,103.7c34.2,26.1,81.3,45,123.9,29.5c39.8-14.5,62.7-54.5,78.2-91.4
    c14.8-35.3,26.2-80,9.2-116.7c-18.8-40.5-66.4-50.5-107-50.2c-42.1,0.3-84.2,12.1-120.3,33.7c-35.6,21.3-65.6,52.5-83,90.3
    c-17.2,37.3-22.5,79.9-15.7,120.4c7.1,41.6,27.4,79.6,59.1,107.5c33.8,29.7,76.9,47.2,120.7,55.5c88.5,16.9,182.6-1.4,260-46.9
    c78.2-46,136.4-120.1,164.5-206.3c14.1-43.3,20.6-89.1,18.5-134.5c-2.1-45-10.9-93.1-45-125.3c-31.8-30.1-76.8-43.4-119.7-45.9
    c-45.6-2.7-92.5,7.2-131.9,30.7c-41.2,24.6-71.2,63.6-95.4,104.3c-24.8,41.6-44,86.8-55.7,133.8c-21.9,88.2-16.6,186.5,33.6,264.5
    c43.6,67.5,117.6,119.3,199.2,124.7c37.1,2.4,90.7-1.3,104.8-42.6c13.6-39.7-7.3-82.6-36.8-109c-35.3-31.6-83.7-41.5-129.7-43.6
    c-52.2-2.4-105.2,1.9-156.8,9.7c-53.1,8.1-105.5,21-156.4,38.4c-24.6,8.4-48.9,18-72.7,28.5c-20.5,9.1-41,18.7-58.9,32.3
    c-30.6,23.1-49.2,59.1-33.4,96.7c13.8,32.9,48.9,57.8,85.3,53.8c37.3-4,67.6-34.3,78.5-69.1c11.9-37.8-2-77.5-30.3-104.2
    c-29.8-28.1-70.5-38.6-110.4-31.4c-45.9,8.3-85.7,35.9-123.1,62.2c-42,29.5-84.5,57-131.1,78.7c-24.7,11.5-50.1,21.6-76,30.1
    c-26.1,8.6-53.5,15.8-81.2,14c-24.3-1.6-47.8-11.2-64.2-29.6c-16.1-18-21.7-42.4-12.3-65C519.5,723,584.5,715.2,633,716
    c50.3,0.8,102.1,16.1,135.9,55.4c16.3,18.9,25.6,41.4,28.5,66.2c2.7,23,1.3,46.9,8.4,69.3c12.7,40.3,52.8,57.5,91.4,63.4
    c49.8,7.7,102.4,3.9,151.5-6.5c39.9-8.4,93-20.3,108.8-63.5c12.2-33.4-0.5-73.5-26-97.2c-31.7-29.3-79.2-34-119.8-25.4
    c-40,8.5-78.8,30.4-103.3,63.7c-24.9,34-28.6,76.5-15.4,115.9c14.7,43.9,44.8,81.3,74.5,116.1C968,1074,968.9,1073.1,968.4,1072.5
    L968.4,1072.5z"
          />
          <defs>
            <radialGradient id="myGradient">
              <stop offset="0%" stop-color="#ff7900" />
              <stop offset="27.8%" stop-color="rgba(28, 40, 152, 0.91)" />
              <stop offset="100%" stop-color="rgba(28, 38, 128, 0)" />
            </radialGradient>
          </defs>

          <circle r="60" fill="url('#myGradient')">
            <animateMotion dur="100s" repeatCount="indefinite" begin="-20s">
              <mpath xlinkHref="#motionPath"></mpath>
            </animateMotion>
          </circle>
          {/* <circle r="60" fill="url('#myGradient')">
            <animateMotion dur="100s" repeatCount="indefinite" begin="-40s">
              <mpath xlinkHref="#motionPath"></mpath>
            </animateMotion>
          </circle>
          <circle r="60" fill="url('#myGradient')">
            <animateMotion dur="100s" repeatCount="indefinite" begin="-90s">
              <mpath xlinkHref="#motionPath"></mpath>
            </animateMotion>
          </circle> */}
        </svg>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid id="page404" container textAlign={"center"}>
          <Grid container item xs={12} pb={10} className="title-wrapper">
            <Grid item xs={12} className="page404-status-code">
              {f({id: "not_found_oops"})}
            </Grid>
            <Grid item xs={12} className="page404-title">
              {f({id: "not_found_text"})}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} className="page404-description">
              {f({id: "not_found_congratulations"})}
            </Grid>
            <Grid item xs={12} className="page404-description">
              {f({id: "not_found_sorry"})} 
            </Grid>
            <Grid item xs={12} pt={5}>
              <Button className="page404-button" onClick={() => navigate("/")}>
                {f({id: "not_found_button_homepage_text"})}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}