import { ActionTypesList, apiURls } from "../common/contstants";

export function getJobs() {

  const url = apiURls.jobList();

  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',

      })
      .then(response => response.json())
      .then(res => {
        dispatch({
          type: ActionTypesList.SUCCESS_FETCH_JOB_LIST,
          data: (res.data && res.data.objects) || []
        })
      })
      .catch((err) => {
        console.log('Service api error', err)
      })
  }
}
export function getJobById(id) {
  const url = apiURls.jobDetail(id);

  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',

      })
      .then(response => response.json())
      .then(res => {
        if (res.data.object) {
          dispatch({
            type: ActionTypesList.SUCCESS_FETCH_JOB_INFO,
            data: res.data.object || {}
          })
        } else {
          dispatch({
            type: ActionTypesList.SUCCESS_FETCH_JOB_INFO,
            data: { error: true }
          })
        }
      })
      .catch((err) => {
        console.log('Service api error', err)
      })
  }
}

// export function handleApplyJob(payload) {
//     return (dispatch) => {
//         dispatch({
//             type: ActionTypesList.JOB_APPLY_RESPONSE,
//             data: {
//                 message: 'Your request was successfully send'
//             }
//         })
//     }
// }

export function handleApplyJob(payload) {
  const url = apiURls.applyJob();

  var requestOptions = {
    method: 'POST',
    body: payload
  };
  // console.log('-=-=-requestoptions=-', requestOptions, payload)
  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',
        ...requestOptions

      })
      .then(response => response.json())
      .then(res => {
        let contactRes = {}
        if (res.errors || (res.data && res.data.status === 'error')) {
          contactRes.error = res.errors ? res.errors[0].userMessage : res.data.message;
        } else {
          contactRes = res;
          contactRes.message = "success_message"

        }
        dispatch({
          type: ActionTypesList.JOB_APPLY_RESPONSE,
          data: contactRes
        })
        setTimeout(() => {
          dispatch({
            type: ActionTypesList.JOB_APPLY_RESPONSE,
            data: {}
          })
        }, 10000)
      })
      .catch((err) => {
        dispatch({
          type: ActionTypesList.JOB_APPLY_RESPONSE,
          data: {
            error: err.message
          }
        })
        setTimeout(() => {
          dispatch({
            type: ActionTypesList.JOB_APPLY_RESPONSE,
            data: {}
          })
        }, 10000)
      })
  }
}

export function createCareer(payload) {
  const url = apiURls.createCareer();

  var requestOptions = {
    method: 'POST',
    body: payload
  };
  // console.log('-=-=-requestoptions=-', requestOptions, payload)
  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',
        ...requestOptions

      })
      .then(response => response.json())
      .then(res => {
        let contactRes = {}
        if (res.errors) {
          contactRes.error = res.errors[0].userMessage
        } else {
          res.message = "success_message";
          contactRes = res;

        }
        dispatch({
          type: ActionTypesList.CREATE_CAREER_RESPONSE,
          data: contactRes
        })
        setTimeout(() => {
          dispatch({
            type: ActionTypesList.CREATE_CAREER_RESPONSE,
            data: {}
          })
        }, 10000)
      })
      .catch((err) => {
        dispatch({
          type: ActionTypesList.CREATE_CAREER_RESPONSE,
          data: {
            error: err.message
          }
        })
        setTimeout(() => {
          dispatch({
            type: ActionTypesList.CREATE_CAREER_RESPONSE,
            data: {}
          })
        }, 10000)
      })
  }
  // return (dispatch) => {
  //     dispatch({
  //         type: ActionTypesList.CREATE_CONTACT_RESPONSE,
  //         data: []
  //     })
  // }
}