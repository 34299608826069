import * as React from 'react';
import '../../css/work-banner.scss'
import { useIntl } from 'react-intl';
function WorkBanner() {

  const title = "our_work_banner_title";
  const { formatMessage } = useIntl();
  return (
    <div className='work-banner'>
      <div className='work-banner-title'>
        <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: title }) }}></div>
      </div>
      <div data-aos="fade-up" className='work-banner-desc fade-el'>
        {formatMessage({ id: 'our_work_banner_desc' })}
      </div>
    </div >
  );
}
export default WorkBanner;
