import { connect } from 'react-redux';
import { getServicesList } from '../Actions/services';
import { footers } from '../common/contstants';
import FooterComponent from '../components/Footer';

const serviceMap = (services) => {
  footers[1].menus = services
  return footers;
}
const mapStateToProps = (state, props) => {
  return ({
    service: state.service.servicesList,
    ...props,
    footers: serviceMap(state.service.servicesList)
  })
}

const mapDispatchToProps = (dispatch) => ({
  getServicesList: () => dispatch(getServicesList())
})

export default connect(mapStateToProps, mapDispatchToProps)(FooterComponent);