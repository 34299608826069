import * as React from 'react';
import '../../css/cs-dev.scss'
import { Grid } from '@mui/material';

function DevelopingService(props) {
    const { data: { title, data, list_type } } = props;
    function nextChar(index) {
        return String.fromCharCode('a'.charCodeAt(0) + index).toUpperCase();
    }
    return (
        <div className='cs-dev'>
            <div data-aos="fade-up" className='title fade-el'>{title}</div>
            {list_type !== 'number_image' && <Grid container direction={'column'} rowGap={8} className='dev-wrapper-parent'>
                {
                    data.map((val, index) => {
                        return <div>
                            <Grid container key={`cs-dev-${index}`} alignItems={'center'} className='dev-wrapper'>
                                <Grid md={1}>
                                    <div  data-aos="fade-up" className='lt-number fade-el'> {list_type === 'number' ? index + 1 : nextChar(index)}</div>
                                </Grid>
                                <Grid md={10}>
                                    <div  data-aos="fade-up" className='sub-desc fade-el' dangerouslySetInnerHTML={{ __html: val.text }}></div>
                                </Grid>
                            </Grid>

                        </div>
                    }
                    )
                }

            </Grid>
            }
            {
                list_type === 'number_image' &&
                <Grid container direction={'column'} rowGap={5} >
                    {
                        data.map(((val, idx) => {
                            return <Grid key={`cs-hl-ni-${idx}`}>
                                <div className={`cs-hl-ni ${idx % 2 !== 0 ? 'flt-rt' : ''}`}>
                                    <Grid container alignItems={'center'} className='cs-hl-ni-title'>
                                        <Grid > <div className='lt-gradient-number'><div>{idx + 1}</div></div></Grid>
                                        <Grid md sm xs>
                                            <div className='ni-title' dangerouslySetInnerHTML={{ __html: val.text }}></div>
                                        </Grid>
                                    </Grid>
                                    {/* <div className='ni-title' dangerouslySetInnerHTML={{ __html: val.head }}> */}
                                    {/* </div> */}
                                    <img src={val.image} alt='arrow text' />
                                    <div className='ni-desc' dangerouslySetInnerHTML={{ __html: val.tail_text }}></div>
                                </div>
                            </Grid>
                        }))
                    }

                </Grid>
            }
        </div >
    );
}
export default DevelopingService;
