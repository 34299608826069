import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";
import logo from '../../assets/Logo.svg';
import skiySmall from '../../assets/skiy-small.png'
import goUpEN from '../../assets/goUpEN.png'
import goUpDE from '../../assets/goUpDE.png'
import downArrow from '../../assets/down-arrow.svg'
import backArrow from '../../assets/arrow-back.svg'
import closeIcon from '../../assets/close.svg';
import mobileLogo from '../../assets/mobile-logo.svg';
import '../../css/header.scss'
import { useState } from 'react'
import { useEffect } from 'react';
import { getPageURl, getPathLocale, getRoutePath, navToService, scrollHeaderHeight } from '../../common/contstants';
import { useLocation, useParams } from 'react-router-dom';



function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false}
      direction="down"
      in={true}>
      {children}
    </Slide>
  );
}

function ResponsiveAppBar(props) {
  const { pages, locale, switchLocale, loading, isLoading } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { formatMessage } = useIntl();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const open = Boolean(anchorEl);
  const [mobileSubNavigationActive, setMobileSubNavigationActive] = useState(-1);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let scrollToTopTrigger = useScrollTrigger();

  const { service, languages } = props;
  useEffect(() => {
    if (!service || !service.length) {
      props.getServicesList();
    }
    if (location.pathname.indexOf('work') > -1 || location.pathname.indexOf('arbeit') > -1) {
      props.getAllActiveWorkList()
    }
  }, [])
  useEffect(() => {
    if (!languages || (!languages.length && !languages.error)) {
      props.getLanguages();
    }
  }, [])
  const handleClose = () => {
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = (item) => {
    setAnchorElNav(null);
    if (item && item.link) {
      const link = getRoutePath(item.link);
      navigate(link)
    }
  };

  const navigateToHome = () => {
    if (props.locale != undefined) {
      navigate('/' + props.locale)
    }
    else {
      navigate('/')
    }
  }

  const navigateToHomeOnClose = () => {
    navigate('/' + props.locale);
    setAnchorElNav(null);
  }

  const serviceNav = (name) => {
    let link = navToService(name);
    navigate(link)
    handleClose();
  }

  // Smooth scroll
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  window.onscroll = handleClose
  const changeLocale = (data) => {
    localStorage.setItem('locale', data.short_name)
    localStorage.setItem('localeCode', data.code)
    switchLocale(data.short_name);
    loading("yes");

    if (location.pathname.indexOf('service') === -1 && location.pathname.indexOf('dienstleistungen') === -1 && location.pathname.indexOf('work') === -1 && location.pathname.indexOf('arbeit') === -1) {
      props.getServicesList()
      props.getClients();
      const path = getPageURl(location.pathname, data.short_name)
      if (path == "/en" || path == "/de") {
        props.getHomeActiveWorkList();
        props.getHomeBlogs();
      }
      else if (path == '/about-us' || path == '/ueber-uns') {
        props.getHomeActiveWorkList();
      }
      else if (path == '/blog' || path == '/blog-german') {
        props.fetchActiveBlogList();
      }
      if (path == "/") {
        navigate(location.pathname)
      }
      else {
        navigate(path)
      }
      loading("no");
    }
    else if (location.pathname.indexOf('services') !== -1 || location.pathname.indexOf('dienstleistungen') !== -1) {
      const serviceId = pages[1]?.menu.find(val => val.slug_url.toLowerCase() === params.id.toLowerCase())?.category_id;
      props.getServicesList()
      setTimeout(() => {
        const path = pages[1]?.menu.find(val => val.category_id == serviceId).slug_url;
        navigate((location.pathname.indexOf('services') !== -1 ? "/dienstleistungen/" : "/services/") + path)
        loading("no");
      }, 1000);

    }
    else if (location.pathname.indexOf('work') !== -1 || location.pathname.indexOf('arbeit') !== -1) {
      console.log(pages[1].work.list.work_slugs);
      if (params.id) {
        let our_work_slg = pages[1].work.list.work_slugs.find((val) => {
          let s = val.slug.split(',');
          return s[0] == params.id || s[1] == params.id
        }).slug.split(",");
        let url = data.code == 'l2' ? our_work_slg[0] : our_work_slg[1]
        navigate((data.code == 'l2' ? "/arbeit/" : "/work/") + url)
        loading("no");
      }
      else {
        props.getAllActiveWorkList()
        const path = getPageURl(location.pathname, data.short_name)

        navigate(path);
        loading("no");
      }

    }
  }
  if (window.location.pathname == "/" && localStorage.getItem('locale')) {
    navigate(localStorage.getItem('locale'));
  }
  const [small, setSmall] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0)
  const [scrollDownDirection, setScrollDownDirection] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setScrollDownDirection(pageYOffset < window.pageYOffset)
        setPageYOffset(window.pageYOffset)
        const offset = scrollHeaderHeight()
        setSmall(window.pageYOffset > offset)
      }
      );
    }
  }, [pageYOffset, scrollDownDirection]);

  return (
    <React.Fragment>
      <HideOnScroll {...props}>

        <AppBar id="header" className={`header ${small ? "small" : ""
          } 
        `
        }
          //  position="static" className={`${scrollDirection === "down" ? "hide" : "show"}`}
          sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Container className='header-container'>
            <Toolbar disableGutters>
              <Box className={`headerLogo ${small ? 'd-none' : ''}`}><img src={logo} alt="Skiy logo" className="cursor" onClick={navigateToHome} /></Box>
              <Box className={`headerLogoSmall ${!small ? 'd-none' : ''}`}><img src={skiySmall} alt="Skiy small logo" className="cursor" onClick={navigateToHome} /></Box>

              {/* <Box><img src={logo} /></Box> */}
              <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center' }}>
                <Box className='navlist-main'>

                  {pages.map((page, idx) => {
                    return (<span key={idx}>
                      {page.type === 'dropdown' ?
                        (<><Button
                          key={idx}
                          className={`header-title ${location.pathname.indexOf('/service') > -1 ? 'header-selected' : ''}`}
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          sx={{ my: 2, color: 'white', background: 'none', textTransform: 'none', fontSize: '24px' }}
                        >
                          {formatMessage({ id: page.key })}
                          <img src={downArrow} alt="down arrow" />
                        </Button>
                          <Menu
                            id="basic-menu"
                            className='header-drpdwn-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button'
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}

                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            disableScrollLock={true}
                          >
                            {
                              page.menu.map((menuItem, idx) => {
                                return (
                                  <span key={idx}>
                                    <Link onClick={() => {
                                      serviceNav(menuItem.slug_url);
                                    }} className='cursor drp-menu-text'>
                                      {menuItem.name}</Link>
                                    {
                                      idx !== page.menu.length - 1 && <span style={{ color: '#FFFFFF' }}>|</span>
                                    }
                                  </span>
                                )
                              })
                            }
                          </Menu></>) :
                        <Button
                          key={page.key}
                          className={`header-title ${location.pathname === page.link ? 'header-selected' : ''}`}
                          onClick={() => handleCloseNavMenu(page)}
                          sx={{ my: 2, color: 'white', textTransform: 'none', fontSize: '24px' }}
                        >
                          {formatMessage({ id: page.key })}
                        </Button>
                      }</span>)
                  }
                  )}

                </Box>
              </Box>
              <Box sx={{ flexGrow: 0 }} className="header-link-menu">
                <Button variant="outlined" className='btn-talk lets-talk-menu' sx={{ borderColor: '#FF7900', color: '#FFFFFF' }}
                  onClick={() => {
                    navigate(getRoutePath('contact'));
                  }}
                >
                  {formatMessage({ id: 'lets_talk_button_text' })}
                </Button>

                {/* Hide Temporary */}
                {
                  languages && !languages.error && languages.map((lang, j) => {
                    return (
                      <Button
                        key={j}
                        id={lang.code}
                        variant="text"
                        disabled={isLoading == 'yes'}
                        className={`btn-lang lang-menu ${locale === lang.short_name ? 'locale-active' : 'locale-disable'}`}
                        onClick={() => changeLocale(lang)}
                      >{lang.short_name}
                      </Button>
                    )
                  })
                }

                {/* </Box> */}
                {/* <Box sx={{ flexGrow: 0, display: { xs: 'flex', sm: 'none', md: 'none' }, alignItems: 'center' }}> */}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  className='open-nav-menu'
                  sx={{ flexGrow: 0, display: { sm: 'inline-flex', md: 'none' }, alignItems: 'center' }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  //   className='test'
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <MenuItem className='mobile-header'>
                    <span className='mobile-logo' onClick={navigateToHomeOnClose}>
                      <img src={mobileLogo} alt="close mobile menu" />
                    </span>
                    <span className='close-icon' onClick={handleCloseNavMenu}>
                      <img src={closeIcon} alt="close mobile menu" />
                    </span>
                  </MenuItem>
                  {/* */}
                  <MenuItem className='mobile-menu-wrapper'>
                    {languages && !languages.error && languages.map((lang, j) => {
                      return (
                        <Button
                          key={j}
                          id={lang.code}
                          variant="text"
                          className={`btn-lang lang-mobile-menu ${locale === lang.short_name ? 'locale-active' : 'locale-disable'}`}
                          onClick={() => changeLocale(lang)}
                        >{lang.short_name}
                        </Button>
                      )
                    })
                    }
                  </MenuItem>
                  {/* Mobile Menu */}
                  <div>
                    {
                      mobileSubNavigationActive === -1 && (
                        <>
                          {
                            pages.map((page, idx) => (
                              page.type === "dropdown" ? (
                                <MenuItem key={page.key} className='custom-menu-mob-cont'>
                                  <Typography
                                    className='dropdown-title custom-menu-mob-title'
                                    aria-controls={open ? 'basic-mobile-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={() => {
                                      setMobileSubNavigationActive(idx);
                                    }}
                                    sx={{ my: 2, color: 'white', background: 'none', textTransform: 'none', fontSize: '24px' }}
                                  >
                                    {formatMessage({ id: page.key })}
                                    <img src={downArrow} alt="down arrow" />
                                  </Typography>
                                </MenuItem>
                              ) : (
                                <MenuItem key={idx} onClick={() => handleCloseNavMenu(page)} className='custom-menu-mob-cont'>
                                  <Typography textAlign="center" className='custom-menu-mob-title' >{formatMessage({ id: page.key })}</Typography>
                                </MenuItem>
                              )
                            ))
                          }
                        </>
                      )
                    }
                  </div>
                  {
                    (mobileSubNavigationActive > -1 && pages[mobileSubNavigationActive].menu) && (
                      <div className='toggle-services-list'>
                        <Button className='dropdown-title back-button' onClick={() => setMobileSubNavigationActive(-1)}>
                          <img src={backArrow} alt="back arrow" />
                        </Button>
                        <div>
                          {
                            pages[mobileSubNavigationActive].menu.map((menuItem, idx) => {
                              return (
                                <MenuItem key={idx} onClick={() => {
                                  serviceNav(menuItem.slug_url);
                                  setMobileSubNavigationActive(-1);
                                  handleCloseNavMenu();
                                }} className='custom-menu-mob-cont'>
                                  <Typography textAlign="center" className='custom-menu-mob-title' >{menuItem.name}</Typography>
                                </MenuItem>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  }


                  {/* <MenuItem className='mobile-menu-wrapper'>   
                    <Button 
                        variant="outlined" 
                        className='btn-talk lets-talk-mobile-menu' 
                        sx={{ borderColor: '#FF7900', color: '#FFFFFF' }}
                        onClick={() => {
                          navigate(getRoutePath('contact'));
                          handleCloseNavMenu();
                        }}
                      >
                        {formatMessage({ id: 'lets_talk_button_text' })}
                    </Button>
                  </MenuItem> */}

                </Menu>
              </Box>
              {/* </div> */}
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <img src={formatMessage({ id: 'goUp' }) == 'goUpEN' ? goUpEN : goUpDE} onClick={scrollToTop} className={`go-up ${scrollToTopTrigger ? '' : 'hide-scroll-to-top'}`} alt="Go up" />
    </React.Fragment>
  );
}
export default ResponsiveAppBar;
