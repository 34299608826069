import { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import '../../css/service-page.scss'
import HomePartners from "../Home/Partners";
import ServiceBanner from "./Banner";
import particlesData from '../../common/particlesjs-config.json';
import ServiceContent from "./ServiceContent";
import ServiceContentCard from "./ServiceCard";
import ServiceListItems from "./ServiceListItems";
import Clients from "../Home/Clients";
import CTAComponentUpdated from "../General/CTAComponentUpdated";
import Workflows from "./Workflow";
import Values from "./Values";
import Faq from "./Faq";
import OurWorkList from '../Home/OurWorkList';
import { servicesCommonConfig, MainCTA } from "../../common/contstants";
import TTused from "./TTused";
import ContactUS from "../Home/ContactUS";
import PopupGame from "./PopupGame";
import { HelmetWrapper } from "../HelmetWrapper";
import PageNotFoundInner from "../PageNotFoundInner";

function Services(props) {
  const params = useParams();
  const location = useLocation();
  const { serviceInfo, partners, clients, worksList, service } = props;
  const [metaData, setMetaData] = useState({ title: '', description: '', isStatic: false });
  const [error, setError] = useState(false);
  const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets 
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready 
    // starting from v2 you can add only the features you need reducing the bundle size 
    await loadFull(main);
  };
  const particlesLoaded = (container) => {
    // console.log(container);
  };

  const getServiceInfo = () => {

    if (service && service.length) {
      const serviceId = service.find(val => val.slug_url.toLowerCase() === params.id.toLowerCase())?.category_id;
      if (serviceId != undefined) {
        props.getServiceDetails(serviceId);
        props.getPartners('all');
        props.getClients();
        props.getWorkListByServiceId(serviceId);
        setError(false)
      }
      else {
        setError(true)
      }

    }
  }
  // Fetch meta data
  useEffect(() => {
    if (serviceInfo.meta_title && serviceInfo.meta_description) {
      setMetaData({ title: serviceInfo.meta_title, description: serviceInfo.meta_description, isStatic: false });
    }
  }, [serviceInfo]);

  useEffect(() => {
    getServiceInfo();
  }, [service, location.pathname])
  let section5;
  if (serviceInfo && serviceInfo.section_5) {
    const { head, text, button_text } = serviceInfo.section_5;
    section5 = {
      title: head, description: text, btnText: button_text, btnLink: 'contact'
    }
  }
  return (
    <div className="root-services">
      <HelmetWrapper data={metaData} />
      {/* <div className="service-name-left">{serviceInfo.name}</div> */}
      {serviceInfo.id  && <div className="service-bg1">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          height='100%'
          width="100%"
          options={particlesData}
        />
        <div className="about-bg2">
          <div className="top-bg">
            <ServiceBanner name={serviceInfo.service_title} desc={serviceInfo.service_overview} />
            <HomePartners partners={partners} limit={6} />
          </div>
        </div>

      </div>
      }
      {serviceInfo.ui_ux && <PopupGame info={serviceInfo.ui_ux} />}
      {serviceInfo.section_1 && <ServiceContent info={serviceInfo.section_1} />}
      {serviceInfo.section_2 && <ServiceContentCard info={serviceInfo.section_2} section9={serviceInfo.section_9} />}
      {serviceInfo.section_3 && <ServiceListItems info={serviceInfo.section_3} />}
      {!error && worksList && worksList.length && <OurWorkList ourworklist={worksList} showTitle={true} showButton={true} title={serviceInfo?.our_work_title} />}
      {!error && clients && partners && <Clients clients={clients} partners={partners} />}
      {serviceInfo.section_4 && <TTused info={serviceInfo.section_4} />}
      {!error && section5 && <CTAComponentUpdated info={section5} dynamic={true} />}
      {serviceInfo.section_6 && <Workflows info={serviceInfo.section_6} />}
      {serviceInfo.section_7 && <Values info={serviceInfo.section_7} />}
      {!error && serviceInfo && MainCTA && <ContactUS info={MainCTA} />}
      {serviceInfo.section_8 && <Faq info={serviceInfo.section_8} />}
      {!error && <div className="service-final-cta">
        {serviceInfo && servicesCommonConfig.finalCTA && <CTAComponentUpdated info={servicesCommonConfig.finalCTA} noBackground={true} />}
      </div>
      }
      {error && !serviceInfo.id && <PageNotFoundInner />}
      {/* <Signup /> */}
    </div>
  )
}

export default Services;