import * as React from 'react';
import '../../css/solutions.scss'
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRef } from 'react';
import { createRef } from 'react';
import { useIntl } from 'react-intl';

function Solutions() {
  const { formatMessage } = useIntl();
  const solutionsData = [
    {
      id: 'no_emp_text',
      title: 'Number of employees',
      value: '60+'
    },
    {
      id: 'years_emp_text',
      title: 'Years average employee experience',
      value: '7+'
    }, {
      id: 'succ_projects_text',
      title: 'Successful projects',
      value: '35+'
    }, {
      id: 'cust_redem_text',
      title: 'Customer retention',
      value: '95%'
    }, {
      id: 'surr_rev_text',
      title: 'Current yearly revenue',
      value: '1.5M€+'
    }
  ]
  const elementsRef = useRef(solutionsData.map(() => createRef()));
  const elementContentRef = useRef(solutionsData.map(() => createRef()));
  const createTimeout = (idx) => {
    setTimeout(() => {
      if (elementsRef.current[idx].current) {
        elementsRef.current[idx].current.style.display = 'block';
        elementContentRef.current[idx].current.style.color = '#FFFFFF';
        intervalD(++idx)
      }
    }, 1000)
  }
  const intervalD = (idx) => {
    
    if (idx === solutionsData.length) {
      setTimeout(() => {
        solutionsData.forEach((data, idx) => {
          if (elementsRef.current[idx].current) {
            elementsRef.current[idx].current.style.display = 'none';
            elementContentRef.current[idx].current.style.color = '#878D90';
          }
        })
        createTimeout(0)
      }, 1000)
    } else {
      createTimeout(idx)
    }
  }

  useEffect(() => {
    intervalD(0)
  },[]);
  return (
    <div className="home-solutions">
      <div data-aos="fade-up" className='header fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'home_vision_title' }) }}>

      </div>
      <div data-aos="fade-up" className='desc fade-el'>
        <div className='desc-item'>
          {formatMessage({ id: "home_vision_desc1" })}
        </div>
        <div className='desc-item'>
          {formatMessage({ id: "home_vision_desc2" })}
        </div>
        <div className='desc-item'>
          {formatMessage({ id: "home_vision_desc3" })}
        </div>
        <div className='desc-item'>
          {formatMessage({ id: "home_vision_desc4" })}
        </div>
        <div className='desc-item'>
          {formatMessage({ id: "home_vision_desc5" })}
        </div>
      </div>
      <Grid container columnGap={{ md: 8, sm: 8, xl: 8, xs: 4 }} rowGap={{ xs: 4 }} alignItems={'center'} justifyContent="space-around"
        direction="row"
        className='main-content'
      >
        {
          solutionsData && solutionsData.map((solution, idx) => {
            return <Grid container className={`content ${(idx % 2 === 0 ? 'half-down' : 'half-up')}`} textAlign={"center"}
              justifyContent="center"
              alignItems={'center'}
              direction="column"
              key={'solution' + idx}
            >
              <div ref={elementsRef.current[idx]} className={`test+${idx} ` + (idx % 2 === 0 ? 'half-down-circle' : 'half-circle')}>
                <div className="border-circle left-circle"></div>
                <div className="border-circle right-circle"></div>
              </div>
              <div ref={elementContentRef.current[idx]} className={`content-inner ${(idx % 2 === 0 ? 'half-down-content' : 'half-content')}`}>
                <span data-aos="fade-up" className='value fade-el'>{solution.value}</span>
                {/* <br /> */}
                <div data-aos="fade-up" className='title fade-el'>{formatMessage({ id: solution.id })}</div>
              </div>
            </Grid>
          })
        }
      </Grid>
    </div>
  );
}
export default Solutions;
