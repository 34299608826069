import * as React from 'react';
import '../../css/cs-tech.scss'
import { Grid } from '@mui/material';

function CSTech(props) {
    const { data } = props;
    return (
        <div className='cs-tech'>
            <Grid container columnGap={10} className='cs-tech-grid cs-tech-wrapper' alignItems={'end'}>
                {
                    data.map(((val, idx) => {
                        return <Grid key={`tech-${idx}`}>
                            <div className='cs-tech-item'>
                                <div className='tech-logo-container'>
                                    <img src={val.icon} alt="tech logo" />
                                </div>
                                <div className='cs-tech-title'>{val.name}</div>
                            </div>
                        </Grid>
                    }))
                }

            </Grid>
        </div >
    );
}
export default CSTech;
