import * as React from 'react';
import '../../css/cs-actions.scss'
import { Button } from '@mui/material';

function CSAction(props) {
    const { data } = props;
    const handleAction = (action) => {
        window.open(action.link)
    }
    return (
        <div className='cs-actions'>
            <div className='action-container fade-el' data-aos="fade-up">
                {
                    data && data.length && data.map((action) => {
                        return <Button className='cs-btn-action' onClick={() => handleAction(action)} >{action.title}</Button>
                    })
                }
            </div>
        </div >
    );
}
export default CSAction;
