import * as React from "react";
import ContactUsForm from "./ContactUsForm";
import "../../css/contact-us.scss";
import "../../assets/animation/css/address-animation.css";
import LocationsMap from "./LocationsMap";
import { useIntl } from 'react-intl';
function ContactUsComponent(props) {
  const { formatMessage } = useIntl();
  return (
    <>
      <div className="contact-us">
        <div className="contact-us_1"></div>
        <div className="top-bg">

          <div className="contact-us-container">
            <div className="contact-us-bg-top">
              <div data-aos="fade-up" className="lbl-title fade-el" dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'contact_us_banner_title' }) }}>
                
              </div>
            </div>
            <div className="contact-us-bg-bottom">
              <div data-aos="fade-up" className="lbl-contact-subheader fade-el" dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'contact_us_banner_desc' }) }}>

              </div>
              <div data-aos="fade-up" className="lbl-contact-main fade-el">
                <div>info@skiy31.de</div>
                <div>+49 (0) 6234 809 26 76</div>
              </div>
            </div>
          </div>
          <div className="contact-us-form-container">
            <ContactUsForm {...props} />
          </div>
        </div>
      </div>

      <LocationsMap />
    </>
  );
}

export default ContactUsComponent;
