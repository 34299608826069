import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/home-about-us.scss';
import { useNavigate } from 'react-router';
import 'react-html5video/dist/styles.css';
import { getRoutePath } from '../../common/contstants';
import StyleOnTouchEvent from '../../common/styleOnTouchEvent';
import { useIntl } from 'react-intl';
export default function AboutUs() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  StyleOnTouchEvent('.about-us-button');

  return (
    <div id="about-us">
      <Grid container>
        <Grid md={6} sm={12} xs={12} lg={6}>
          <div data-aos="fade-up" className='about-us-title fade-el'>
            <span className='lb'>{formatMessage({ id: 'home_about_title1' })}&nbsp;</span><span className='lb'>{formatMessage({ id: 'home_about_title2' })}</span>
          </div>
          <Grid container>
            <Grid className='about-us-content'>
              <div data-aos="fade-up" className='content-item fade-el'>
                {formatMessage({ id: 'home_about_desc1' })}
              </div>
              <div className='content-item fade-el'>
                {formatMessage({ id: 'home_about_desc2' })}
              </div>
            </Grid>
            <Grid
              data-aos="fade-up"
              className='about-us-button-container fade-el'
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" }
              }}
            >
              <button className='about-us-button' onClick={() => navigate(getRoutePath('career'))}>{formatMessage({ id: 'home_about_button' })}</button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container md={6} sm={12} xs={12} lg={6} className='about-us-card' justifyContent={'center'} alignItems={'center'}>
          <Grid className='video-wrapper'>
            <iframe
              className='home-about-video'
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/_lbhQHV2Pig?si=VkI-8IgU2MZNtRGq&amp;controls=0&rel=0"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
          </Grid>
          <Grid
            className='about-us-button-container'
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" }
            }}
          >
            <button className='about-us-button' onClick={() => navigate(getRoutePath('career'))}>
              {formatMessage({ id: 'home_about_button' })}
            </button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}