import abbLogo from '../../assets/Home/AbbLogo.svg';
import risoLogo from '../../assets/Home/RisoLogo.svg';
import talisLogo from '../../assets/Home/TalisLogo.svg';
import arztlicheLogo from '../../assets/OurWorks/ArztlicheLogo.svg';
import bid4itLogo from '../../assets/OurWorks/Bid4itLogo.svg';
import finecottonLogo from '../../assets/OurWorks/FinecottonLogo.svg';
import fundflowLogo from '../../assets/OurWorks/FundflowLogo.svg';
import iuvandoLogo from '../../assets/OurWorks/IuvandoLogo.svg';
import mybuddyLogo from '../../assets/OurWorks/MybuddyLogo.svg';
import mymixLogo from '../../assets/OurWorks/MymixLogo.svg';
import spotsizeLogo from '../../assets/OurWorks/SpotsizeLogo.svg';

import abb from '../../assets/Home/Abb.svg';
import talis from '../../assets/Home/Talis.svg';
import riso from '../../assets/Home/Riso.svg';
import consulting from '../../assets/Home/Car.svg';
import togoLogo from '../../assets/OurWorks/TogoLogo.svg';
import arztliche from '../../assets/OurWorks/Arztliche.svg';
import bid4it from '../../assets/OurWorks/Bid4it.svg';
import finecotton from '../../assets/OurWorks/Finecotton.svg';
import fundflow from '../../assets/OurWorks/Fundflow.svg';
import iuvando from '../../assets/OurWorks/Iuvando.svg';
import mybuddy from '../../assets/OurWorks/Mybuddy.svg';
import mymix from '../../assets/OurWorks/Mymix.svg';
import spotsize from '../../assets/OurWorks/Spotsize.svg';
import togo from '../../assets/OurWorks/Togo.svg';

export const OurWorkData = [
    {
        id: 'consulting',
        logo: "",
        tags: ["remote teams(web app)", "remote teams(SAP)", "webapp", "ux/ui design"],
        image: consulting,
        content: "Digitizing automotive consulting experts",
        rightRotate: false
    },
    {
        id: 'Abb',
        logo: abbLogo,
        tags: ["ux/ui design", "ecommerce"],
        image: abb,
        content: "Improving designs of global leading equipment provider",
        rightRotate: false
    },
    {
        id: 'Riso',
        logo: risoLogo,
        tags: ["mob app", "ux/ui design", "branding"],
        image: riso,
        content: "Upgrading the tax-free salary provider",
        rightRotate: true
    },
    {
        id: 'Talis',
        logo: talisLogo,
        tags: ["remote teams(CAD)"],
        image: talis,
        // content: "Providing CAD designs for a water industry leader",
        content: "CAD Engineering for a leading provider of solutions for the entire water flow",
        rightRotate: true
    },
    {
        id: 'Mymix',
        logo: mymixLogo,
        tags: ["ecommerce"],
        image: mymix,
        content: "Developing an e-commerce store for personalized gifts",
        rightRotate: true
    },
    {
        id: 'Finecotton',
        logo: finecottonLogo,
        tags: ["ecommerce"],
        image: finecotton,
        content: "Building a shirt maker webshop",
        rightRotate: true
    },
    {
        id: 'Spotsize',
        logo: spotsizeLogo,
        tags: ["remote teams"],
        image: spotsize,
        content: "Developing an app that helps you find your perfect shoe size",
        rightRotate: true
    },
    {
        id: 'Mybuddy',
        logo: mybuddyLogo,
        tags: ["ux/ui design", "web app"],
        image: mybuddy,
        content: "Creating an app to promote social cohesion across cultures",
        rightRotate: true
    },
    {
        id: 'Iuvando',
        logo: iuvandoLogo,
        tags: ["web app"],
        image: iuvando,
        content: "Creating an app to help cancer fighters",
        rightRotate: true
    },
    {
        id: 'Togo',
        logo: togoLogo,
        tags: ["mob app", "web app"],
        image: togo,
        content: "Developing the medical platform",
        rightRotate: true
    },
    {
        id: 'Arztliche',
        logo: arztlicheLogo,
        tags: ["ux/ui design", "website"],
        image: arztliche,
        content: "Designing and developind website for family doctor practice",
        rightRotate: true
    },
    {
        id: 'Fundflow',
        logo: fundflowLogo,
        tags: ["remote teams"],
        image: fundflow,
        content: "Upgrading online invoice discounting platform",
        rightRotate: true
    },
    {
        id: 'Bid4it',
        logo: bid4itLogo,
        tags: ["remote teams"],
        image: bid4it,
        content: "Providing specialists for the online auction platform",
        rightRotate: true
    }
];

export const Tags = {
    'all': 'All',
    'remote teams': 'remote_teams',
    'website': 'websites',
    'web app': 'web_apps',
    'mobile app': 'mobile_apps',
    'ui/ux design': 'ui/ux_design',
    'e-commerce': 'ecommerce',
    'branding': 'branding'
}

export const WorkingHours = {
    'all': 'All',
    'workingHours': 'Work Hours',
    'remote': 'remote',
    'fullTime': 'fullEmployment',
    'partTime': 'underemployment'
}

export const Locations = {
    'germany': 'germany',
    'worldwide': 'worldwide'
}

export const NumberMapping = {
    '0': 'zero',
    '1': 'one',
    '2': 'two',
    '3': 'three'
}