import { ActionTypesList, apiURls } from "../common/contstants";


export function getCaseStudyDetails(id) {
  const url = apiURls.csDetail(id);

  return (dispatch) => {
    fetch(url,
      {
        mode: 'cors',

      })
      .then(response => response.json())
      .then(res => {
        if (Object.keys(res.data).length > 0) {
          dispatch({
            type: ActionTypesList.FETCH_CS_DETAILS,
            data: res.data
          })
        } else {
          dispatch({
            type: ActionTypesList.FETCH_CS_DETAILS,
            data: { error: true }
          })
        }
      })
      .catch((err) => {
        console.log('cs api error', err)
      })
  }
}