import { Button } from '@mui/material';
import * as React from 'react';
import '../../css/connect-updated.scss'
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { getRoutePath } from '../../common/contstants';

function CTAComponentUpdated(props) {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { info: { title, description, image, btnText, btnLink }, noBackground, dynamic } = props;

    return (
        <div className='about-connect-updated'>
            {!image &&
                <div className='about-connect-updated-bg-main-container'>
                    <div className='about-connect-updated-bg-radiant'></div>
                </div>
            }
            <div className='about-connect-updated-outer-container'>
                <div className={`about-connect-updated-inner-container${noBackground ? "no-background" : ""}`}>
                    <div className='about-connect-updated-title-bg'>
                        <div className='about-connect-updated-title about-connect-updated-center' >
                            <div className={image ? 'cta-title-align' : ''} dangerouslySetInnerHTML={{ __html: dynamic ? title : formatMessage({ id: title }) }}></div>
                        </div>
                    </div>

                    <div className='about-connect-updated-main-bg'>
                        <div className='about-connect-updated-center'>
                            <div className='about-connect-updated-main ' >
                                <div className={image ? 'cta-title-align' : ''} dangerouslySetInnerHTML={{ __html: dynamic ? description : formatMessage({ id: description }) }}></div>
                            </div>

                            <div className='connect-button-wrapper'>
                                <Button className='button' onClick={() => navigate(getRoutePath(btnLink))}>{dynamic ? btnText : formatMessage({ id: btnText })}</Button>
                                <div className='button-radiant'></div>
                            </div>
                        </div>
                    </div >
                    {image &&
                        <div className='contact-img-container'>
                            <div className='bg-gradiant'></div>
                            <img src={image} className='contact-image' style={{ boxSizing: "border-box" }} alt="Contact Us" />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default CTAComponentUpdated;
