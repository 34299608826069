import { connect } from 'react-redux';
import { getPartners } from '../Actions/clients';
import Career from '../components/Career';
import { createCareer, getJobs } from '../Actions/career';

const mapStateToProps = state => ({
    partners: state.home.partners,
    jobs: state.career.jobs,
    careerCreateRes: state.career.careerCreateRes
})

const mapDispatchToProps = (dispatch) => ({
    getPartners: (type) => dispatch(getPartners(type)),
    getJobs: () => dispatch(getJobs()),
    createCareer: (payload) => dispatch(createCareer(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Career);