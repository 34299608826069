import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useIntl } from 'react-intl';

function CompareImage(props) {
  const { data } = props;
  const { formatMessage } = useIntl();
  return <div className="compare-image">
    <ReactCompareSlider className='compare-image-desktop'
      itemOne={<ReactCompareSliderImage src={data[0].image} srcSet={data[0].image} alt="Image one" />}
      itemTwo={<ReactCompareSliderImage src={data[1].image} srcSet={data[1].image} alt="Image two" />}
    />

    <div className='compare-image-mobile'>
      <div className='compare-image-wrapper'>
        <img className='compare-image-item' src={data[0].image} alt='Image one' />
      </div>
      <h2 className='compare-image-title'>{formatMessage({ id: "Before redesign" })}</h2>

      <div className='compare-image-wrapper'>
        <img className='compare-image-item' src={data[1].image} alt='Image two' />
      </div>

      <h2 className='compare-image-title'>{formatMessage({ id: "After redesign" })}</h2>
    </div>

  </div>
}

export default CompareImage;