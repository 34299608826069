import * as React from 'react';
import '../../css/partners.scss'
import { Grid } from '@mui/material';

function HomePartners(props) {
    const { partners, limit } = props;
    let partnersData = partners && partners.home_banner ? partners.home_banner : partners;
    let row1Data = partnersData ? Array.isArray(partnersData[0]) ? partnersData[0] : partnersData : null;
    row1Data = (row1Data && limit) ? row1Data.slice(0, limit) : row1Data;
    return (
        <div className="home-partners">
            <Grid container spacing={0} rowGap={{ md: 8, sm: 8, lg: 8, xs: 4 }} columnGap={{ xs: 4 }} justifyContent={'space-between'} className="home-partners-row1" id="partners">
                {row1Data && row1Data.map((imgData, idx) => {
                    return <Grid key={idx} item style={{ display: 'flex' }}
                        // lg={1.6} md={1.5} xs={6} sm={3}
                        className='home-partner-item'
                    >
                        <img src={imgData.client_logo} className='partner-logo' style={{ maxWidth: '100%' }} alt={imgData.client_name} />
                    </Grid>
                })
                }
            </Grid>
            {partnersData && partnersData.length >= 2 && Array.isArray(partnersData[1]) &&
                <Grid container spacing={0} rowGap={{ md: 8, sm: 8, lg: 8, xs: 4 }} columnGap={{ xs: 4 }} justifyContent={'space-between'} className="home-partners-row2" id="partners">
                    {partnersData[1].map((imgData, idx) => {
                        return <Grid key={idx} item style={{ display: 'flex' }}
                            className='home-partner-item'
                        >
                            <img src={imgData.client_logo} className='partner-logo' style={{ maxWidth: '100%' }} alt={imgData.client_name} />
                        </Grid>
                    })
                    }
                </Grid>
            }
        </div>
    );
}
export default HomePartners;
