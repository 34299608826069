import * as React from 'react';
import OurWork from './OurWork';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { NumberMapping } from '../General';
import { useNavigate } from 'react-router';
import { getRoutePath } from '../../common/contstants';


const OurWorkBlock = ({ block, idx }) => {
    return (
        <div key={idx} className='our-work-block'>
            {
                block.map((ourwork, index) => (
                    <OurWork key={index} data={ourwork} classname={`${NumberMapping[(index).toString()]}`} />
                ))
            }
        </div>
    )
}

export default function OurWorkList({ ourworklist, showTitle, showButton, title }) {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    let ourWorkListOfList = [];
    const chunkSize = 4;
    for (let i = 0; i < ourworklist.length; i += chunkSize) {
        const chunk = ourworklist.slice(i, i + chunkSize);
        ourWorkListOfList.push(chunk);
    }
    return (
        <div className='our-work-container'>
            {
                showTitle ? (<div data-aos="fade-up" className='our-work-list-heading fade-el'>{title || formatMessage({id: "our_work_title"})}</div>) : ""
            }
            <div className='our-work-list'>
                {
                    (ourWorkListOfList.length > 0)
                        ? ourWorkListOfList.map((block, index) => (
                            <OurWorkBlock key={index} block={block} />
                        ))
                        : <div className='our-work-no-data'>No Data Found</div>
                }
            </div>
            {
                showButton ? (<div data-aos="fade-up" className='our-work-button-container fade-el'>
                    <Button className='our-work-button' onClick={() => navigate(getRoutePath('work'))}>{formatMessage({id: "our_work_view_all"})}</Button>
                </div>) : ""
            }
        </div>
    )
} 