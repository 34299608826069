import {Helmet} from "react-helmet";
import { useIntl } from 'react-intl';

export function HelmetWrapper({data}) {
    const { formatMessage } = useIntl();

    return (
        <>
            {
                (data && !data.isStatic) && (
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{data.title || 'SKIY31'}</title>
                        <meta
                            name="description"
                            content={data.description}
                            />
                    </Helmet>
                )
            }
            {
                (data && data.isStatic) && (
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{ formatMessage({ id: data.title }) }</title>
                        <meta name="description" content={ formatMessage({ id: data.description }) }/>
                    </Helmet>
                )
            }
        </>
    );
}