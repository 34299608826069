// import { Tooltip, styled, tooltipClasses } from '@mui/material';
import * as React from 'react';
import '../../css/offices.scss'
import LocationsMap from '../Contact/LocationsMap';
import BestOfCountries from './BestOfCountries';

function AboutOffices() {

    return (
        <div className='about-offices'>
            <BestOfCountries />
           <LocationsMap />
        </div>
    );
} 

// const locations = [
//     {title: "New Port", top: "27%", left:"18.5%"},
//     {title: "Lviv", top: "28%", left:"32%"},
//     {title: "Mannheim", top: "29.5%", left:"26%"},
//     {title: "Andorra", top: "34%", left:"16.6%"},
//     {title: "Baku", top: "35%", left:"46.6%"},
//     {title: "Dubai", top: "48.5%", left:"50.6%"},
//     {title: "Ahmedabad", top: "50%", left:"64.7%"},
//     {title: "Chennai", top: "56%", left:"69.5%"},
//     {title: "Bangalore", top: "57%", left:"64.7%"},
//     {title: "Singapore", top: "66.5%", left:"80.5%"},
// ]

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     boxSizing: "borderBox",
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "center",
//     alignItems: "center",
//     padding: "20px",
//     maxWidth: "100%",
//     height: "10px",
//     background:
//       "linear-gradient(90deg, #1C2898 0%, rgba(28, 40, 152, 0.6) 100.83%)",
//     boxShadow: "0px 0px 15px rgba(0, 59, 232, 0.75)",
//     backdropFilter: "blur(4px)",
//     borderRadius: 8,
//     transform: "rotate(-180deg)",
//     flex: "none",
//     order: 0,
//     flexGrow: 0,
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     "&:before": {
//         background:
//         "linear-gradient(270deg, rgba(40, 49, 136, 0.66) 17.04%, rgba(39, 49, 137, 0.64) 59.39%, rgba(38, 48, 138, 0.76) 100%)",
//     },
//   }
// }));

export default AboutOffices;
