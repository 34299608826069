import { connect } from 'react-redux';
import { PrivacyPolicyDetailsEN } from '../common/contstants';
import { PrivacyPolicyDetailsDE } from '../common/contstants';
import PrivacyPolicy from '../components/TermsAndConditions/PrivacyPolicy';

const mapStateToProps = state => ({
    infoEN: PrivacyPolicyDetailsEN,
    infoDE: PrivacyPolicyDetailsDE
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);