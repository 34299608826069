import * as React from 'react';
import '../../css/service-content.scss'

function ServiceContent(props) {
    const { info: { title, description } } = props;
    return (
        <div className='service-page-content'> 
            <div>
                <div className='service-page-content-title'>
                    <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: title.split(".").join(".<br>") }}></div>
                </div>
                <div data-aos="fade-up" className='service-page-content-desc fade-el' dangerouslySetInnerHTML={{ __html: description }}>
                </div>
            </div>
        </div >
    );
}
export default ServiceContent;
