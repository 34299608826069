import { connect } from 'react-redux';
import { getCaseStudyDetails } from '../Actions/cs';
import CaseStudy from '../components/CaseStudy';
import { CaseStudyCTA } from '../common/contstants';

const mapStateToProps = state => ({
    csInfo: state.cs.csInfo,
    CTA: CaseStudyCTA,
    // partners: state.home.partners,
    // clients: state.home.clients,
    // worksList: _.get(state, 'works.worksList.list',[]),
})

const mapDispatchToProps = (dispatch) => ({
    getCaseStudyDetails: (id) => dispatch(getCaseStudyDetails(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudy);