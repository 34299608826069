import { Grid, Button } from '@mui/material';
import * as React from 'react';
import '../../css/story-updated.scss';
import { useIntl } from 'react-intl';
const StoryImage = ({ storyData }) => {
    const { blog_image: image } = storyData;
    return (
        <Grid container md={5} sm={5} lg={5} className='story-image-grid'>
            <img src={image} className='story-image' style={{}} alt="Story" />
        </Grid>
    )
}

const StoryDisplayData = ({ storyData, imageSide }) => {
  const { formatMessage } = useIntl();
    const { title, description, reading_time: readingTime, url } = storyData;
    return (
        <Grid container className={`story-text-container story-text-${imageSide === "right" ? "right" : "left"}-container`} md={6} sm={6} lg={6}>
            <Grid data-aos="fade-up" className='story-read-time fade-el'>{readingTime}</Grid>
            <Grid data-aos="fade-up" className='story-title fade-el'>{title}</Grid>
            <Grid data-aos="fade-up" className='story-description fade-el'  ><span dangerouslySetInnerHTML={{ __html: description }}></span></Grid>
            <Grid><Button className='story-button' onClick={() => { window.open(url, '_blank') }}> {formatMessage({id: 'read_more'})} </Button></Grid>
        </Grid>
    )
}

export default function StoryUpdated({ storyData, imageSide }) {
    return (
        <div className='story-card-container'>
            <div className={`story-image-bg-gardient ${imageSide === "left" ? 'story-image-bg-gardient-left' : 'story-image-bg-gardient-right'} `}></div>
            <div className={`${imageSide === "left" ? 'story-card-left': 'story-card-right'}`}>
                <div className='story-card'>
                    <Grid container className={`story-image-main ${imageSide}`} rowGap={10}>
                        {
                            imageSide === "left" ?
                                (<>
                                    <StoryImage storyData={storyData} />
                                    <StoryDisplayData storyData={storyData} imageSide={imageSide} />
                                </>)
                                :
                                (<>
                                    <StoryDisplayData storyData={storyData} imageSide={imageSide} />
                                    <StoryImage storyData={storyData} />
                                </>)
                        }
                    </Grid>
                </div>
            </div>
        </div>
    );
}