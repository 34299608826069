import { connect } from 'react-redux';
import { LegalNoticeDetailsEN } from '../common/contstants';
import { LegalNoticeDetailsDE } from '../common/contstants';
import LegalNotice from '../components/TermsAndConditions/LegalNotice';
const mapStateToProps = state => ({
    infoEN: LegalNoticeDetailsEN,
    infoDE: LegalNoticeDetailsDE,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(LegalNotice);