import React from 'react';
import { Grid } from '@mui/material';
import Image1 from '../../assets/Blog/Image1.svg';
import Image2 from '../../assets/Blog/Image2.svg';
import Image3 from '../../assets/Blog/Image3.svg';
import StoryUpdated from './StoryUpdated';


const StoryListData = [
    {
        "heading": "Just look at these fantastic SKIY31 people!",
        "content": "The SKIY31 team gathered to have fun before the New Year.",
        "date": "12/01/2023",
        "image": Image1
    },
    {
        "heading": "How to successfully spend a crisis year (like 2022 ; )",
        "content": "Reflect on the past,  Fight today,  Envision the future.",
        "date": "08/01/2023",
        "image": Image2
    },
    {
        "heading": "The story that will make you smile on this good Monday day 🙌",
        "content": "I made a mistake yesterday at midnight. I turned my notebook on and wanted to send ...",
        "date": "21/12/2022",
        "image": Image3
    },
]

export default function StoryList({storyListData}) {
    return (
        <Grid id="story-list" container>
            {
                storyListData.map((storyData, index) => {
                    return (<StoryUpdated key={index} storyData={storyData} imageSide={index % 2 === 0 ? 'right' : 'left'} />)
                })
            }
        </Grid>
    )
}