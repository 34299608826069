import { Box, Grid } from '@mui/material';
import * as React from 'react';
import '../../css/clients.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../css/employee-says.scss'
import { useIntl } from 'react-intl';
import quotesImg from '../../assets/Home/quotes.svg'
import kostiantynProfile from '../../assets/employees/kostiantyn2.png'
import rashadProfile from '../../assets/employees/rashad2.png'
import hovhannesProfile from '../../assets/employees/hovhannes2.png'
import andriiProfile from '../../assets/employees/andrii2.png';
import kostiantynProfileMobile from '../../assets/employees-mobile/konstantin.png'
import rashadProfileMobile from '../../assets/employees-mobile/rashad.png'
import hovhannesProfileMobile from '../../assets/employees-mobile/hovhannes.png'
import andriiProfileMobile from '../../assets/employees-mobile/andrii.png';
import { useState } from 'react';
import { screenSize } from '../../common/contstants';
var Carousel = require('react-responsive-carousel').Carousel;


const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 40,
  height: 40,
  cursor: 'pointer',
};

const focusHeight = {
  lg: 230,
  lgs: 200,
  md: 150,
  sm: 130
};

function EmployeesSay(props) {
  const { formatMessage } = useIntl();
  const [activeIdx, setActiveIdx] = useState(0);
  const navToProfile = (ele) => {
    const focusEle = document.getElementById('client-focus-gradient')
    const data = ele.getBoundingClientRect()
    const parentEle = ele.parentElement.parentElement.getBoundingClientRect()
    const diffWidth = (focusEle.clientWidth - data.width) / 2;
    const left = data.left - parentEle.left - diffWidth;
    const top = data.top - parentEle.top - getDefaultPos();
    focusEle.style = `top:${top}px;left:${left}px`

  }
  const getDefaultPos = () => {
    const screenSizeVal = screenSize();
    return (focusHeight[screenSizeVal] / 2) - 20;
  }
  const updateCurrentProfile = (idx, item) => {
    if (item && item.target) {
      navToProfile(item.currentTarget)
    } else if (item === 'arrow' || item === 'initial') {
      const currEle = document.getElementsByClassName(`client${idx}`)[0];
      if(currEle){
        navToProfile(currEle)
      }
      
    }
    setActiveIdx(idx)
  }

  const bannerData = [
    {
      profile: rashadProfile,
      profileMobile: rashadProfileMobile,
      name: 'Rashad Farajullayev',
      position: 'career_employee_say1_pos',
      summary: `career_employee_say1_summary`
    },
    {
      profile: kostiantynProfile,
      profileMobile: kostiantynProfileMobile,
      name: 'Kostiantyn Kolomak',
      position: 'Designer',
      position: 'career_employee_say2_pos',
      summary: `career_employee_say2_summary`
    },
    {
      profile: hovhannesProfile,
      profileMobile: hovhannesProfileMobile,
      name: 'Hovhannes Matevosyan',
     position: 'career_employee_say3_pos',
      summary: `career_employee_say3_summary`
    },
    {
      profile: andriiProfile,
      profileMobile: andriiProfileMobile,
      name: 'Andrii Pelykh',
      position: 'career_employee_say4_pos',
      summary: `career_employee_say4_summary`
    },
  ]
  const [currIdx, setCurrentIdx] = useState(0);
  const [prevIdx, setPrevIdx] = useState(bannerData.length - 1);
  const [nextIdx, setNextIdx] = useState(1)
  const onClickHandler = (e) => {
    let cidx;
    let pidx;
    let nidx;
    if (e.target.id === 'left') {
      cidx = (currIdx + 1) === bannerData.length ? 0 : currIdx + 1;
      pidx = (prevIdx + 1) === bannerData.length ? 0 : prevIdx + 1;
      nidx = (nextIdx + 1) === bannerData.length ? 0 : nextIdx + 1;
    } else {
      cidx = (currIdx - 1) === -1 ? bannerData.length - 1 : currIdx - 1;
      pidx = (prevIdx - 1) === -1 ? bannerData.length - 1 : prevIdx - 1;
      nidx = (nextIdx - 1) === -1 ? bannerData.length - 1 : nextIdx - 1;
    }
    setCurrentIdx(cidx)
    setPrevIdx(pidx)
    setNextIdx(nidx)
  }
  return (
    <div className='career-employees-say'>
      <Grid container columnGap={2}>

        <Grid md={7} lg={7} sm={12} xs={12} className='employee-say-root-container'>
          <Grid container sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" }
          }}>
            <div data-aos="fade-up" className='lbl-employee-say fade-el'>{formatMessage({ id: 'career_employee_say_title' })}</div>
            <div className='employee-say-lcontent'>
              <div>
                <img src={quotesImg} className="client-quotes" alt='quotes' />
                <div data-aos="fade-up" className='lbl-employee-say-desc fade-el'>
                  {formatMessage({ id: bannerData[currIdx].summary })}
                </div>
                <div className='employee-arrow'>
                  <div id='left' onClick={onClickHandler} className={`left-arrow cursor ${currIdx === 0 ? 'disable-action' : ''}`}
                  ></div>
                  <div id='right' onClick={onClickHandler} className={`right-arrow cursor ${prevIdx === 0 ? 'disable-action' : ''}`}
                  ></div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid md={4.5} lg={4} sm={12} xs={12} className="employee-profile-main-view">
          <Grid container sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" }
          }}>
            <Grid >
              <div className="client-container-wrapper">
                <div className="client-top-container">
                  <img className='client-profile-short-img' src={bannerData[prevIdx].profile} alt={bannerData[prevIdx].name} />
                  {/* <div className='client-profile-short-effect'></div> */}
                </div>
                <div className='client-profile-pos align-center'>{formatMessage({ id: bannerData[prevIdx].position })}</div>
              </div>
            </Grid>
            <Grid item md={12}>
              <Box display="flex" flexDirection='column' justifyContent="flex-end">
                <div className="client-container">
                  <div>
                    <img className='client-profile-img' src={bannerData[currIdx].profile} alt={bannerData[currIdx].name} />
                    {/* <div className='client-profile-effect'></div> */}
                  </div>
                </div>
                <div className='align-center'>
                  <div className='client-profile-name'>{bannerData[currIdx].name} </div>
                  <div className='client-profile-cur-pos'>{formatMessage({ id: bannerData[currIdx].position })}</div>
                </div>
              </Box>
            </Grid>
            <Grid>
              <div className="client-container-wrapper">
                <div className="client-bottom-container">
                  <img className='client-profile-short-img' src={bannerData[nextIdx].profile} alt={bannerData[nextIdx].name} />
                  {/* <div className='client-profile-short-effect'></div> */}
                </div>
                <div className='client-profile-pos align-center'>{formatMessage({ id: bannerData[nextIdx].position })}</div>
              </div>
            </Grid>
          </Grid>

          <Grid sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>

            <div data-aos="fade-up" className='lbl-employee-say fade-el'>{ formatMessage({id: 'career_employee_say_title'})}</div>

            <Carousel showArrows={true} interval={3000} showStatus={false} showThumbs={false} showIndicators={false} infiniteLoop={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                <div onClick={onClickHandler} className={`client-banner-left-arrow ${!hasPrev ? 'arrow-disable' : ''}`} style={{ ...arrowStyles, left: 15 }}></div>

              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                <div onClick={onClickHandler} className={`client-banner-right-arrow ${!hasNext ? 'arrow-disable' : ''}`} style={{ ...arrowStyles, right: 12 }}></div>
              }
              selectedItem={activeIdx}
              onChange={(e, item) => updateCurrentProfile(e, 'arrow')}
            >
              {bannerData && bannerData.map((banner, idx) => {
                return <div className='banner-item' key={idx}>
                  <Grid container className='banner-container'>
                    <Grid item lg={3} md={3.5} sm={3.5} className="client-container">
                      <img className='careers-profile-image client-profile-img' src={banner.profileMobile} alt={banner.name} />
                    </Grid>
                    <Grid item lg={7.5} md={6.5} sm={6.25} className="client-desc-container">
                      <div className='client-banner-main'>
                        <Grid container className='banner-desc-container'>
                          <div className='client-quotes-main'>
                            <img src={quotesImg} className="client-quotes" alt='quotes' />
                          </div>
                          <Grid item data-aos="fade-up" className='banner-desc fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: banner.summary }) }}>
                          </Grid>
                          <Grid item className='banner-author-main'>
                            <span data-aos="fade-up" className='banner-author-name fade-el'>{banner.name}</span> <br />
                            <span data-aos="fade-up" className='banner-author-details fade-el'>{formatMessage({ id: banner.position })}</span>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              })}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}
export default EmployeesSay;


