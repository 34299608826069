import React from 'react';
import { Grid } from '@mui/material';
import '../../css/about-focus.scss';
import { useIntl } from 'react-intl';
import "../../assets/animation/css/aos.css";

const FocusData = [
  {
    title: "about_us_ForwardThinking",
    desc: "about_us_ForwardThinking_desc"
  },
  {
    title: "about_us_OpenMinded",
    desc: "about_us_OpenMinded_desc"
  },
  {
    title: "about_us_CustomerCentric",
    desc: "about_us_CustomerCentric_desc"
  },
  {
    title: "about_us_UncompromisedOnQuality",
    desc: "about_us_UncompromisedOnQuality_desc"
  },
  {
    title: "about_us_Sustainable",
    desc: "about_us_Sustainable_desc"
  }
]


function RightText({ title, desc, cssClass }) {
  return (
    <Grid container className={`focus-title-container ${cssClass}`}>
      <Grid item md={9.12} sm={9.12} className='right focus-content-wrapper'
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-duration="200"
      >
        <Grid container className='right'>
          {/* <Grid className='focus-title-first-letter'>{title.charAt(0)}</Grid>
                    <Grid className='focus-title'>{title.slice(1, title.length)}</Grid> */}
          <Grid className='focus-title'>{title}</Grid>
        </Grid>
        <Grid className='focus-desc'>
          {desc}
        </Grid>
      </Grid>
      <Grid item md={2.88} sm={2.88} className='focus-title-large-letter right'
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-duration="200"
      >
        <div>
          {title.charAt(0)}
        </div>
      </Grid>
    </Grid>
  )
}


function LeftText({ title, desc, cssClass }) {
  return (
    <Grid container className={`focus-title-container ${cssClass}`}>
      <Grid item md={2.88} sm={2.88} className='focus-title-large-letter'
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-duration="200"
      >
        {title.charAt(0)}
      </Grid>
      <Grid item md={9.12} sm={9.12} className='left focus-content-wrapper'
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-duration="200"
      >
        <Grid container className='left'>
          {/* <Grid className='focus-title-first-letter'>{title.charAt(0)}</Grid>
                    <Grid className='focus-title'>{title.slice(1, title.length)}</Grid> */}
          <Grid className='focus-title'>{title}</Grid>
        </Grid>
        <Grid className='focus-desc'>
          {desc}
        </Grid>
      </Grid>
    </Grid>
  )
}


export default function Focus() {
  const { formatMessage: f } = useIntl();

  return (
    <div className="about-focus" id="focus">
      <div className='focus-content'>{f({ id: 'about_focus_title' })}</div>
      <RightText cssClass='letter-f' title={f({ id: FocusData[0].title })} desc={f({ id: FocusData[0].desc })} />
      <LeftText cssClass='letter-o' title={f({ id: FocusData[1].title })} desc={f({ id: FocusData[1].desc })} />
      <RightText cssClass='letter-c' title={f({ id: FocusData[2].title })} desc={f({ id: FocusData[2].desc })} />
      <LeftText cssClass='letter-u' title={f({ id: FocusData[3].title })} desc={f({ id: FocusData[3].desc })} />
      <RightText cssClass='letter-s' title={f({ id: FocusData[4].title })} desc={f({ id: FocusData[4].desc })} />
    </div>
  )
}