import * as React from "react";
import "../../css/success.scss";
import "../../assets/animation/css/icon-animations.css";
// import '../../assets/animation/css/styles.css'
import { Box, Grid } from "@mui/material";
// import fintechImg from "../../assets/Home/success-clients/Fintech.png";
// import manufacturingImg from "../../assets/Home/success-clients/Manufacturing.png";
// import healthcareImg from "../../assets/Home/success-clients/Healthcare.png";
// import automotiveImg from "../../assets/Home/success-clients/Automotive.png";
// import apparelImg from "../../assets/Home/success-clients/Apparel.png";
import { useIntl } from "react-intl";

function Success() {
  const {formatMessage: f} = useIntl();
  // const sucesssData = [
  //   {
  //     title: "Fintech",
  //     // img: 'Fintech.png'
  //     img: fintechImg,
  //   },
  //   {
  //     title: "Manufacturing",
  //     img: manufacturingImg,
  //     // img: 'Manufacturing.png'
  //   },
  //   {
  //     title: "Healthcare",
  //     img: healthcareImg,
  //     // img: 'Healthcare.png'
  //   },
  //   {
  //     title: "Automotive",
  //     img: automotiveImg,
  //     // img: 'Automotive.png'
  //   },
  //   {
  //     title: "Apparel",
  //     img: apparelImg,
  //     // img: 'Apparel.png'
  //   },
  // ];
  return (
    <div className="home-success">
      <div data-aos="fade-up" className="about-us-title fade-el" >
      <span className="lb" dangerouslySetInnerHTML={{ __html: f({id: 'success_title1'})}}></span><span className="lb">{f({id: 'success_title2'})}</span>
      </div>
      <div data-aos="fade-up" className="desc fade-el" dangerouslySetInnerHTML={{ __html: f({id: 'success_description'})}}>
        
      </div>
      {/* <Grid container columnGap={4} alignItems={'center'} justifyContent="center"
                direction="row"
                className='main-content'
            >
                {
                    sucesssData.map((solution, idx) => {
                        return <Grid item container className='content' textAlign={"center"}
                            md={2.2}
                            justifyContent="center"
                            direction="column"
                        >
                            <div className='content-inner'>
                                <img src={`${solution.img}`} alt={solution.title} />
                                <div className='title'>{solution.title}</div>
                            </div>

                        </Grid>
                    })
                }
            </Grid> */}
      <Grid container xs={12} sm={12} columnSpacing={{ xs: 0, sm: 0, md: 0, lg: 2 }} className="icon-animation-container" justifyContent={"space-between"}>
        <Grid item xs={2.4} sm={2.4} className="icon-item">
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={150}
          >
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="success-svg laptop"
            >
              <path
                d="M2 84H98V85.9567C98 89.8424 95.0766 93 91.479 93H8.52098C4.92342 93 2 89.8424 2 85.9567V84Z"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.6771 27H11.624C9.06888 27 7 29.0934 7 31.6787V84H93V31.6787C93 29.0934 90.9311 27 88.376 27H80.6662"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M80.8198 34H87V79H13V34H19.3751"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M26.0481 39.1894C25.3643 36.9112 25 34.4987 25 32C25 18.1931 36.1931 7 50 7C63.8069 7 75 18.1931 75 32C75 45.8069 63.8069 57 50 57C41.0691 57 33.2279 52.3157 28.8088 45.2701"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="dollar"
              />
              <path
                d="M64.0688 19.9579C66.5309 23.1217 68 27.1333 68 31.5C68 41.7177 59.9416 50 50 50C40.0584 50 32 41.7177 32 31.5C32 21.2823 40.0584 13 50 13C53.6884 13 57.1216 14.1421 59.9758 16.0999"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="dollar"
              />
              <path
                d="M70 72C71.6569 72 73 70.6569 73 69C73 67.3431 71.6569 66 70 66C68.3431 66 67 67.3431 67 69C67 70.6569 68.3431 72 70 72Z"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M50 72C51.6569 72 53 70.6569 53 69C53 67.3431 51.6569 66 50 66C48.3431 66 47 67.3431 47 69C47 70.6569 48.3431 72 50 72Z"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M30 72C31.6569 72 33 70.6569 33 69C33 67.3431 31.6569 66 30 66C28.3431 66 27 67.3431 27 69C27 70.6569 28.3431 72 30 72Z"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M30 66V57.056L35 52"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M70 66V57.056L65 52"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M50 66V57"
                stroke="#FF7900"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="connector"
              />
              <path
                d="M56 22.8424C54.8426 22.3016 53.5578 22 52.2048 22C47.1209 22 43 26.2531 43 31.5C43 36.7469 47.1209 41 52.2048 41C53.5578 41 54.8426 40.6984 56 40.1576"
                stroke="white"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                class="dollar"
              />
              <path
                d="M40 30H55"
                stroke="white"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                class="dollar"
              />
              <path
                d="M40 34H55"
                stroke="white"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                class="dollar"
              />
            </svg>
            <span className="icon-text">{f({id: "success_fintech_icon_text"})}</span>
          </Box>
        </Grid>
        <Grid item xs={2.4} sm={2.4} className="icon-item">
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={150}
          >
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="success-svg industry"
            >
              <path
                d="M62.2291 21.1767C61.8227 20.2767 56.4441 19.8424 56.4441 19.8424L54.6216 15.5147C54.6216 15.5147 58.1142 11.4651 57.7554 10.5465C57.3934 9.62781 56.5139 8.71853 56.5139 8.71853C56.5139 8.71853 55.59 7.85299 54.6565 7.49678C53.723 7.14056 49.6081 10.5808 49.6081 10.5808L45.2106 8.78727C45.2106 8.78727 44.7693 3.49093 43.8549 3.0941C42.9404 2.69414 41.6641 2.66602 41.6641 2.66602C41.6641 2.66602 40.3877 2.69414 39.4733 3.0941C38.5588 3.49406 38.1175 8.78727 38.1175 8.78727L33.72 10.5808C33.72 10.5808 29.6051 7.14369 28.6716 7.49678C27.7381 7.84987 26.8142 8.71853 26.8142 8.71853C26.8142 8.71853 25.9347 9.62781 25.5727 10.5465C25.2108 11.4651 28.7065 15.5147 28.7065 15.5147L26.884 19.8424C26.884 19.8424 21.5023 20.2767 21.099 21.1767C20.6958 22.0766 20.6641 23.3327 20.6641 23.3327C20.6641 23.3327 20.6926 24.5888 21.099 25.4887C21.5055 26.3886 26.884 26.823 26.884 26.823L28.7065 31.1506C28.7065 31.1506 25.214 35.2002 25.5727 36.1189C25.9347 37.0375 26.8142 37.9468 26.8142 37.9468C26.8142 37.9468 27.7381 38.8124 28.6716 39.1686C29.6051 39.5248 33.72 36.0845 33.72 36.0845L38.1175 37.8781C38.1175 37.8781 38.5588 43.1744 39.4733 43.5713C40.3877 43.9712 41.6641 43.9993 41.6641 43.9993C41.6641 43.9993 42.9404 43.9712 43.8549 43.5713C44.7693 43.1713 45.2106 37.8781 45.2106 37.8781L49.6081 36.0845C49.6081 36.0845 53.723 39.5217 54.6565 39.1686C55.59 38.8124 56.5139 37.9468 56.5139 37.9468C56.5139 37.9468 57.3934 37.0375 57.7554 36.1189C58.1173 35.2002 54.6216 31.1506 54.6216 31.1506L56.4441 26.823C56.4441 26.823 61.8258 26.3886 62.2291 25.4887C62.6323 24.5888 62.6641 23.3327 62.6641 23.3327C62.6641 23.3327 62.6355 22.0766 62.2291 21.1767V21.1767Z"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="gear"
              />
            </svg>
            <span className="icon-text">{f({id: "success_manufacturing_icon_text"})}</span>
          </Box>
        </Grid>
        <Grid item xs={2.4} sm={2.4} className="icon-item">
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={150}
          >
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="success-svg health"
            >
              <path
                d="M36.5819 70.5261H37.2761C38.8569 70.5261 40.138 71.8071 40.138 73.388V83.1381C40.138 84.7189 41.4191 86 42.9999 86H56.3282C57.909 86 59.1901 84.7189 59.1901 83.1381V73.388C59.1901 71.8071 60.4712 70.5261 62.052 70.5261H71.8021C73.383 70.5261 74.6641 69.245 74.6641 67.6641V54.3359C74.6641 52.755 73.383 51.4739 71.8021 51.4739H62.052C60.4712 51.4739 59.1901 50.1929 59.1901 48.612V38.8619C59.1901 37.2811 57.909 36 56.3282 36H42.9999C41.4191 36 40.138 37.2811 40.138 38.8619V48.612C40.138 50.1929 38.8569 51.4739 37.2761 51.4739H27.526C25.9451 51.4739 24.6641 52.755 24.6641 54.3359V67.6641C24.6641 69.245 25.9451 70.5261 27.526 70.5261H30.9685"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="square"
              />
            </svg>
            <span className="icon-text">{f({id: "success_healthcare_icon_text"})}</span>
          </Box>
        </Grid>
        <Grid item xs={2.4} sm={2.4} className="icon-item">
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={150}
          >
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="success-svg automotive"
            >
              <g clipPath="url(#clip0_5444_3279)">
                <path
                  d="M50.0026 54.6673C52.5799 54.6673 54.6693 52.578 54.6693 50.0007C54.6693 47.4233 52.5799 45.334 50.0026 45.334C47.4253 45.334 45.3359 47.4233 45.3359 50.0007C45.3359 52.578 47.4253 54.6673 50.0026 54.6673Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M49.9989 41.4177C50.8655 41.4177 51.5681 40.7151 51.5681 39.8485C51.5681 38.9819 50.8655 38.2793 49.9989 38.2793C49.1322 38.2793 48.4297 38.9819 48.4297 39.8485C48.4297 40.7151 49.1322 41.4177 49.9989 41.4177Z"
                  fill="white"
                />
                <path
                  d="M59.5458 48.3396C60.4124 48.3396 61.115 47.637 61.115 46.7704C61.115 45.9037 60.4124 45.2012 59.5458 45.2012C58.6791 45.2012 57.9766 45.9037 57.9766 46.7704C57.9766 47.637 58.6791 48.3396 59.5458 48.3396Z"
                  fill="white"
                />
                <path
                  d="M40.4364 48.3396C41.303 48.3396 42.0056 47.637 42.0056 46.7704C42.0056 45.9037 41.303 45.2012 40.4364 45.2012C39.5697 45.2012 38.8672 45.9037 38.8672 46.7704C38.8672 47.637 39.5697 48.3396 40.4364 48.3396Z"
                  fill="white"
                />
                <path
                  d="M55.9676 59.4978C56.8343 59.4978 57.5368 58.7952 57.5368 57.9286C57.5368 57.0619 56.8343 56.3594 55.9676 56.3594C55.101 56.3594 54.3984 57.0619 54.3984 57.9286C54.3984 58.7952 55.101 59.4978 55.9676 59.4978Z"
                  fill="white"
                />
                <path
                  d="M44.0301 59.4978C44.8968 59.4978 45.5993 58.7952 45.5993 57.9286C45.5993 57.0619 44.8968 56.3594 44.0301 56.3594C43.1635 56.3594 42.4609 57.0619 42.4609 57.9286C42.4609 58.7952 43.1635 59.4978 44.0301 59.4978Z"
                  fill="white"
                />
                <path
                  d="M32.9675 21.3372C37.9514 18.3684 43.7754 16.666 49.9974 16.666C68.4075 16.666 83.3307 31.5893 83.3307 49.9993C83.3307 68.4094 68.4075 83.3327 49.9974 83.3327C31.5873 83.3327 16.6641 68.4094 16.6641 49.9993C16.6641 40.0379 21.0321 31.0997 27.9584 24.9915"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M49.9978 97.9956C76.5063 97.9956 97.9956 76.5063 97.9956 49.9978C97.9956 23.4894 76.5063 2 49.9978 2C23.4894 2 2 23.4894 2 49.9978C2 76.5063 23.4894 97.9956 49.9978 97.9956Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M50 2V8.14418"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M50 91.8516V97.9989"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M97.9989 49.998H91.8516"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.14418 49.998H2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M64.6833 4.29883L62.8047 10.1525"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M37.1989 89.8477L35.3203 95.6982"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M95.6943 64.6794L89.8438 62.8008"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.1506 37.197L4.29688 35.3184"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M78.043 11.0449L74.4531 16.0335"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.543 83.9629L21.9531 88.9515"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M88.9573 78.043L83.9688 74.4531"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0355 25.543L11.0469 21.9531"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M88.9088 21.8906L83.9297 25.49"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.076 74.5078L11.0938 78.1072"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M78.1072 88.9069L74.5078 83.9277"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.49 16.0741L21.8906 11.0918"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M95.5691 34.9316L89.7344 36.8608"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.2566 63.1348L4.42188 65.0671"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M65.0651 95.5691L63.1328 89.7344"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M36.8588 10.2586L34.9297 4.42383"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g class="automotive-block">
                  <path
                    d="M49.9977 63.129C43.7967 62.9491 37.4126 74.4986 37.4062 74.5081C37.6209 74.6186 37.8356 74.7259 38.0503 74.8301C38.0503 74.8301 41.6876 77.2676 49.9945 77.2676C58.3015 77.2676 61.9388 74.8301 61.9388 74.8301C62.1566 74.7259 62.3713 74.6186 62.5828 74.5081C62.5797 74.5018 56.1956 63.3058 49.9945 63.129H49.9977Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M37.4047 53.9754C35.6777 48.0175 22.7294 45.4758 22.7168 45.4727C22.6789 45.7095 22.641 45.9463 22.6063 46.1862C22.6063 46.1862 21.4002 50.3981 23.9418 58.3041C26.4835 66.21 29.9218 68.9285 29.9218 68.9285C30.0892 69.1022 30.2565 69.2758 30.427 69.4432C30.4302 69.4368 39.1349 59.9333 37.4047 53.9722V53.9754Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M62.6455 53.9736C60.5238 59.8021 69.478 69.4951 69.4843 69.5046C69.6548 69.3372 69.8253 69.1667 69.9926 68.9931C69.9926 68.9931 73.4531 66.3062 76.0674 58.4223C78.6816 50.5384 77.5103 46.3171 77.5103 46.3171C77.4787 46.0771 77.444 45.8403 77.4061 45.6035C77.3998 45.6035 64.7641 48.142 62.6424 53.9736H62.6455Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M57.785 39.4324C62.6694 43.256 74.6609 37.7496 74.6704 37.7464C74.5631 37.5317 74.4557 37.317 74.3421 37.1055C74.3421 37.1055 72.8613 32.982 66.1772 28.0534C59.4931 23.1248 55.117 22.9259 55.117 22.9259C54.8802 22.8817 54.6434 22.8406 54.4066 22.8027C54.4066 22.809 52.9006 35.6089 57.785 39.4324V39.4324Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M42.1788 39.2946C47.3442 35.8626 45.8919 22.747 45.8919 22.7344C45.6551 22.7691 45.4151 22.8038 45.1783 22.8449C45.1783 22.8449 40.7991 22.9522 34.0171 27.7482C27.2352 32.5411 25.6691 36.633 25.6691 36.633C25.5523 36.8445 25.4386 37.0561 25.3281 37.2676C25.3344 37.2676 37.0103 42.7266 42.1788 39.2946Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_5444_3279">
                  <rect width="100" height="100" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="icon-text">{f({id: "success_automotive_icon_text"})}</span>
          </Box>
        </Grid>
        <Grid item xs={2.4} sm={2.4} className="icon-item">
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            height={150}
          >
            <svg
              width="100" 
              height="101"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="success-svg apparel"
              id="apparel"
            >
              <g clipPath="url(#clip0_7975_16086)">
                <path 
                  d="M49.9986 75.6641C49.9986 75.6641 88.5802 75.6258 91.647 75.6641C94.7138 75.7025 97.3151 74.6564 97.7806 69.8919C98.2461 65.1274 92.9668 63.6324 92.9668 63.6324L49.9986 43.123L7.03037 63.6324C7.03037 63.6324 1.75107 65.1329 2.21657 69.8919C2.68207 74.651 5.28338 75.7025 8.35019 75.6641C11.417 75.6258 49.9986 75.6641 49.9986 75.6641Z" 
                  stroke="white" 
                  strokeWidth="3" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  class="hook" 
                />
                <path 
                  d="M49.9992 43.1245V32.8945C49.9992 32.8945 61.7517 32.9712 62.2172 22.3359C62.6827 11.7007 53.526 10.3042 51.9707 10.3042C50.4154 10.3042 40.0156 9.60318 40.0156 21.9471" 
                  stroke="white" 
                  strokeWidth="3" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  class="hook" 
                />
                <path
                  d="M47.8283 22.5953L37.4833 17.4434L27.6641 22.332"
                  stroke="#FF7900"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="hook2"
                />
                <path
                  d="M37.4476 17.4427V12.7721C37.4476 12.7721 43.1707 12.8071 43.3974 7.95155C43.6241 3.09598 39.1651 2.45841 38.4077 2.45841C37.6503 2.45841 32.5859 2.13837 32.5859 7.77403"
                  stroke="#FF7900"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="hook2"
                />
                <path
                  d="M14.8812 25.4043C14.8812 25.4043 4.49086 35.4626 2.79393 41.4408C1.09701 47.419 10.2081 54.5372 14.6935 54.4418"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="cloth"
                />
                <path
                  d="M37.5037 38.8627C52.4118 38.5702 48.7566 22.139 48.7566 22.139L48.959 21.6582L60.0255 25.1205C60.0255 25.1205 58.9181 60.3105 63.0444 88.8659C64.1518 97.7111 37.5037 98.0012 37.5037 98.0012C37.5037 98.0012 10.8583 97.7137 11.9631 88.8659C16.0894 60.3105 14.982 25.1205 14.982 25.1205L26.0485 21.6582L26.2509 22.139C26.2509 22.139 22.5957 38.5702 37.5037 38.8627Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="cloth"
                />
                <path
                  d="M60.125 25.4043C60.125 25.4043 70.5149 35.4626 72.2117 41.4408C73.9112 47.419 64.7979 54.5372 60.3127 54.4418"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="cloth"
                />
              </g>
              <defs>
                <clipPath id="clip0_588_987">
                  <rect width="100" height="143" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="icon-text">{f({id: "success_apparel_icon_text"})}</span>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
export default Success;
