import axiosInstance from '../common/axiosconfig';
import { apiURls } from '../common/contstants';
import ERRORS from '../common/errors';
import _ from 'lodash';


const defaultLimit = 4;


async function fetchHomeActiveWorkList(limit = defaultLimit){
    let url = `${apiURls.getBaseOurWorklUrl()}home-active-list?limit=${limit}`;
    try{
        const response = await axiosInstance.get(url);
        let result = _.get(response, 'data', []);
        return result;
    }
    catch(err){
        throw ERRORS.GetHomeActiveWorkListFailed;
    }
}

async function fetchAllActiveWorkList(){
    let url = `${apiURls.getBaseOurWorklUrl()}active-list`;
    try{
        const response = await axiosInstance.get(url);
        let result = _.get(response, 'data', []);
        return result;
    }
    catch(err){
        throw ERRORS.GetHomeActiveWorkListFailed;
    }
}

async function fetchWorkListByServiceId(serviceId){
    let url = `${apiURls.getBaseOurWorklUrl()}categorywise-list/${serviceId}`;
    try{
        const response = await axiosInstance.get(url);
        let result = _.get(response, 'data', []);
        return result;
    }
    catch(err){
        throw ERRORS.GetHomeActiveWorkListFailed;
    }
}

export { fetchHomeActiveWorkList, fetchAllActiveWorkList, fetchWorkListByServiceId};