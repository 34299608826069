import * as React from 'react';
import '../../css/about-video.scss'
import { useIntl } from 'react-intl';
function AboutVideo() {
    const { formatMessage } = useIntl();
    return (
        <div className='about-video'>
            <div data-aos="fade-up" className='title fade-el'>{ formatMessage({id: 'video_title'}) }</div>
            <div data-aos="fade-up" className='video-wrapper fade-el'>
                <iframe 
                    className='about-youtube-video' 
                    width="560" height="315" 
                    src="https://www.youtube-nocookie.com/embed/FFMF2L88vik?si=cY0rEsy8ia3vBcF9&amp;controls=0&rel=0" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
            </div>
        </div>
    );
}
export default AboutVideo;
