import { connect } from 'react-redux';
import { getClients, getPartners } from '../Actions/clients';
import { getServiceDetails } from '../Actions/services';
import { getWorkListByServiceId } from '../Actions/works';
import Services from '../components/Services';
import _ from 'lodash';

const mapStateToProps = state => ({
    serviceInfo: state.service.serviceInfo,
    partners: state.home.partners,
    clients: state.home.clients,
    service: state.service.servicesList,
    worksList: _.get(state, 'works.worksList.list',[]),
    languages: state.home.languages,
})

const mapDispatchToProps = (dispatch) => ({
    getServiceDetails: (id) => dispatch(getServiceDetails(id)),
    getPartners: (type) => dispatch(getPartners(type)),
    getClients: () => dispatch(getClients()),
    getWorkListByServiceId: (serviceId)=>dispatch(getWorkListByServiceId(serviceId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Services);