import { Grid } from '@mui/material';
import * as React from 'react';
import '../css/founder.scss'
import { useIntl } from 'react-intl';

function FounderLeftBlock({ info }) {
  const { formatMessage } = useIntl();
  const { name, position, description, image } = info;
  return (
    <div className='founder-lblock'>
      <Grid container className='founder-lblock-grid'>
        <Grid item lg={2.7} md={2.7} sm={2.7} xs={3.5} className="image-wrapper">
          <div className="about-client-container">
            <img className='about-client-profile-img' src={image} alt='profile' />
            <div className='about-client-profile-effect'></div>
          </div>
        </Grid>
        <Grid item lg={8.5} md={7.75} sm={7.75} xs className='founder-lblock-grid-ryt'>
          <div className='lblock-right'>
            <div data-aos="fade-up" className='founder-name fade-el'>{name}</div>
            <div data-aos="fade-up" className='founder-position fade-el'>{formatMessage({ id: position })}</div>
            <div data-aos="fade-up" className='founder-desc fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: description }) }} />

          </div>

        </Grid>
      </Grid>
    </div >
  );
}
export default FounderLeftBlock;
