import { ActionTypesList } from "../common/contstants";

const contactReducer = (state = { contactRes: {} }, action) => {
    switch (action.type) {
        case ActionTypesList.CREATE_CONTACT_RESPONSE:
            return { ...state, contactRes: action.data }
        default:
            return state;
    }
}

export default contactReducer;