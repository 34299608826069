import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/workflow.scss'


function Workflow(props) {
    const { itemId, head, text } = props;

    return (
        <Grid  data-aos="fade-up"  container className={`fade-el ${itemId % 2 === 0 ? 'workflow workflow-even' : 'workflow workflow-odd'}`}>
            <Grid md={1} sm={1} className='container-num'>
                <div className='number-align'><div className='inner-number-align'>{itemId + 1}</div></div>
            </Grid>
            <Grid md={9} sm={9} className='workflow-content'>
                <div style={{marginLeft:"3%"}}>
                    <div className='workflow-title'>{head}</div>
                    <div className='workflow-desc' dangerouslySetInnerHTML={{__html: text}}></div>
                </div>
            </Grid>
        </Grid>
    )
}
function Workflows(props, idx) {
    const { info } = props;
    return (
        <div key={idx} className='service-page-workflow'>
            <div data-aos="fade-up" className='service-page-workflow-main-title fade-el'>{info.title}</div>
            {
                info.data && info.data.length &&
                <Grid container rowGap={{lg: 25, md: 20, sm:15}}>
                    {
                        info.data.map((itemInfo, itemId) => {
                            return (
                                <Workflow  key={itemId} {...{...itemInfo, itemId}} />
                            )
                        })
                    }
                </Grid>
            }
        </div >
    );
}
export default Workflows;
