import * as React from 'react';
import '../../css/cs-banner.scss'
import { Grid } from '@mui/material';
import Tags from '../General/Tags';
import NextParticleAnimation from '../NextParticleAnimation';

const PARTICLE_CONFIG = {
    mouseForce: "-3",
    particleSize: 1,
    renderer: "webgl",
    color: "#fff",
    particleGap: 2,
};

function CSBanner(props) {
    const { title, desc, data, logo, id } = props;
    let tags = data.split(', ');
    return (
        <div className='cs-banner'>
            <Grid container columnGap={2}>
                <Grid md={7.8} container alignContent={'center'} className='cs-banner-left' >
                    <div className='cs-banner-title'>
                        {title}
                    </div>
                    <div className='cs-banner-desc'>
                        {desc}
                    </div>
                    <div className='cs-banner-chips'>
                        <Grid container className="our-work-tags" alignContent={"center"}>
                            <Tags
                                tags={tags}
                                stackClass="our-work-tags-stack"
                                chipClass={"our-work-tags-chip"}
                            />
                        </Grid>
                    </div>

                </Grid>
                <Grid md={4} className="cs-image-wrapper">
                    <img src={logo} className='cs-image' alt='cs  image' />
                    {/* <NextParticleAnimation
                imageSrc={logo}
                options={{
                    particleSize: 1,
                    renderer: "webgl",
                    particleGap: 2,
                    disableInteraction: true,
                    addTimestamp: true,
                }}
                className={`our-work-image`}
                id={`nextparticle-image-${id}`}
                loading="lazy"
              /> */}
                </Grid>
            </Grid>
        </div >
    );
}
export default CSBanner;
