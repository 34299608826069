import { ActionTypesList, apiURls } from "../common/contstants";


export function getClients() {
    const url = apiURls.testiMonials();

    return (dispatch) => {
        fetch(url,
            {
                mode: 'cors',

            })
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: ActionTypesList.SUCCESS_CLIENTS_INFO,
                    data: res.data
                })
            })
            .catch((err) => {
                console.log('Service api error', err)
            })
    }

    // return (dispatch) => {
    //     dispatch({
    //         type: ActionTypesList.SUCCESS_CLIENTS_INFO,
    //         data: res
    //     })
    // }
}

export function getPartners(type) {

    const url = type ? apiURls.partners() : apiURls.clients();

    return (dispatch) => {
        dispatch({
            type: ActionTypesList.SUCCESS_FETCH_PARTNERS,
            data: []
        })
        fetch(url,
            {
                mode: 'cors',

            })
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: ActionTypesList.SUCCESS_FETCH_PARTNERS,
                    data: res.data
                })
            })
            .catch((err) => {
                console.log('Service api error', err)
            })
    }

}