import * as React from 'react';
import '../../css/cs-clients.scss'
import { Grid } from '@mui/material';
import quotesImg from '../../assets/Home/quotes.svg'

function Client(props) {
    const { data: { image, review, name, position } } = props;
    return (
        <div id='cs-page' className='cs-client'>
            <Grid container columnGap={0}>
                <Grid md={3} className='client-logo-wrapper' lg={2.8}>
                    <div data-aos="fade-up" className='client-logo-container fade-el'>
                        <img src={image} alt="client icon"  className='client-logo'/>
                    </div>
                </Grid>
                <Grid md sm >
                    <div className='content-container relative'>
                    <div className='client-quotes-main'>
                        <img src={quotesImg} className="client-quotes" alt='quotes' />
                    </div>
                        <div data-aos="fade-up" className='client-content fade-el' dangerouslySetInnerHTML={{ __html: review }}></div> 
                        <div className='author-main'>
                            <span data-aos="fade-up" className='author-name fade-el'>{name}</span> <br />
                            <span data-aos="fade-up" className='author-details fade-el'>{position}</span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
}
export default Client;
