import * as React from 'react';
import '../../css/connect.scss'
import { useIntl } from 'react-intl';
function CareerConnect() {
  const { formatMessage } = useIntl();
  return (
    <div className='about-connect'>
      <div className='about-connect-title-bg'>
        <div data-aos="fade-up" className='about-connect-title about-connect-center fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'career_connect_title' }) }}>

        </div>
      </div>

      <div className='about-connect-main-bg'>
        <div className='about-connect-center'>
          <div data-aos="fade-up" className='about-connect-main fade-el'>
            {formatMessage({ id: 'career_connect_desc' })}
          </div>
        </div>
      </div >
    </div>
  );
}
export default CareerConnect;
