import * as React from 'react';
import { Grid } from '@mui/material';
import '../../css/terms-and-conditions.scss';
import BasicInfo from './BasicInfo';

export default function MainInfo({ key, info }) {
    
    const { heading, data } = info;
    return (
        <Grid container key={key} className='tac-main-info'>
            <Grid item className='tac-main-info-heading'>{heading}</Grid>
            {
                data.map((item,index)=>{
                    return (<BasicInfo key={index} info={item}/>)
                })
            }
        </Grid>
    );
}