import { ActionTypesList } from "../common/contstants";

const careerReducer = (state = { jobs: [], job: {}, applyRes: {message: ''}, careerCreateRes: {} }, action) => {
    switch (action.type) {
        case ActionTypesList.SUCCESS_FETCH_JOB_LIST:
            return { ...state, jobs: action.data }
        case ActionTypesList.SUCCESS_FETCH_JOB_INFO:
            return { ...state, job: action.data }
        case ActionTypesList.JOB_APPLY_RESPONSE:
            return { ...state, applyRes: action.data }
        case ActionTypesList.CREATE_CAREER_RESPONSE:
            return { ...state, careerCreateRes: action.data }
        default:
            return state;
    }
}

export default careerReducer;