import { Box, Button, Checkbox, Grid, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import { Constants, isValidEmail, isValidFullName, isNumberOnly, isValid, isValidLnAndXing, getRoutePath } from '../../common/contstants'
import '../../css/contact-form.scss'
import attachmentImg from '../../assets/multimedia.svg'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
function CareerContactForm(props) {
  const { formatMessage } = useIntl();
  const { careerCreateRes, createCareer } = props;
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [skills, setSkills] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedin, setLinkedIn] = useState('');
  const [xing, setXING] = useState('');
  const [message, setMessage] = useState('');
  const [terms, setTerms] = useState(false)
  const [notify, setNotify] = useState(false)
  const [isSubmitted, setIssubmitted] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [file, setFile] = useState();
  const [captcha, setCaptcha] = useState();
  const [error, setError] = useState('');
  const navigate = useNavigate();


  if (careerCreateRes.message && isSubmitted) {
    setFirstName('');
    setEmail('');
    setSkills('');
    setPhone('');
    setLinkedIn('');
    setXING('');
    setMessage('');
    setTerms(false);
    setNotify(false);
    setIssubmitted(false);
    setbuttonDisable(false);
    setFile();
    setCaptcha();
    setError()
  }
  const handleFileChange = (e) => {
    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf"
    ]
    if (e.target.files) {
      if (allowedFileTypes.includes(e.target.files[0].type)) {
        setFile(e.target.files[0]);
        setError('');
      } else {
        setError(formatMessage({ id: 'attachment_validation' }));
        setFile('')
      }
    }
  };

  const changeRecaptcha = (e) => {
    setCaptcha(e);
  }

  function onSubmit(e) {

    e.preventDefault();
    setIssubmitted(true)
    // const token = captchaRef.current.getValue();
    if (!captcha || !firstName || !email || !skills || !terms) {
      return;
    }
    /// Make api call
    if (error || !isValidEmail(email) || (phone && isNaN(phone))) {
      return;
    }

    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("firstName", firstName);
    if (phone)
      formdata.append("phone", phone);
    if (skills)
      formdata.append("skills", skills);
    if (message)
      formdata.append("message", message);
    if (linkedin)
      formdata.append("linkedin", linkedin);
    if (xing)
      formdata.append("xing", xing);
    if (notify)
      formdata.append("notify", notify);
    if (file)
      formdata.append("attachment", file, file.name);
    setbuttonDisable(true);
    createCareer(formdata)

  }

  return (
    <div>
      <Box display="flex" flexDirection='row' justifyContent="flex-end">
        <div className='career-contact-form'>

          {/* {resMessage && <div className='message-style'>{resMessage}</div>} */}
          {careerCreateRes && careerCreateRes.message && <div className='message-style success-msg'>{formatMessage({ id: careerCreateRes.message })} </div>}
          {careerCreateRes && careerCreateRes.error && <div className='message-style error-msg'>{careerCreateRes.error}</div>}
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Grid container
              columnGap={5}
              rowGap={{ sm: 1 }}
              className='custom-form-row'
            // justifyContent="flex-end" 
            >
              <Grid md={5} sm={12} xs={12}>
                <TextField
                  error={isSubmitted && (!firstName || (firstName && !isValidFullName(firstName)))}
                  helperText={isSubmitted && (!firstName ? formatMessage({ id: 'name_validation' }) : (!isValidFullName(firstName) ? formatMessage({ id: 'name_message' }) : ''))}
                  value={firstName}
                  required id="standard-basic" onChange={(e) => setFirstName(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_1_label' })} variant="standard" />
              </Grid>
              <Grid md={5} sm={12}>
                <TextField
                  error={isSubmitted && (!email || (email && !isValidEmail(email)))}
                  helperText={isSubmitted && (!email ? formatMessage({ id: 'email_validation' }) : (!isValidEmail(email) ? formatMessage({ id: 'email_message' }) : ''))}
                  value={email}
                  type='email' required id="standard-basic" onChange={(e) => setEmail(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_2_label' })} variant="standard" />
              </Grid>
            </Grid>

            <Grid container
              columnGap={5}
              rowGap={{ sm: 1 }}
              // justifyContent="flex-end" 
              className='custom-form-row'
            >
              <Grid md={5} sm={12} xs={12}>
                <TextField required
                  error={isSubmitted && (!skills || !isValid(skills))}
                  helperText={isSubmitted && (!skills ? formatMessage({ id: 'skill_validation' }) : (!isValid(skills) ? formatMessage({ id: 'name_message' }) : ''))}
                  value={skills}
                  id="standard-basic" onChange={(e) => setSkills(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_3_label' })} variant="standard" />
              </Grid>
              <Grid md={5} sm={12}>
                <TextField
                  error={isSubmitted && phone && !isNumberOnly(phone)}
                  helperText={isSubmitted && phone && (!isNumberOnly(phone) ? formatMessage({ id: 'phone_validation' }) : '')}
                  value={phone}
                  id="standard-basic" onChange={(e) => setPhone(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_4_label' })} variant="standard" />
              </Grid>
            </Grid>


            <Grid container
              columnGap={5}
              className='custom-form-row'
            >
              <Grid md={5} sm={12} xs={12}>
                <TextField
                  error={isSubmitted && linkedin && !isValidLnAndXing(linkedin)}
                  helperText={isSubmitted && linkedin && (!isValidLnAndXing(linkedin) ? formatMessage({ id: 'name_message' }) : '')}
                  value={linkedin}
                  id="standard-basic" onChange={(e) => setLinkedIn(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_5_label' })} variant="standard" />
              </Grid>
              <Grid md={5} sm={12} xs={12}>
                <TextField
                  error={isSubmitted && xing && !isValidLnAndXing(xing)}
                  helperText={isSubmitted && xing && (!isValidLnAndXing(xing) ? formatMessage({ id: 'name_message' }) : '')}
                  value={xing}
                  id="standard-basic" onChange={(e) => setXING(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_6_label' })} variant="standard" />
              </Grid>
            </Grid>
            <Grid container
              className='custom-form-row'
            >
              <Grid md={10.6} sm={12} xs={12}>
                <TextField
                  error={isSubmitted && message && !isValid(message)}
                  helperText={isSubmitted && message && (!isValid(message) ? formatMessage({ id: 'name_message' }) : '')}
                  value={message}
                  id="standard-basic" onChange={(e) => setMessage(e.target.value)} className='ipt-field-common' label={formatMessage({ id: 'contact_form_field_7_label' })} variant="standard" />
              </Grid>
            </Grid>


            <div className='custom-form-row custom-form-row-file '>

              <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept=".doc,.pdf" type="file" onChange={handleFileChange} />
                <img className='attachment-icon' src={attachmentImg} alt="attachment" />
              </IconButton>
              <span className='lbl-attachment'>{(file && file.name) ? `${file.name} ` + formatMessage({ id: 'attached' }) : formatMessage({ id: 'contact_form_field_8_label' })} </span>
              {error && <div className='contact-error-cont error-msg'>{error}</div>}

            </div>

            <div>
              <ReCAPTCHA
                sitekey={Constants.siteKey}
                // onChange={onChange}
                // ref={captchaRef}
                className="testmain"
                onChange={changeRecaptcha}
              />
            </div>
            {isSubmitted && !captcha && <div className="error-msg">
              {formatMessage({ id: 'robot' })}
            </div>
            }

            <div className='w-color terms-content'>
              <Checkbox className='chk-terms' checked={terms} required {... { inputProps: { 'aria-label': 'terms' } }} onChange={(e) => setTerms(!terms)} />
              {formatMessage({ id: 'contact_terms_accept' })} <a style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => navigate(getRoutePath('legal-notice'))}><span className='o-color'> {formatMessage({ id: 'terms' })}</span></a> {formatMessage({ id: 'of' })} <a style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => navigate(getRoutePath('privacy-policy'))}><span className='o-color'>{formatMessage({ id: 'privacy' })}</span></a>*
            </div>
            {isSubmitted && !terms && <div className="error-msg">
              {formatMessage({ id: 'terms_contact_validation' })}
            </div>
            }

            <div className='w-color notify-content'>
              <Checkbox className='chk-terms' checked={notify} {... { inputProps: { 'aria-label': 'notify' } }} onChange={(e) => setNotify(!notify)} />
              {formatMessage({ id: 'notify' })}
            </div>
            <div style={{ position: 'relative' }}>
              <Button className='button' type='submit' disabled={buttonDisable}>{formatMessage({ id: 'contact_form_button' })}</Button>
              {/* <div className='button-radiant'></div> */}
            </div>
          </form>
        </div>
      </Box >
    </div >
  );
}
export default CareerContactForm;
