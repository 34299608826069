import * as React from 'react';
import { useIntl } from 'react-intl';
export default function ClickToView({ mousePosition, rightRotate }) {
  const { formatMessage } = useIntl();
  let topTextCssClass = "";
  let bottomTextCssClass = "";
  if (rightRotate) {
    topTextCssClass = "right-rotate top-right-rotate-position";
    bottomTextCssClass = "right-rotate bottom-right-rotate-position";
  }
  else {
    topTextCssClass = "left-rotate top-left-rotate-position";
    bottomTextCssClass = "left-rotate bottom-left-rotate-position";
  }

  return (
    <div className='click-to-view'
      style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
    >
      <div className="circle1 one"></div>
      <div className="circle2 two"></div>
      <div className="circle2 three"></div>
      <div className={`top-text ${topTextCssClass}`}>{formatMessage({ id: "CLICK" })}</div>
      <div className={`bottom-text ${bottomTextCssClass}`}>{formatMessage({ id: "TO VIEW" })}</div>
    </div>
  )
}