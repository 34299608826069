import { connect } from 'react-redux';
import { registerContact } from '../Actions/contact';
import ContactUs from '../components/Contact';
import { ContactCTA } from '../common/contstants';


const mapStateToProps = state => ({
    contactRes: state.contact.contactRes,
    CTA: ContactCTA
})

const mapDispatchToProps = (dispatch) => ({
    registerContact: (payload) => dispatch(registerContact(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);