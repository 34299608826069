import { Component } from "react";
import '../../css/work.scss'
import ContactUsComponent from "./Contact";
import CTAComponentUpdated from "../General/CTAComponentUpdated";
import { HelmetWrapper } from "../HelmetWrapper";

export default class ContactUs extends Component {
    metaData = {
        title: 'contact_page_title', 
        description: 'contact_page_description', 
        isStatic: true
    }
    constructor(props) {
        super(props)
    }
    render() {
        const { CTA } = this.props;
        return (
            <div className="root-contact">
                <HelmetWrapper data={this.metaData} />
                <ContactUsComponent {...this.props} />
                {CTA && <CTAComponentUpdated info={CTA} />}
                {/* <HomeSignUp /> */}
            </div>
        )
    }
}