import React, { useState } from "react";
import { Grid } from '@mui/material'
import '../../css/tt-used.scss';
import dropDownIcon from '../../assets/dropdown-icon.svg';
import { useIntl } from 'react-intl';
const screenWidth = {
    lg: 12,
    md: 12,
    sm: 12
}

const Logo = ({ icon, text, wrapIntoColByItemLength, wrapIntoRowByItemLength, singleRow }) => { //wrapIntoColByItemLength -only for ListOfIconList
    text = text ? text.split(" ").join("<br/>") : "";
    const logoWidth = {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
    }
    if(wrapIntoColByItemLength){
        logoWidth.lg = 5;
        logoWidth.md = 5;
        logoWidth.sm = 5;
    }
    if(wrapIntoRowByItemLength){
        logoWidth.lg = 1;
        logoWidth.md = 1;
        logoWidth.sm = 2;
        logoWidth.xs = 2.5;
    }
    return (
        <Grid item className="service-tt-used-logo-main-container" {...logoWidth} >
            <img src={icon} className={`service-tt-used-logos${singleRow?"-single":""}`} alt="icons-list"/>
            <div className="service-tt-used-logo-texts" dangerouslySetInnerHTML={{ __html: text }}></div>
        </Grid>);

};

const IconsList = ({ data, direction, containerWidth, wrapIntoColByItemLength, wrapIntoRowByItemLength, flexWrap }, idx) => {
    const { item } = data;
    return (
        <Grid 
            key={idx}
            container 
            className="service-tt-used-logo-inner-container" 
            {...containerWidth} 
            flexDirection={direction} 
            flexWrap={flexWrap ? "wrap" : ""} 
            justifyContent={wrapIntoRowByItemLength ? "flex-start": ""}>
            {
                item.map((logo,i)=> {
                    const { icon, text } = logo;
                    return (
                            <Logo 
                                key={i} 
                                icon={icon} 
                                text={text} 
                                wrapIntoColByItemLength={ wrapIntoColByItemLength && item.length > 6} 
                                wrapIntoRowByItemLength={ wrapIntoRowByItemLength }/>
                        );
                })
            }
        </Grid>
    );
}

const ListOfIconsList = ({ data }) => {
    const [isActiveTechnology, setIsActiveTechnology] = useState(data.map((item, index) => (index === 0 ? true : false)));
    const toggleClass = (items, index) => {
        const newItems = items.map((item, i) => {
            if (i === index) return !item;
            return false;
        })
        setIsActiveTechnology(newItems);
    };
    let sWidth = {...screenWidth}
    sWidth.lg = 12 / 7;
    sWidth.md = 12 / 6;
    sWidth.sm = 12 / 5;
    return (
        <Grid container className="service-tt-used-outer-container desktop-view">
            {
                data.map((datum, datIndex) => {
                    const { head } = datum;
                    return (
                        <Grid 
                            item 
                            key={`techGrid-${datIndex}`}
                            onClick={() => toggleClass(isActiveTechnology, datIndex)}
                            className={`service-tt-used-col-container ${isActiveTechnology[datIndex]  ? 'active' : ''}`} 
                            {...sWidth}
                            >
                            <div className="service-tt-used-logo-list-heading">
                                {head}
                                <span className="dropdown-icon">
                                    <img src={dropDownIcon} alt="drop down arrow" />
                                </span> 
                            </div>
                            <IconsList 
                                className="service-tt-used-logo-list-icon" 
                                data={datum} 
                                direction={"row"} 
                                containerWidth={{}} 
                                wrapIntoColByItemLength={true} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

const ListOfIconListMobileView = ({data, i}) => {
    const [isActiveTechnology, setIsActiveTechnology] = useState(data.map((item, index) => (index === 0 ? true : false)));
    const toggleClass = (items, index) => {
        const newItems = items.map((item, i) => {
            if (i === index) return !item;
            return false;
        })
        setIsActiveTechnology(newItems);
    };
    return (
        <Grid key={i} container className="service-tt-used-outer-container-mob-view">
            { 
                data.map((datum, j) => {
                    const { head } = datum;
                    return (
                        <Grid
                            key={j} 
                            item 
                            onClick={() => toggleClass(isActiveTechnology, j)}
                            className={`service-tt-used-col-container ${isActiveTechnology[j]  ? 'active' : ''}`} >
                            <div className="service-tt-used-logo-list-heading">
                                {head}
                                <span className="dropdown-icon">
                                    <img src={dropDownIcon} alt="drop down arrow" />
                                </span> 
                            </div>
                            <IconsList 
                                className="service-tt-used-logo-list-icon" 
                                data={datum} 
                                direction={"row"} 
                                containerWidth={screenWidth} 
                                flexWrap={true} 
                                wrapIntoRowByItemLength={true}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}


export default function TTused({ info }) {
    const { title, data } = info;
    const { formatMessage } = useIntl();
    return (
        <div className="service-tt-used">
            <div  data-aos="fade-up" className="service-tt-used-title fade-el">{title}</div>
           <div className="service-tt-used-s-row"> {(data.length === 1 && data[0].head === '') ? <IconsList data={data[0]} direction={"row"} containerWidth={screenWidth} flexWrap={true} wrapIntoRowByItemLength={true}/> : ""}
           </div>
            {data.length && data[0].head !== '' && <ListOfIconsList data={data} /> }
            {data.length && data[0].head !== '' && <ListOfIconListMobileView data={data} /> }
            {data.length && data[0].head !== '' && <div className="service-tt-used-cue">{formatMessage({id: "and many others"})}</div>}
        </div>
    );
}