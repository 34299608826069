import { connect } from 'react-redux';
//import { getPartners } from '../Actions/clients';
import { CaseStudyCTAUpdated } from '../common/contstants';
import Work from '../components/Work';
import { getAllActiveWorkList } from '../Actions/works';
import _ from 'lodash';

const mapStateToProps = state => ({
    //partners: state.home.partners,
    worksList: _.get(_.get(state, 'works.worksList', {}),'list.all', []),
    CTA: CaseStudyCTAUpdated
})

const mapDispatchToProps = (dispatch) => ({
    //getPartners: () => dispatch(getPartners()),
    getAllActiveWorkList: ()=>dispatch(getAllActiveWorkList())
})

export default connect(mapStateToProps, mapDispatchToProps)(Work);