import _ from 'lodash';
import { ActionTypesList } from '../common/contstants';
import { fetchHomeActiveWorkList, fetchAllActiveWorkList, fetchWorkListByServiceId } from '../services/work-service';

const homeActiveListRequest = () => {
    return { type: ActionTypesList.HOME_ACTIVE_WORK_LIST_REQUEST }
}

const homeActiveListFailed = (errorMsg) => {
    return {
        type: ActionTypesList.HOME_ACTIVE_WORK_LIST_FAILED,
        errorMsg
    }
}

const requestHomeActiveListSuccess = (result) => {
    return {
        type: ActionTypesList.HOME_ACTIVE_WORK_LIST_SUCCESS,
        data: result.data
    }
}

const getHomeActiveWorkList = (limit) => {
    return async (dispatch) => {
        dispatch(homeActiveListRequest());
        try {
            let result = await fetchHomeActiveWorkList(limit);
            dispatch(requestHomeActiveListSuccess(result))
        }
        catch (err) {
            let errorMsg = _.get(err, 'message', 'error');
            dispatch(homeActiveListFailed(errorMsg));
        }
    }
}


const fetchAllActiveWorkListRequest = () => {
    return { type: ActionTypesList.ALL_ACTIVE_WORKS_LIST_REQUEST }
}

const fetchAllActiveWorkListRequestFailed = (errorMsg) => {
    return {
        type: ActionTypesList.ALL_ACTIVE_WORKS_LIST_FAILED,
        errorMsg
    }
}

const fetchAllActiveWorkListRequestSuccess = (result) => {
    return {
        type: ActionTypesList.ALL_ACTIVE_WORKS_LIST_SUCCESS,
        data: result.data
    }
}

const getAllActiveWorkList = () => {
    return async (dispatch) => {
        dispatch(fetchAllActiveWorkListRequest());
        try {
            let result = await fetchAllActiveWorkList();
            dispatch(fetchAllActiveWorkListRequestSuccess(result))
        }
        catch (err) {
            let errorMsg = _.get(err, 'message', 'error');
            dispatch(fetchAllActiveWorkListRequestFailed(errorMsg));
        }
    }
}


const fetchWorkListByServiceIdRequest = () => {
    return { type: ActionTypesList.WORK_LIST_BY_SERVICEID_FETCH_REQUEST }
}

const fetchWorkListByServiceIdRequestFailed = (errorMsg) => {
    return {
        type: ActionTypesList.WORK_LIST_BY_SERVICEID_LIST_REQUEST_FAILED,
        errorMsg
    }
}

const fetchWorkListByServiceIdRequestSuccess = (result) => {
    return {
        type: ActionTypesList.WORK_LIST_BY_SERVICEID_REQUEST_SUCCESS,
        data: result.data
    }
}

const getWorkListByServiceId = (serviceId) => {
    return async (dispatch) => {
        dispatch(fetchWorkListByServiceIdRequest());
        try {
            let result = await fetchWorkListByServiceId(serviceId);
            dispatch(fetchWorkListByServiceIdRequestSuccess(result))
        }
        catch (err) {
            let errorMsg = _.get(err, 'message', 'error');
            dispatch(fetchWorkListByServiceIdRequestFailed(errorMsg));
        }
    }
}


export { getHomeActiveWorkList, getAllActiveWorkList, getWorkListByServiceId }