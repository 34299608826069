import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import logo from '../../assets/footer-logo.svg'
import linkedInImg from '../../assets/linkedin.png';
import arrowDown from '../../assets/arrow-down.svg';
import heartIcon from '../../assets/heart-icon.png';
import '../../css/footer.scss'
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { getRoutePath, navToService } from '../../common/contstants';
import { useIntl } from 'react-intl';

function Copyright(props) {
  const { formatMessage } = useIntl();
  return (
    <Typography variant="body2" component={'div'} color="text.secondary" align="center" {...props}>
      <img src={logo} alt='skiy log' className='footer-logo' />
      <div className='mail-content'>
        <span className='lbl-mail' style={{ color: '#FF7900' }} >{formatMessage({ id: 'company_info_mail' })}</span>
        <span className='lbl-mail-content' style={{ color: '#FFFFFF', paddingLeft: '30px' }}>{formatMessage({ id: 'site_made_text' })}
          &nbsp;<span className='heart-icon'>
            <img src={heartIcon} alt='love' />
          </span>&nbsp;{formatMessage({ id: 'site_made_text1' })}</span>
      </div>
      <div className='lbl-developed' style={{ color: '#FFFFFF' }}> {formatMessage({ id: 'copyright_text' })}</div>
    </Typography>
  );
}


function FooterComponent(props, idx) {
  const ref = useRef();
  const [isServicesVisibile, setServicesVisibile] = useState(false);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const navToLink = (name, item) => {
    let link = item.link === '' ? null : (item.link || item.name);
    if (link) {
      if (name === 'Services') {
        link = item?.slug_url;
        link = navToService(link);
        navigate(link)
      } else if (name === "Offices") {
        link = getRoutePath(link);
        navigate(link, {
          state: {
            country: item.name
          }
        })
      } else {
        link = getRoutePath(link)
        navigate(link)
      }
    }
  }
  const toggleServicesMenu = () => {
    setServicesVisibile(!isServicesVisibile);
    // if (isServicesVisibile) {
    //     setServicesVisibile(false)
    // } else {
    //     setServicesVisibile(true) // arrow-down
    // }
  }
  const navigateToServices = (url) => {
    let link = footers[1].menus.find(serviceData => serviceData.name.toLowerCase().includes(formatMessage({ id: url }).toLowerCase()))?.slug_url;
    link = navToService(link);
    navigate(link)
    setServicesVisibile(false);
  }
  const openNewTab = (link) => {
    window.open(link);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event['target'])) {
        setServicesVisibile(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const { footers } = props;

  return (
    <React.Fragment key={idx}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      {/* Footer */}
      <Box className="footer-main">
        <Box
          className='footer'
          component="footer"
        >
          <Grid container spacing={0} columnGap={{ xs: 4, sm: 0, md: 0 }} rowGap={{ xs: 4 }} >
            {footers.map((footer) => (
              <>
                <Grid item md lg sm key={footer.name}>
                  {footer.name &&
                    <Typography
                      variant="h6"
                      className={footer.clsTitleName}
                      sx={{ color: '#FF7900', fontSize: footer.type === 'content' ? '40px' : '24px' }} gutterBottom>
                      {formatMessage({ id: footer.key })}
                    </Typography>
                  }



                  <ul>
                    {footer.menus.map((item, i) => (


                      <li className='footer-list-item' key={i}>
                        {(item.name.toLowerCase() === 'services') ? <>
                          <div ref={ref}>

                            <Link onClick={() => toggleServicesMenu()}
                              variant="subtitle1"
                              className={`cursor ${footer.clsName} ${item.name.toLowerCase() === 'services' ? 'hide-for-desktop-footer' : ''}`}
                              sx={{ color: footer.name ? '#FFFFFF' : '#FF7900', fontSize: footer.name ? '20px' : '24px', textDecoration: 'none' }}>
                              {item.key ? formatMessage({ id: item.key }) : item.name}
                              <span className={`services-arrow-wrapper ${isServicesVisibile ? 'is-menu-open' : ''}`}>
                                <img src={arrowDown} alt='arrow' />
                              </span>
                            </Link>

                            <ul className={`footer-services-menu ${isServicesVisibile ? 'show-menu' : ''}`}>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_remote_teams')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_remote_teams' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_web_apps')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_web_apps' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_mobile_apps')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_mobile_apps' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_websites')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_websites' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_ecommerce')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_ecommerce' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_uxui_design')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_uxui_design' })}
                                </span>
                              </li>
                              <li>
                                <span
                                  onClick={() => navigateToServices('services_menu_title_branding')}
                                  className='footer-service-menu-item'>
                                  {formatMessage({ id: 'services_menu_title_branding' })}
                                </span>
                              </li>
                            </ul>

                          </div>
                        </> :
                          <Link onClick={() => navToLink(footer.name, item)}
                            variant="subtitle1"
                            className={`cursor ${footer.clsName} ${item.name.toLowerCase() === 'services' ? 'hide-for-desktop-footer' : ''}`}
                            sx={{ color: footer.name ? '#FFFFFF' : '#FF7900', fontSize: footer.name ? '20px' : '24px', textDecoration: 'none' }}>
                            {item.key ? formatMessage({ id: item.key }) : item.name}
                          </Link>}
                      </li>
                    ))}
                    {footer.data && footer.data.map((item, j) => (
                      <li key={j} className='linkedInList'>
                        <img src={linkedInImg} alt='linked in' />
                        <span className='linkedInDesc' onClick={() => openNewTab(item.link)}>{formatMessage({ id: item.key })}</span>
                      </li>
                    ))}

                  </ul>
                </Grid>
              </>
            ))}
          </Grid>


          <ul className='footer-services-mobile'>
            {
              footers.map((footer) => {
                return footer.menus.map((item, i) => {
                  // footer_office_text
                  // footer_legal_text
                  // footer_what_we_are_text
                  if (item?.key === 'footer_office_dem_text') {
                    return <li key={i}>
                      <Link onClick={() => navToLink(item.link, item)}
                        variant="subtitle1"
                        className='cursor footer-services-mobile-link'>
                        {item.key ? formatMessage({ id: 'footer_office_text' }) : item.name}
                      </Link>
                    </li>
                  } else if (item?.key === 'footer_legal_notice_text') {
                    return <li key={i}>
                      <Link onClick={() => navToLink(footers.name, item)}
                        variant="subtitle1"
                        className='cursor footer-services-mobile-link'>
                        {item.key ? formatMessage({ id: item.key }) : item.name}
                      </Link>
                    </li>
                  } else if (item?.key === 'footer_privacy_policy_text') {
                    return <li key={i}>
                      <Link onClick={() => navToLink(footers.name, item)}
                        variant="subtitle1"
                        className='cursor footer-services-mobile-link'>
                        {item.key ? formatMessage({ id: item.key }) : item.name}
                      </Link>
                    </li>
                  }
                })
              }
              )}
          </ul>

          <Copyright sx={{ mt: 8 }} />
        </Box>
      </Box>
      {/* End footer */}
    </React.Fragment>
  );
}

export default FooterComponent;