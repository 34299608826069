import '../../css/dynamic-html.scss';
import StyleOnTouchEvent from '../../common/styleOnTouchEvent';

function DynamicHtmlBind(props) {

    StyleOnTouchEvent('label.next, label.prev');

    // add class to active label(arrow) of carousel slider
    window["setActiveArrow"] = function(lableId) {
        // check if 'active-arrow' class exists in DOM then remove
        document.querySelectorAll('.sl-container label.prev, .sl-container label.next')
            .forEach(el => el.classList.remove('active-arrow'));

        // add 'active-arrow' class to selected label(arrow) element
        document.getElementById(lableId).classList.add('active-arrow');
    }

    const { data } = props;
    return <div className="dynamic-html fade-el" data-aos="fade-up" dangerouslySetInnerHTML={{ __html: data }}></div>
}

export default DynamicHtmlBind;