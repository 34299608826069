import { Component } from "react";
import HomeBanner from "./Home/Banner";
import HomePartners from "./Home/Partners";
import ServicesList from "./Home/ServicesList";
import OurWorkList from "./Home/OurWorkList";
import Solutions from "./Home/Solutions";
import Vision from "./Home/Vision";
import ContactUS from "./Home/ContactUS";
import AboutUs from "./Home/AboutUs";
import Success from "./Home/Success";
import Clients from "./Home/Clients";
import _ from 'lodash';
import mobileBanner from "../assets/Home/home-bg-image mobile.png";
import mountain from "../assets/Home/mountain.png";
import { Grid } from "@mui/material";
import { isMobileOnly } from "react-device-detect";
import { HelmetWrapper } from "./HelmetWrapper";
import LazyLoad from 'react-lazyload';

export default class Home extends Component {
  metaData = {
    title: 'home_page_title',
    description: 'home_page_description',
    isStatic: true
  }
  constructor(props) {
    super(props)
    this.state = {
      c: 0
    }
  }
  update = () => {
    this.setState((prevState) => ({
      c: ++prevState.c
    }), () => {
      this.props.update(this.state.c)
    })
  }
  componentDidMount() {
    document.body.classList.add('hide-lets-talk');
    this.props.getClients();
    this.props.getPartners();
    this.props.getHomeActiveWorkList();
    this.props.getHomeBlogs();

  }
  componentWillUnmount() {
    document.body.classList.remove('hide-lets-talk');
  }
  render() {
    const { clients, partners, CTA, worksList, homeBlogs, services } = this.props;
    const ourworkList = _.get(worksList, 'list', []);
    return (
      <div className="root-home" >
        <HelmetWrapper data={this.metaData} />
        <div className="e1">
          <div className="e2">
            <div className={`top-bg ${!isMobileOnly && "galaxy-comet"}`}>
              {isMobileOnly && <MobileViewBanner />}
              <LazyLoad>
                <HomeBanner />
                <HomePartners partners={partners} />
              </LazyLoad>

            </div>
          </div>
        </div>
        <LazyLoad>
          <Solutions />
          <ServicesList services={services} />
          <Success />
          <OurWorkList ourworklist={ourworkList} showTitle={true} showButton={true} />
          {clients && partners && <Clients clients={clients} partners={partners} />}
          {homeBlogs && homeBlogs.length && <Vision blogs={homeBlogs} />}
          {CTA && <ContactUS info={CTA} />}
          <AboutUs />
        </LazyLoad>

      </div>
    )
  }
}

function MobileViewBanner() {
  return (
    <Grid position={"absolute"} top={0} container>
      <Grid item position={"relative"} top={0}>
        <img src={mobileBanner} style={{ minWidth: window.screen.width }} alt="Mobile Banner" />
      </Grid>
      <Grid item className="mobile-view-banner-item" position={"absolute"} top={640}>{/* #90 */}
        <img src={mountain} style={{ minWidth: window.screen.width }} alt="mountain" />
      </Grid>
    </Grid>
  )
}