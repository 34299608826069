import _ from 'lodash';
import { ActionTypesList, apiURls } from '../common/contstants';
import { getActiveBlogList } from '../services/blog-service';

const fetchActiveBlogListRequest = () => {
    return { type: ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_REQUEST }
}

const fetchActiveBlogListFailed = (errorMsg) => {
    return {
        type: ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_FAILED,
        errorMsg
    }
}

const fetchActiveBlogListSuccess = (result) => {
    return {
        type: ActionTypesList.BLOG_GET_ACTIVE_HOME_LIST_SUCCESS,
        data: result.data
    }
}

const fetchActiveBlogList = () => {
    return async (dispatch) => {
        dispatch(fetchActiveBlogListRequest());
        try {
            let result = await getActiveBlogList();
            dispatch(fetchActiveBlogListSuccess(result))
        }
        catch (err) {
            let errorMsg = _.get(err, 'message', 'error');
            dispatch(fetchActiveBlogListFailed(errorMsg));
        }
    }
}


const fetchHomeBlogs = () => {

    const url = apiURls.homeBlogs();
    return (dispatch) => {
        fetch(url,
            {
                mode: 'cors',

            })
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: ActionTypesList.FETCH_HOME_BLOGS,
                    data: res.data
                })
            })
            .catch((err) => {
                console.log('Service api error', err)
            })
    }

}

export {fetchActiveBlogList, fetchHomeBlogs};