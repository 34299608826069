import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import { useEffect, useRef } from "react";
import { useIntl } from 'react-intl';
function CustomCookieBanner() {
  const { formatMessage } = useIntl();
  let appendContent;
  let lang = localStorage.getItem('locale');
  let acceptAllButtonText = formatMessage({ id: "accept all" })
  let necessary = formatMessage({ id: "Necessary" });
  let analytics = formatMessage({ id: "Analytics" });
  let advertise = formatMessage({ id: "Advertisement" });
  let useNecessary = formatMessage({ id: "Confirm selection" });
  
  useEffect(()=>{
  let cookie = (!lang || lang == 'en') ?"Cookies we use:": "Cookies, die wir verwenden:"
  let cookieMobile = (!lang || lang == 'en') ?"Cookie Preferences": "Cookie-Einstellungen"
  let cookieTitle =(!lang || lang == 'en') ? "Cookies are here too!  🍪": "Cookies sind auch da! 🍪"
  let cookieDescrition =(!lang || lang == 'en') ?"We use them to improve your experience on our website. They also help us to analyze our traffic. By using this website, you consent to our use of cookies as we describe in our " :"Wir verwenden diese, um Ihre Erfahrung auf unserer Website zu verbessern. Sie helfen uns auch, unseren Datenverkehr zu analysieren. Durch die Nutzung dieser Website erklären Sie sich mit der Verwendung von Cookies einverstanden, wie in unserer "
  let choosen = (!lang || lang == 'en') ?" accept chosen": " Auswahl bestätigen"
  let privacy = (!lang || lang == 'en') ?"Privacy Policy.": "Datenschutzrichtlinie beschrieben."
  let preference = (!lang || lang == 'en') ?"View preferences": "Einstellungen ansehen"
    if(document.getElementsByClassName("custom-cookie-law-dialog").length > 0)
    {
      document.getElementsByClassName("custom-cookie-law-dialog")[0].remove();
      document.getElementsByClassName("react-cookie-law-msg")[0].innerHTML="";
      document.getElementsByClassName("react-cookie-law-description")[0].remove();
    }
      if (!document.getElementsByClassName("custom-cookie-law-dialog").length && document.getElementsByClassName("react-cookie-law-dialog").length) {
        const para = document.createElement("div");
        para.className = 'custom-cookie-law-dialog';
  
        const head = document.createElement("div");
        head.className = 'custom-cookie-law-dialog-head'
        const node = document.createTextNode(cookieTitle);
        head.appendChild(node);
  
        const content = document.createElement("span");
        content.className = 'custom-cookie-law-dialog-content'
        const node1 = document.createTextNode(cookieDescrition);
  
        content.appendChild(node1);
  
  
        const viewAllPref = document.createElement("div");
        viewAllPref.className = 'custom-cookieview-all-preferences show-in-mobile'
        const allPrefButton = document.createElement("button");
        allPrefButton.className = 'custom-cookieview-all-preferences-btn';
        allPrefButton.setAttribute('type', 'button');
        allPrefButton.textContent = preference;
        viewAllPref.appendChild(allPrefButton);
  
  
        const a = document.createElement('span');
        var linkText = document.createTextNode(privacy);
        a.className = 'privacy-policy';
        a.appendChild(linkText);
        a.onclick = () => {
          window.open((!lang || lang == 'en')?'privacy-policy':'/Datenschutzerklärung', '_blank')
        }
        content.appendChild(a);
        content.appendChild(viewAllPref);
  
  
        para.appendChild(head);
        para.appendChild(content);
  
        const cookieLawOptionNodes = document.getElementsByClassName("react-cookie-law-option-wrapper");
        for (var i = 0; i < cookieLawOptionNodes.length; i++) {
          const checkmark = document.createElement("span");
          checkmark.className = 'checkmark';
          cookieLawOptionNodes[i].firstChild.className = 'container';
          cookieLawOptionNodes[i].firstChild.appendChild(checkmark);
        }
  
        const element = document.getElementsByClassName("react-cookie-law-dialog")[0];
        const child = document.getElementsByClassName("react-cookie-law-container")[0];
        const title = document.querySelector(".react-cookie-law-msg");
        title.innerHTML += "<span class='mobile-title'>" + cookieMobile + "</span><span class='desktop-title'>" + cookie + "</span>"
  
        const prefDesc = document.createElement("div");
        prefDesc.className = 'react-cookie-law-description'
        const prefMessage = document.createTextNode((!lang || lang == "en") ? "You can choose those cookies you want to be active.  For more info,  learn our " : "Sie können die Cookies auswählen, die aktiv sein sollen.  Weitere Information finden Sie in unserer ");
  
  
        var link = document.createElement('span');
        link.className = 'privacy-policy';
        var pp = document.createTextNode(privacy);
        link.appendChild(pp);
  
  
        prefDesc.appendChild(prefMessage);
        prefDesc.appendChild(link);
  
        title.parentNode.insertBefore(prefDesc, title.nextSibling);
  
        link.onclick = () => {
          window.open((!lang || lang == 'en')?'privacy-policy':'/Datenschutzerklärung', '_blank')
        }
  
        element.insertBefore(para, child);
  
        allPrefButton.addEventListener("click", function () {
          para.classList.add('hide-in-mobile');
          child.classList.add('show-in-mobile');
          // Change text for accept button
          document.querySelector(".react-cookie-law-accept-all-btn span").innerText = choosen
        }, false);
  
        // cle/arInterval(cookieInteval)
        appendContent = true
      }
  },[lang])
  
  return <div>
    <CookieBanner
      message=""
      showAcceptSelectionButton={true}
      showPreferencesOption={false}
      necessaryOptionText={necessary}
      acceptSelectionButtonText={useNecessary}
      acceptAllButtonText={acceptAllButtonText}
      statisticsOptionText={analytics}
      marketingOptionText={advertise}
      onAcceptPreferences={() => {
        // load your preferences trackers here
      }}
      onAcceptStatistics={() => {
        // load your statistics trackers here
      }}
      onAcceptMarketing={() => {
        // load your marketing trackers here
      }}
    />
  </div>;
}

export default CustomCookieBanner;