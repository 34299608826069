import axiosInstance from '../common/axiosconfig';
import { apiURls } from '../common/contstants';
import ERRORS from '../common/errors';
import _ from 'lodash';




async function getActiveBlogList(){
  const baseOurWorklUrl = apiURls.getBaseBlogUrl();
    let url = `${baseOurWorklUrl}active-list`;
    try{
        const response = await axiosInstance.get(url);
        let result = _.get(response, 'data', []);
        return result;
    }
    catch(err){
        throw ERRORS.GetActiveBlogListFailed;
    }
}

export { getActiveBlogList };