import { ActionTypesList, apiURls } from "../common/contstants";
// import SellingIcon from '../assets/Services/list-items/Selling.svg'
// import RecomendationIcon from '../assets/Services/list-items/Recomendation.svg'
// import AwarenessIcon from '../assets/Services/list-items/Awareness.svg'

// import image1 from '../assets/Services/values/image1.svg'
// import image2 from '../assets/Services/values/image2.svg'
// import contactUsImage from '../assets/Home/ContactUs.svg';


export function getServiceDetails(id) {
    const url = apiURls.serviceDetail(id);

    return (dispatch) => {
        fetch(url,
            {
                mode: 'cors',

            })
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: ActionTypesList.SUCCESS_FETCH_SERVICE_DETAILS,
                    data: res.data
                })
            })
            .catch((err) => {
                console.log('Service api error', err)
            })
    }
}

export function getServicesList() {
    const url = apiURls.servicelist();

    return (dispatch) => {
        fetch(url,
            {
                mode: 'cors',

            })
            .then(response => response.json())
            .then(res => {
                dispatch({
                    type: ActionTypesList.SUCCESS_FETCH_SERVICE_LIST,
                    data: res.data
                })
            })
            .catch((err) => {
                console.log('Service api error', err)
            })
    }
}