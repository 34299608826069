import * as React from 'react';
import '../../css/terms-and-conditions.scss';
import { Button } from '@mui/material';
import '../../css/terms-and-conditions-hcwh.scss';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
export default function HowCanWeHelp() {
  const { formatMessage } = useIntl();
    const navigate = useNavigate();
    return (
        <div className='tac-hcwh'>
            <div className='tac-hcwh-heading'> {formatMessage({ id: "contact_us_help_title" })}</div>
            <div className='tac-hcwh-content'  dangerouslySetInnerHTML={{ __html: formatMessage({ id: "contact_us_help_desc" }) }}></div>
            <div className='tac-hcwh-btn-container'><Button className='tac-hcwh-btn' onClick={() => navigate('/')} >{formatMessage({ id: "contact_us_help_button" })}</Button></div>
        </div>
    );
}