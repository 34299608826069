import { useEffect, useRef } from "react";
import AOS from 'aos';

import CSBanner from "./Banner";
import '../../css/cs.scss'
import CSTech from "./tech";
import CSTestimonial from "./testimonial";
import MajorTasks from "./MajorTasks";
import ServiceHighlights from "./ServiceHighlights";
import DevelopingService from "./DevelopingService";
import SolutionService from "./Solutions";
import { useParams } from "react-router";
import { useState } from 'react';
import PageNotFoundInner from '../PageNotFoundInner';
import DynamicHtmlBind from "./DynamicContent";
import ServiceListItems from "../Services/ServiceListItems";
import ContactUS from "../Home/ContactUS";
import Faq from "../Services/Faq";
import Client from "./Client";
import CSAction from "./Actions";
import CSBillers from "./Billers";
import CompareImage from "./CompareImage";
import { HelmetWrapper } from "../HelmetWrapper";

function CaseStudy(props) {

  const { csInfo, CTA } = props;
  const params = useParams();
  const container = useRef(null);
  const [metaData, setMetaData] = useState({ title: '', description: '', isStatic: false });

  const getCaseStudyDetails = () => {
    props.getCaseStudyDetails(params.id);
  }
  useEffect(() => {
    setMetaData({ title: csInfo?.meta_title, description: csInfo?.meta_description, isStatic: false });
  }, [csInfo]);

  useEffect(() => {
    getCaseStudyDetails();
  }, [params.id]);

  useEffect(() => {
    let elements = csInfo && container?.current ? container?.current?.querySelectorAll('[data-aos="fade-up"]') ?? [] : [];

    const handleLoad = () => {
      AOS.refresh();
    }
    elements.forEach(el => {
      el.addEventListener('load', handleLoad);
    })
    return () => {
      elements.forEach(el => {
        el.removeEventListener('load', handleLoad);
      })
    };
  }, [container, csInfo]);

  return (

    <div id="root-cs" className="root-cs" ref={container}>
      <HelmetWrapper data={metaData} />
      {csInfo?.id && <CSBanner
        title={csInfo.name}
        desc={csInfo.overview}
        data={csInfo.tags}
        logo={csInfo.image}
        id={params.id}
      />}
      {csInfo?.section_1 && <CSTech data={csInfo.section_1} />}
      {csInfo?.section_2 && <CSTestimonial id={csInfo?.name.replace(/ /g, '-').trim().toLowerCase()} data={csInfo.section_2} />}
      {csInfo?.section_3 && <MajorTasks data={csInfo.section_3} />}
      {csInfo?.section_4 && <ServiceHighlights info={csInfo.section_4} />}
      {csInfo?.section_5 && <DynamicHtmlBind data={csInfo.section_5} />}
      {csInfo?.section_6 && <DevelopingService data={csInfo.section_6} />}
      {csInfo?.section_7 && <DynamicHtmlBind id='section-7' data={csInfo.section_7} />}
      {csInfo?.section_8 && <SolutionService data={csInfo.section_8} />}
      {csInfo?.section_12 && <CompareImage data={csInfo.section_12} />}
      {csInfo?.testimonials && csInfo?.testimonials.length > 0 && <Client data={csInfo?.testimonials[0]} />}
      {csInfo?.website_link && csInfo?.website_link.length > 0 && <CSAction data={csInfo?.website_link} />}
      {csInfo?.section_9 && <CSBillers data={csInfo.section_9} />}
      {/* {csInfo?.section_12 && <CompareImage data={csInfo.section_12} />} */}
      {csInfo?.section_10 && <ServiceListItems info={csInfo.section_10} isFullview={true} />}
      {csInfo?.section_11 && <Faq info={csInfo.section_11} />}
      {csInfo?.id && CTA && <ContactUS info={CTA} marginBottom={true} />}
      {csInfo?.error && <PageNotFoundInner />}
    </div>
  )

}
export default CaseStudy;