import * as React from "react";
import ClickToView from "../General/ClickToView";
import { Grid } from "@mui/material";
import Tags from "../General/Tags";
import "../../css/ourwork.scss";
import "../../css/click-to-view.scss";
import { NumberMapping } from "../General";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tilt } from "react-tilt";
import { TiltDefaultOptions, getRoutePath } from "../../common/contstants";
// import NextParticleAnimation from '../NextParticleAnimation';

// const PARTICLE_CONFIG = {
//     maxWidth: 250,
//     mouseForce: "-3",
//     particleSize: 1,
//     renderer: "webgl",
//     color: "#fff",
//     particleGap: 2,
// };

const apiToUiMapping = (data) => {
  let tags = _.get(data, "tags", []);
  if (!_.isArray(tags)) {
    tags = tags.split(",");
  }
  return {
    id: _.get(data, "our_work_id", _.get(data, "id", "")),
    name: _.get(data, "name", _.get(data, "id", "")),
    logo: _.get(data, "client_logo", _.get(data, "logo", "")),
    image: _.get(data, "image", ""),
    content: _.get(data, "overview", _.get(data, "content", "")),
    tags,
    slug_url: _.get(data, 'slug_url', '')
  };
};

export default function OurWork({ data, classname }) {
  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const ourWorkRef = React.useRef(null);
  useEffect(() => {
    const mouseMoveHandler = (event) => {
      var offset = event.target.closest('.our-work').getBoundingClientRect();
      const { clientX, clientY } = event;
      setMousePosition({ x: clientX - offset.left, y: clientY - offset.top });
    };
    if (ourWorkRef) {
      ourWorkRef.current.addEventListener("mousemove", mouseMoveHandler);
    }
  }, []);

  const { id, name, logo, tags, image, rightRotate, content, slug_url } = apiToUiMapping(data);
  return (
    <div
      data-aos="fade-up"
      key={id}
      className={`our-work-root our-work-card-template-${classname}-root-container fade-el`}
      onClick={() => {
        navigate(`${getRoutePath('case-study')}/${slug_url}`);
      }}
    >
      <Tilt options={TiltDefaultOptions}>
        <div
          ref={ourWorkRef}
          className={`our-work our-work-card-template-${classname}-dimension`}
        >
          <ClickToView
            mousePosition={mousePosition}
            rightRotate={rightRotate}
          />
          <Grid
            container
            className={`our-work-main-container our-work-main-container-right`}
          >
            {logo ? (
              <Grid className="our-work-logo-container">
                <img
                  src={logo}
                  className="our-work-logo"
                  style={{ boxSizing: "border-box", mixBlendMode: "lighten" }}
                  alt={`${name} logo`}
                />
              </Grid>
            ) : (
              ""
            )}
            <Grid className={logo ? `our-work-image-container` : `our-work-nologo-image-container`}>
              {<img src={image} className={`our-work-image`} style={{ mixBlendMode: (classname === NumberMapping[0] || classname === NumberMapping[3]) ? 'lighten' : 'lighten' }} alt={name} />}
              {
              //   <NextParticleAnimation
              //   imageSrc={image}
              //   options={{
              //     maxWidth: 250,
              //     padding: 0,
              //     particleSize: 2,
              //     renderer: "webgl",
              //     particleGap: 3,
              //     disableInteraction: true,
              //     addTimestamp: true,
              //   }}
              //   className={`our-work-image`}
              //   id={`nextparticle-image-${id}`}
              //   style={{
              //     mixBlendMode:
              //       classname === NumberMapping[0] ||
              //       classname === NumberMapping[3]
              //         ? "lighten"
              //         : "lighten",
              //   }}
              //   loading="lazy"
              // />
              }
            </Grid>
            <Grid container className="our-work-tags" alignContent={"center"}>
              <Tags
                tags={tags}
                stackClass="our-work-tags-stack"
                chipClass={"our-work-tags-chip"}
              />
            </Grid>
          </Grid>
        </div>
        <div
          className={`our-work-content our-work-card-template-${classname}-description`}
          dangerouslySetInnerHTML={{ __html: content }}
        >
        </div>
      </Tilt>
    </div>
  );
}
