import * as React from 'react';
import '../../css/popup-game.scss';
import PopupBg from '../../assets/Services/popup/bakground.svg';
import VideoCard from '../../assets/Services/popup/video.svg';
import PopupService from '../../assets/Services/services-widget.png';
import CloseNews from '../../assets/Services/close-news.svg';
import StarsGroup from '../../assets/Services/stars-group.svg';
import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
const NewsLetterPopup = ({ closeNewsletter, setCloseNewsletter, data }) => {
    const { head, input_text: inputText, text } = data;
    
    return (
        <div className={closeNewsletter ? 'popup-newsletter-closed' : 'popup-newsletter'}>
            <Grid container className='popup-newsletter-main-container' justifyContent={"center"}>
                <Grid item className='popup-newsletter-main-heading'>{head}</Grid>
                <Grid container className='popup-newsletter-text-container'>
                    <Grid item lg={9} md={9} sm={9} xs={9}><input type='text' placeholder={text} value={inputText} className='popup-newsletter-textfield' /></Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}><Button className='popup-newsletter-button' onClick={() => { setCloseNewsletter(true) }}>GO</Button></Grid>
                </Grid>
            </Grid>
        </div>
    );
}


const NotificationPopup = ({ closeNotification, setCloseNotification, data }) => {
    const { head, b1_text, b2_text } = data;
    return (
        <div className={closeNotification ? 'popup-notification-closed' : 'popup-notification'}>
            <Grid container className='popup-notification-main-container' justifyContent={"center"}>
                <Grid item className='popup-notification-main-heading'>{head}</Grid>
                <Grid container className='popup-notification-text-container'>
                    <Grid item lg={6} md={6} sm={6} xs={6}><Button className='popup-notification-button' onClick={() => { setCloseNotification(true) }}>{b1_text}</Button></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}><Button className='popup-notification-button' onClick={() => { setCloseNotification(true) }}>{b2_text}</Button></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const VideoCardPopup = ({ closeVideoCard, setCloseVideoCard }) => {
    return (
        <div className={`${closeVideoCard ? 'popup-videocard-closed' : 'popup-videocard'}`}>
            <PauseIcon className='popup-videocard-pause-icon' />
            <CloseIcon className='popup-videocard-close-icon' onClick={() => { setCloseVideoCard(true) }} />
            <img src={VideoCard} alt='popup-bg' className='popup-videocard-bg' style={{ boxSizing: "border-box", width: "100%" }} />
        </div>
    );
}

const PopupFinalContent = ({ data }) => {
    const { head, text } = data;
    return (
        <div className='popup-content'>
            <div className='popup-main-content' dangerouslySetInnerHTML={{ __html: head }} />
            <div className='popup-sub-content' dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
}

export default function PopupGame({ info }) {

    const [closeNewsletter, setCloseNewsletter] = React.useState(false);
    const [closeNotification, setCloseNotification] = React.useState(false);
    const [closeVideoCard, setCloseVideoCard] = React.useState(false);
    const { title, content, video_link: videoLink, notification, subscribe } = info;
    const { formatMessage } = useIntl();
    return (
        <div id="popup">
            <div className='popup-main-heading'>{title}</div>
            <div className='popup-main-container'>
                {(closeNewsletter && closeNotification && closeVideoCard) ? <PopupFinalContent data={content} /> : ""}
                <Grid sx={{
                        display: { xs: "none", sm: "none", md: "block", lg: "block" }
                    }}>
                    <VideoCardPopup closeVideoCard={closeVideoCard} setCloseVideoCard={setCloseVideoCard} data={videoLink} />
                    <NotificationPopup closeNotification={closeNotification} setCloseNotification={setCloseNotification} data={notification} />
                    <NewsLetterPopup closeNewsletter={closeNewsletter} setCloseNewsletter={setCloseNewsletter} data={subscribe} />
                
                    <img src={PopupBg} alt='popup-bg' style={{ boxSizing: "border-box", width: "100%" }} className='popup-bg popup-game-image' />
                </Grid>
                <Grid sx={{
                        display: { xs: "block", sm: "block", md: "none", lg: "none" }
                    }}>
                    <PopupServiceWrapper data={{text: 'Hello'}} />
                </Grid>
                 
            </div>
            <div className='popup-main-heading'>{formatMessage({id:'gaming_user'})}</div>
        </div>
    )
}

const PopupServiceWrapper = ({ data }) => {
    const [viewInApp, setViewInApp] = React.useState(false);
    const [hideDonate, setDonateStatus] = React.useState(false);
    
    const { text } = data;
    return (
        <div className='popup-service-content'>
            <div className={`popup-service-header ${viewInApp ? 'hide-header' : ''}`} >
                <div className='popup-service-header-news-box'>
                    <span className='popup-service-header-news-close'>
                        <img src={CloseNews} alt='Close' />
                    </span>
                    <span className='popup-service-header-news-rect'>LN</span>
                    <div className='popup-service-header-news-details'>
                        <span className='popup-service-header-news-text'>Get the latest news and exclusive materials!</span>
                        <span className='popup-service-star'>
                            <img src={StarsGroup} alt='stars' />
                            <span className='start-number'>4.8/5</span>
                        </span>
                    </div>
                </div>
                
                <button className='popup-service-view-app' onClick={() => { setViewInApp(true) }}>
                    View in app
                </button>
            </div>
            <div className='game-image-wrapper'>
                <img src={PopupService} alt='popup service' className='popup-game-image' />
                <div className='game-image-details'>
                    <p className='game-image-details-desc'>On September 18th, English footballer scored the first goal for his new team.</p>
                    <p className='game-image-details-desc'>The resultative kick after Alphonso Davies’ pass from the left made the beginning for the 4-0 defeat against Werder Bremen.</p>
                    <p className='game-image-details-desc'>Kane scored in his first Bundesliga match.</p>
                </div>
            </div>
            

            <div className={`donate-banner ${hideDonate ? 'hide-donate-banner' : ''}`}>
                <span className='donate-banner-close'>
                        <img src={CloseNews} alt='Close' />
                    </span>
                <div className='donate-banner-desc'>
                    We would be happy if you considered  donation  to support the work that we do for you.
                </div>
                <div className='donate-banner-desc'>
                    You can choose any amount you want:
                </div>

                <button className='donate-banner-btn' onClick={() => { setDonateStatus(true) }}>Donate</button>
            </div>
        </div>
    );
}