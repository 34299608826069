import * as React from 'react';
import { Grid, Button } from '@mui/material';
import '../../css/services.scss';
import { useNavigate } from 'react-router';
import { navToService } from '../../common/contstants';
import NextParticleAnimation from '../NextParticleAnimation';
import curvedArrow from '../../assets/Home/curved-arrow.svg'
import { useIntl } from 'react-intl';

const ServiceButtons = (props) => {
  const { formatMessage } = useIntl();
  const { services } = props;
  //  direction={{ xs: 'column', sm: 'row' }} 
  const navigate = useNavigate();
  const navToLink = (button) => {
    let link = services.find(serviceData => serviceData.name.toLowerCase().includes(formatMessage({ id: button.linkText }).toLowerCase()))?.slug_url;
    link = navToService(link);
    navigate(link)
  }
  return (
    <Grid spacing={1} sx={{
      display: { xs: "inline-flex", sm: "inline-flex", md: "inline-flex", lg: "inline-flex" }
    }} className='stack'>
      {
        props.buttons.map((button, idx) => <Button
          key={idx}
          id={button.linkText.replace(/\s+|[\/]/g, '-').trim().toLowerCase()}
          onClick={() => navToLink(button,)}
          className='button'>{(formatMessage({ id: button.text }) || 'Click Here').toUpperCase()}</Button>)
      }
      {
        props.buttons.length === 1 ? (<div className='image-container curved-arrow-wrapper'><img src={curvedArrow} className='curved-arrow' alt="curved-arrow" /></div>) : ""
      }
    </Grid>
  );
};

export default function Services(props) {
  const { formatMessage } = useIntl();
  const { heading, description, buttons, image, id, customeParticle } = props.service;

  return (
    <Grid container className='service' alignItems={"center"}>
      <Grid xs={12} sm={12} md={3.6} item className='service-image'>
        <NextParticleAnimation customOptions={true} options={customeParticle} imageSrc={image} id={id} />
      </Grid>
      <Grid xs={12} sm={12} md={8.4} item>
        <div data-aos="fade-up" className='service-heading fade-el'>{formatMessage({ id: heading })}</div>
        <div data-aos="fade-up" className='description fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: description }) }} />
        {buttons.length > 0 ? <div id={`buttons-${id}`} className='buttons'>
          <ServiceButtons heading={heading} buttons={buttons} id={`service-buttons-${id}`} services={props.services} />
        </div> : ""}
      </Grid>
    </Grid>
  )
}