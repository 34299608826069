import { Button, Grid, Menu, Link, Stack } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import '../../css/jobs.scss'
import '../../css/all-works.scss';
import JobCard from './JobCard';
import downArrow from '../../assets/down-arrow.svg'
import { WorkingHours, Locations } from '../General';
import { ActiveTypesMapping } from '../../common/contstants';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
const ActiveTypes = {
  ALL: 'All',
  workingHours: "Work Hours",
  'remote': 'remote',
  'underemployment': 'underemployment',
  'fullEmployment': 'fullEmployment'
}
const defaultRecord = 4;

function Jobs(props) {
  const { formatMessage } = useIntl();
  const { jobs } = props;
  const [activeType, setActiveType] = useState(WorkingHours.workingHours);
  const [selectedTag, setTag] = useState(WorkingHours.workingHours);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showMenu, setMenuStatus] = useState(false);
  const [showLocationsMenu, setLocationsMenuStatus] = useState(false);
  const [filterJobs, setFilterJobs] = useState(jobs);
  const [positionVal, setPositionVal] = useState(1)
  let remoteCount = jobs.filter(job => job.employmentType === ActiveTypes.remote).length
  let fullTimeCount = jobs.filter(job => job.employmentType === ActiveTypes.fullEmployment).length
  let partTimeCount = jobs.filter(job => job.employmentType === ActiveTypes.underemployment).length;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleMenu = () => {
    setMenuStatus(!showMenu)
  };
  const toggleLocationsMenu = () => {
    setLocationsMenuStatus(!showLocationsMenu)
  };
  const handleClose = (e) => {
    if (open) {
      setAnchorEl(null);
      if (!e.target.id) {
        return;
      }
      let fJobs;
      if (activeType === ActiveTypes.ALL) {
        if (e.target.id === 'all') {
          fJobs = jobs;
        } else {
          fJobs = jobs.filter(job => job.region && job.region.country === e.target.id)
        }
      }
      else {
        if (e.target.id === 'all') {
          fJobs = jobs.filter(job => job.employmentType === activeType);
        } else {
          fJobs = jobs.filter(job => job.employmentType === activeType && job.region && job.region.country === e.target.id)
        }
      }
      setFilterJobs(fJobs)
    }
  };

  const handleFilterChanged = (type, location) => {
    setFilterJobs(jobs.filter((job) => {
      if ((type.toLowerCase() !== "all" && type.toLowerCase() !== "work hours") || (location !== "worldwide" && location !== "")) {
        if (type.toLowerCase() === "all" || type.toLowerCase() === "work hours") {
          return job.region?.country.toLowerCase() === location.toLowerCase();
        }
        if (location === "worldwide" || location === "") {
          return job.employmentType === type;
        }
        return job.employmentType === type && job.region?.country.toLowerCase() === location.toLowerCase();
      }
      return job;
    }))
  };

  const handlePosition = () => {
    const posIdx = positionVal + 1;
    setPositionVal(posIdx)
  }
  const locations = jobs.filter((job,
    index) => {
    if (job.region) {
      return jobs.findIndex(j => j.region && j.region.country === job.region.country) === index
    }
    return;
  })
  window.onscroll = () => {
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleActiveTypeEvent = (e) => {
    const aType = e.target.id;
    setActiveType(aType);
    setTag(aType);
    let fJobs = aType === ActiveTypes.ALL ? jobs : jobs.filter(job => job.employmentType === aType)
    setFilterJobs(fJobs)
  }

  const handleLocationMobileSelect = (key) => {
    setSelectedLocation(key);
  }

  useEffect(() => {
    handleFilterChanged(activeType, selectedLocation);
  }, [selectedLocation, activeType]);

  const finalJobsData = filterJobs && filterJobs.length ? filterJobs.slice(0, (defaultRecord * positionVal)) : [];


  return (
    <div id='career-jobs' className='career-jobs'>
      <div data-aos="fade-up" className='lbl-career-jobs fade-el'>{formatMessage({ id: 'career_job_list_title' })}</div>
      <div className='career-jobs-categories'>
        <div className='category-main category-all desktop-menu'>
          <span
            id={`${ActiveTypes.ALL}`}
            className={`lbl-category-filter cursor  ${activeType === ActiveTypes.ALL ? 'lbl-category-active' : ''}`}
            onClick={handleActiveTypeEvent}>
            {formatMessage({ id: 'All' })}({jobs.length})
          </span>
          <span
            id={`${ActiveTypes.remote}`}
            className={`lbl-category-filter cursor ${activeType === ActiveTypes.remote ? 'lbl-category-active' : ''}`}
            onClick={handleActiveTypeEvent}>
            {formatMessage({ id: ActiveTypesMapping[ActiveTypes.remote] })} ({remoteCount})
          </span>
          <span
            id={`${ActiveTypes.fullEmployment}`}
            className={`lbl-category-filter cursor ${activeType === ActiveTypes.fullEmployment ? 'lbl-category-active' : ''}`}
            onClick={handleActiveTypeEvent}>
            {formatMessage({ id: ActiveTypesMapping[ActiveTypes.fullEmployment] })} ({fullTimeCount})
          </span>
          <span
            id={`${ActiveTypes.underemployment}`}
            className={`lbl-category-filter cursor ${activeType === ActiveTypes.underemployment ? 'lbl-category-active' : ''}`}
            onClick={handleActiveTypeEvent}>
            {formatMessage({ id: ActiveTypesMapping[ActiveTypes.underemployment] })} ({partTimeCount})
          </span>
          <div style={{ display: 'inline-block' }}>
            <span id="location-menu"
              onClick={handleClick}
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              className={`lbl-category-filter cursor`}>{formatMessage({ id: 'career_job_type5' })} <img src={downArrow} alt="down arrow" /></span>

            <Menu
              id="location-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {
                locations.map((job, idx) => {
                  return (
                    <span key={idx}>
                      <Link id={job.region.country} className='location-menu-item cursor' onClick={handleClose} >{formatMessage({ id: job.region.country.toLowerCase() })}</Link>
                      <span style={{ color: '#FFFFFF', fontWeight: 600 }}>|</span>
                    </span>
                  )
                })
              }
              <spav>
                <Link id={'all'} className='location-menu-item cursor' onClick={handleClose} >{formatMessage({ id: 'worldwide' })}</Link>
              </spav>
            </Menu>
          </div>
        </div>
        <div className='category-main mobile-menu'>
          <Stack className='all-jobs-tags-stack mobile-menu' columnGap={4} rowGap={{ sm: 3 }} alignContent='center' flexWrap={'wrap'}>
            <div id="custom-job-select" className={`custom-select ${showMenu ? 'open' : ''}`}>
              <summary className="radios" onClick={toggleMenu}>
                {
                  Object.keys(WorkingHours).map((key, index) => {
                    return (
                      index === 1 && selectedTag === 'Work Hours'
                        ? (<input
                          key={index}
                          type="radio"
                          name="item"
                          id={`item-${index}`}
                          title={formatMessage({ id: `${WorkingHours[key]}` })}
                          value={formatMessage({ id: `${WorkingHours[key]}` })}
                          onChange={e => { }}
                          checked
                        />)
                        : <input
                          key={index}
                          type="radio"
                          name="item"
                          id={`item-${index}`}
                          title={formatMessage({ id: `${WorkingHours[key]}` })}
                          value={formatMessage({ id: `${WorkingHours[key]}` })}
                        />
                    )
                  })
                }
              </summary>
              <ul className={`work-list ${showMenu ? 'open' : ''}`}>
                {
                  Object.keys(WorkingHours).map((key, index) => {
                    return (
                      key !== 'workingHours' && key !== 'all' ? <>
                        <li key={index}>
                          <label htmlFor={`item-${index}`} id={`${WorkingHours[key]}`} onClick={handleActiveTypeEvent}>
                            {formatMessage({ id: WorkingHours[key] })} ({jobs.filter(job => job.employmentType === ActiveTypes[key]).length})
                          </label>
                        </li>
                      </> : key === 'all' ? <>
                        <li key={index}>
                          <label htmlFor={`item-${index}`} id={`${ActiveTypes.ALL}`} onClick={handleActiveTypeEvent}>
                            {formatMessage({ id: WorkingHours[key] })} ({jobs.length})
                          </label>
                        </li>
                      </> : <></>
                    )
                  })
                }
              </ul>
            </div>
            <div id="custom-location-select" className={`custom-select ${showLocationsMenu ? 'open' : ''}`}>
              <summary className="radios" onClick={toggleLocationsMenu}>
                {
                  selectedLocation === "" && (
                    <span className='locations-default'>{formatMessage({ id: 'career_job_type5' })}</span>
                  )
                }
                {
                  selectedLocation !== "" && (
                    Object.keys(Locations).map((key, index) => {
                      return (
                        <input
                          key={index}
                          type="radio"
                          name="location-item"
                          id={`location-${index}`}
                          title={Locations[key]}
                          value={formatMessage({ id: `${Locations[key]}` })}
                          onChange={e => { }}
                          checked={key === selectedLocation}
                        />
                      )
                    })
                  )
                }
              </summary>
              <ul className={`work-list ${showLocationsMenu ? 'open' : ''}`}>
                {
                  Object.keys(Locations).map((key, index) => (
                    <li key={`mobile-location-${index}`}>
                      <label htmlFor={`location-${index}`} id={`mobile-${Locations[key]}`} onClick={() => handleLocationMobileSelect(key)}>
                        {formatMessage({ id: Locations[key] })}
                      </label>
                    </li>
                  ))
                }
              </ul>
            </div>
          </Stack>
        </div>
      </div>


      <div className='career-jobs-content'>

        <Grid container columnGap={8} rowGap={10}>
          {
            finalJobsData.length ? finalJobsData.map(jobInfo => {
              return (
                <Grid data-aos="fade-up" md={5.5} sm={5} className='job-card-wrapper fade-el'>
                  <JobCard jobInfo={jobInfo} />
                </Grid>
              )
            }) : <div className='no-data-found'>{ formatMessage({id: "No Jobs Found"}) }</div>
          }

        </Grid>
        {
          (finalJobsData.slice(0, (defaultRecord * positionVal)).length < filterJobs.length) && <div className='view-job-position' >
            <Button className='btn-view-job-position' sx={{ borderColor: '#FF7900', color: '#FFFFFF' }} variant="outlined" onClick={handlePosition}>MORE POSITIONS</Button></div>
        }
      </div>
    </div >
  );
}
export default Jobs;
