import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/values.scss'


function Value(props) {
    const { idx, image, head, text } = props;
    return (
        <Grid container
            rowGap={0}
            data-aos="fade-up"
            className='value-item-wrapper fade-el'
        >
            {idx % 2 === 0 ?
                (<Grid md={12} sm={12} lg={12}>
                    <Grid container>
                        <Grid md={8} lg={7} sm={8} className="value-item">
                            <div>
                                <div className='value-title'>
                                    <span dangerouslySetInnerHTML={{ __html: head }}></span>
                                    <span className="o-color">...</span>
                                </div>
                                <div className='value-desc' dangerouslySetInnerHTML={{ __html: text }}></div>
                            </div>
                        </Grid>
                        <Grid md={4} lg={5} sm={4}>
                            <div className={`${!image ? 'd-none' : ''} value-bg-img-style`}
                            //  style={{backgroundImage: image}}
                            >
                                {image && <img src={image} alt="bgimage" />}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>)
                :
                (<Grid md={12} sm={12} lg={12}>
                    <Grid container>
                        <Grid md={4} lg={4} sm={4}>
                            <div className={`${image ? '' : 'd-none'} value-bg-img-style`}
                            //  style={{backgroundImage: image}}
                            >
                                {image && <img src={image} alt="bgimage" />}
                            </div>
                        </Grid>
                        <Grid md={8} lg={8} sm={8} className="value-item value-item-left">
                            <div>
                                <div className='value-title'>
                                    <span className="o-color">...</span>
                                    <span dangerouslySetInnerHTML={{ __html: head }}></span>
                                </div>
                                <div className='value-desc' dangerouslySetInnerHTML={{ __html: text }}></div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>)
            }
        </Grid>
    )
}
function Values(props) {
    const { info } = props;
    return (
        <div className='service-page-value'>
            {
                info && info.length &&
                <Grid container rowGap={{ lg: 10, md: 8, sm: 4 }}>
                    {
                        info.map((itemInfo, idx) => {
                            return (
                                <Value key={idx} {...{ ...itemInfo, idx }} />
                            )
                        })
                    }
                </Grid>
            }

        </div >
    );
}
export default Values;
