import { useEffect } from "react";
import '../../css/position.scss'
import JobBanner from "./JobBanner";
import { useParams } from "react-router";
import JobDetail from "./JobDetail";
import PageNotFoundInner from "../PageNotFoundInner";
function Position(props) {

    const params = useParams();
    useEffect(() => {
        props.getJobDetail(params.id)
    }, []);

    const { job, handleApplyJob, applyRes } = props;
    return (
        <div className="root-position-detail">
            {job && job.position && <JobBanner job={job} />}
            {job && job.position && <JobDetail job={job} handleApplyJob={handleApplyJob} applyRes={applyRes} />}
            {job?.error && <PageNotFoundInner />}
        </div>
    )
}

export default Position; 