import { Grid } from '@mui/material';
import * as React from 'react';
import '../../css/reason.scss'
import { useIntl } from 'react-intl';
function ReasonTemplate(props) {
  const { formatMessage } = useIntl();
  const { info: { title, data } } = props;
  return (
    <div className='work-us-main'>
      <div data-aos="fade-up" className='work-us-title fade-el'>{formatMessage({ id: title })}</div>
      <div className='work-us-list'>
        <Grid container>
          {
            data.map(val => {
              return (<Grid container className='work-us-list-item' columnGap={{ lg: 4 }}>
                <Grid data-aos="fade-up" lg={1} className='img-wrapper fade-el'>
                  {val.url}
                </Grid>
                <Grid lg md sm>
                  <div data-aos="fade-up fade-el">
                    <div className='item-title'>{formatMessage({ id: val.title })}</div>
                    <div className='item-desc'>{formatMessage({ id: val.desc })}</div>
                  </div>
                </Grid>
              </Grid>)
            })
          }

        </Grid>
      </div>
    </div >
  );
}
export default ReasonTemplate;
