const ERRORS = {
    GetHomeActiveWorkListFailed: {
        statusCode: 500,
        error: 'GetHomeActiveWorkListFailed',
        message: 'unable to get home active list.'
    },
    GetActiveBlogListFailed: {
        statusCode: 500,
        error: 'GetHomeActiveBlogListFailed',
        message: 'unable to get active blog list.'
    },
    GetWorkListByServiceIdFailed: {
        statusCode: 500,
        error: 'GetWorkListByServiceIdFailed',
        message: 'unable to get work list by service id.'
    }
}

export default ERRORS;