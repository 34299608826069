import * as React from 'react';
import '../../css/banner.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from '@mui/material';
import downArrow from '../../assets/Home/Down.svg'
import CometImg from '../../assets/Home/Comet.svg'
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { getRoutePath } from '../../common/contstants';

var Carousel = require('react-responsive-carousel').Carousel;

function HomeBanner() {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const bannerData = [
        {
            title: "home_banner_slide1_title",
            descrition: "home_banner_slide1_desc",
        },
        {
            title: "home_banner_slide2_title",
            descrition: "home_banner_slide2_desc",

        },
        {
            title: "home_banner_slide3_title",
            descrition: "home_banner_slide3_desc",
        }
    ]
    return (
        <div className='home-banner'>
            <div className='falling-comet-wrapper'>
                <img src={CometImg} className='falling-comet' alt='comet' />
                <img src={CometImg} className='falling-comet' alt='comet' />
                <img src={CometImg} className='falling-comet' alt='comet' />
                <img src={CometImg} className='falling-comet' alt='comet' />
                <img src={CometImg} className='falling-comet' alt='comet' />
                <img src={CometImg} className='falling-comet' alt='comet' />
            </div>
            <Carousel className={localStorage.getItem('locale')} showArrows={false} showThumbs={false} interval={3000} showStatus={false} autoPlay infiniteLoop={false}
            >
                {bannerData.map((banner, index) => {
                    return <div key={index} className='banner-item'>
                        <div className='banner-title'>
                            <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: banner.title }) }}></div>
                        </div>
                        <div>
                            <p data-aos="fade-up" className="banner-desc" dangerouslySetInnerHTML={{ __html: formatMessage({ id: banner.descrition }) }}></p>
                            <Button className='button' onClick={() => navigate(getRoutePath('contact'))}>{formatMessage({id: 'lets_talk_button_text'})}</Button>
                            <div className='down-arrow'>
                                <a href='#partners' alt="Navigate to parners">
                                    <img className='down-arrow-img' src={downArrow} alt="down arrow" />
                                </a>
                            </div>
                        </div>
                    </div>
                })
                }
            </Carousel>
        </div>
    );
}
export default HomeBanner;
