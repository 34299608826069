import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {
  IntlProvider
} from "react-intl";
import './fonts/stylesheet.css';
import { LOCALES } from "./ii8n/locales";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import './App.css';
import Home from './containers/home';
import About from './containers/about';
import Contact from './containers/contact';
import Blog from './containers/blog';
import Services from './containers/services';
import Career from './containers/career';
import Work from './containers/work';
import PageNotFound from './components/PageNotFound';
import PositionDetail from './containers/position-detail';
import Header from './containers/header';
import Footer from './containers/footer';
import GoToTop from './GoToTop';
import AOS from 'aos';
import CaseStudy from './containers/cs';
import PrivacyPolicy from './containers/privacy-policy';
import LegalNotice from './containers/legal-notice';
import Signup from './components/Home/Signup';
import { checkLanguage } from './common/contstants';
import CustomCookieBanner from './components/CustomCookieBanner';
const THEME = createTheme({
  typography: {
    "fontFamily": `Neue Haas Grotesk Display Pro`
  }
});

function App() {

  let locale;

  useEffect(() => {

    AOS.init();
  }, []);


  const [isLoading, setLoading] = useState('no');

  if (isLoading == 'no') {
    checkLanguage()
  }
  const lang = localStorage.getItem('locale');
  if (lang) {
    locale = lang;
  } else {
    locale = LOCALES.ENGLISH;
  }
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [messages, setMessages] = useState(null);
  const withRoot = (Component) => {
    return (
      <>
        <Header locale={currentLocale} switchLocale={setCurrentLocale} loading={setLoading} isLoading={isLoading} />
        <Component />
        <div className='app-footer-main'>
          <Signup />
          <Footer />
        </div>
        <CustomCookieBanner />
      </>
    );
  }

  useEffect(() => {
    const loadMessages = async () => {
      const res = await import(`./ii8n/${currentLocale}.json`);
      return res;
    }
    loadMessages().then(data => {
      setMessages(data)
    })
  }, [currentLocale]);

  return messages && (
    <IntlProvider
      messages={messages}
      locale={currentLocale}
      defaultLocale={LOCALES.ENGLISH}
    >
      <ThemeProvider theme={THEME}>
        <Router>
          <div className='skiy-main '>
            <GoToTop />
            <Routes>
              <Route path="/" element={withRoot(Home)} />
              <Route path="/en" element={withRoot(Home)} />
              <Route path="/services/:id" element={withRoot(Services)} />
              <Route path="/about-us" element={withRoot(About)} />
              <Route path="/work" element={withRoot(Work)} />
              <Route path="/careers" element={withRoot(Career)} />
              <Route path="/contact-us" element={withRoot(Contact)} />
              <Route path="/blog" element={withRoot(Blog)} />
              <Route path="/careers/:id" element={withRoot(PositionDetail)} />
              <Route path='/work/:id' element={withRoot(CaseStudy)} />
              <Route path='/privacy-policy' element={withRoot(PrivacyPolicy)} />
              <Route path='/legal-notice' element={withRoot(LegalNotice)} />
              <Route path="*" element={<PageNotFound />} />

              {/* //Germany */}
              <Route path="/de" element={withRoot(Home)} />
              <Route path="/dienstleistungen/:id" element={withRoot(Services)} />
              <Route path="/ueber-uns" element={withRoot(About)} />
              <Route path="/arbeit" element={withRoot(Work)} />
              <Route path="/Karriere" element={withRoot(Career)} />
              <Route path="/Kontakt" element={withRoot(Contact)} />
              <Route path="/blog-german" element={withRoot(Blog)} />
              <Route path="/Karriere/:id" element={withRoot(PositionDetail)} />
              <Route path='/arbeit/:id' element={withRoot(CaseStudy)} />
              <Route path='/Datenschutzerklärung' element={withRoot(PrivacyPolicy)} />
              <Route path='/Impressum' element={withRoot(LegalNotice)} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
