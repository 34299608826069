import { ActionTypesList } from "../common/contstants";

const homeReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypesList.SUCCESS_FETCH_PARTNERS:
            return { ...state, partners: action.data }
        case ActionTypesList.SUCCESS_CLIENTS_INFO:
            return { ...state, clients: action.data }
        case ActionTypesList.SUBSCRIBE_RESPONSE:
            return { ...state, subscribe: action.data }
        case ActionTypesList.HOME_ACTIVE_WORK_LIST_REQUEST:
            return { ...state, worksList: { isFetching: true, list: [] } }
        case ActionTypesList.HOME_ACTIVE_WORK_LIST_FAILED:
            return { ...state, worksList: { isFetching: true, list: [] } }
        case ActionTypesList.HOME_ACTIVE_WORK_LIST_SUCCESS:
            return { ...state, worksList: { isFetching: true, list: action.data } }
        case ActionTypesList.LANGUAGE_RESPONSE:
            return { ...state, languages: action.data } 
        default:
            return state;
    }
}

export default homeReducer;