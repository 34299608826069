import * as React from 'react';
import '../../css/clients.scss'
import { Grid } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import quotesImg from '../../assets/Home/quotes.svg'
import { useState } from 'react';
import { screenSize } from '../../common/contstants';
import { useIntl } from 'react-intl';

var Carousel = require('react-responsive-carousel').Carousel;

const focusHeight = {
    lg: 230,
    lgs: 200,
    md: 150,
    sm: 130
};
function Clients(props) {
    const { formatMessage } = useIntl();
    const [activeIdx, setActiveIdx] = useState(0);

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 40,
        height: 40,
        cursor: 'pointer',
    };

    const getDefaultPos = () => {
        const screenSizeVal = screenSize();
        return (focusHeight[screenSizeVal] / 2) - 20;
    }
    const navToProfile = (ele) => {
        const focusEle = document.getElementById('client-focus-gradient')
        const data = ele.getBoundingClientRect()
        const parentEle = ele.parentElement.parentElement.getBoundingClientRect()
        const diffWidth = (focusEle.clientWidth - data.width) / 2;
        const left = data.left - parentEle.left - diffWidth;
        const top = data.top - parentEle.top - getDefaultPos();
        focusEle.style = `top:${top}px;left:${left}px`

    }
    const updateCurrentProfile = (idx, item) => {
        if (item && item.target) {
            navToProfile(item.currentTarget)
        } else if (item === 'arrow' || item === 'initial') {
            const currEle = document.getElementsByClassName(`client${idx}`)[0];
            navToProfile(currEle)
        }
        setActiveIdx(idx)
    }
    let clientList = [];
    let partnerList = [];
    let partners = props.partners && props.partners.all ? props.partners.all : props.partners;
    partners.forEach((partner) => {
        const clientInfo = props.clients.find((client) => client.client_id === partner.client_id);
        if (clientInfo) {
            partnerList.push(partner);
            clientList.push(clientInfo);
        }
    })
    return (
        <div className="home-clients">
            <div data-aos="fade-up" className='header fade-el'>
                {formatMessage({ id: 'client_say_text' })}
            </div>
            <Grid container columnGap={{ lg: 12, md: 4, sm: 4 }} rowGap={8} className="client-partners" >
                <Grid item className='grad-container'>
                    <div className={'focus-gradient selected-focus'} style={{ top: `-${getDefaultPos()}px`, left: `0px` }} id="client-focus-gradient">

                    </div>

                </Grid>
                {partnerList.map((imgData, idx) => { 
                    return <Grid 
                            item key={idx} 
                            lg={'auto'} 
                            md={2} xs={12} sm={1.8} 
                            style={{ position: 'relative', display: 'flex', alignItems: 'end' }} 
                            className={`${activeIdx !== idx ? "non-active-logo clients-item" : "clients-item"}`}>
                        <img 
                            src={imgData.client_logo} 
                            className={`cursor profile client${idx} client-img-tag`} 
                            alt="profile" 
                            onClick={(e) => updateCurrentProfile(idx, e)} />
                    </Grid>
                })
                }
            </Grid>
            <div className='client-banner'> 
                <Carousel showArrows={true} interval={3000} showStatus={false} showIndicators={false} infiniteLoop={false}
                    showThumbs={false}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        <div onClick={onClickHandler} className={`client-banner-left-arrow ${!hasPrev ? 'arrow-disable' : ''}`} style={{ ...arrowStyles, left: 15 }}></div>

                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        <div onClick={onClickHandler} className={`client-banner-right-arrow ${!hasNext ? 'arrow-disable' : ''}`} style={{ ...arrowStyles, right: 12 }}></div>
                    }
                    selectedItem={activeIdx}
                    onChange={(e, item) => updateCurrentProfile(e, 'arrow')}
                >
                    {clientList && clientList.map((banner, idx) => {
                        return <div className='banner-item' key={idx}>
                            <Grid container columnGap={{ md: 12, lg: 12, sm: 10 }} className='banner-container'>
                                <Grid item lg={3} md={3.5} sm={3.5} className="client-container">
                                    <img className='client-profile-img' src={banner.image} alt={banner.name} />
                                </Grid>
                                <Grid item lg={7.5} md={6.5} sm={6.25} className="client-desc-container">
                                    <div className='client-banner-main'>
                                        <Grid container className='banner-desc-container'>
                                            <div className='client-quotes-main'>
                                                <img src={quotesImg} className="client-quotes" alt='quotes' />
                                            </div>
                                            <Grid item data-aos="fade-up" className='banner-desc fade-el' dangerouslySetInnerHTML={{ __html: banner.review }}>
                                            </Grid>
                                            <Grid item className='banner-author-main'>
                                                <span data-aos="fade-up" className='banner-author-name fade-el'>{banner.name}</span> <br />
                                                <span data-aos="fade-up" className='banner-author-details fade-el'>{banner.position}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    })
                    }
                </Carousel>
            </div>
        </div>
    );
}
export default Clients;
