import * as React from 'react';
import '../../css/signup.scss'
import { Button, Checkbox, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { clearSubscribeInfo, subscribeNewsLetter } from '../../Actions/home';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { getRoutePath } from '../../common/contstants';

function HomeSignUp(props) {

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [mail, setMail] = useState('');
  const [valid, setValid] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  const updateMail = (e) => {
    setMail(e.target?.value)
    if (isValidEmail(e.target.value)) {
      setValid(true)
    } else {
      setValid(false)
    }
    if (props?.data?.error) {
      props.clearSubscribeInfo();
    }
  }
  const updateTerms = (e) => {
    setChecked(!isChecked)
  }
  const onSubmit = (e) => {
    setSubmitted(true);
    if (valid && isChecked) {
      setbuttonDisable(true)
      props.subscribeNewsLetter(mail)
    }
  }
  const navigateToPolicy = (e) => {
    const link = getRoutePath('privacy-policy');
    navigate(link);
    e.preventDefault();

  }
  return (
    <div className="home-signup">
      <Grid container
        direction="row"
        className='main-content'
        alignItems="top"
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div data-aos="fade-up" className='lbl-signup non-mobile fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'white_paper_text' }) }}>
          </div>
          <div data-aos="fade-up" className='lbl-signup mobile fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'white_paper_text_mobile' }) }}>

          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {!props.data && (<div>
            <div className='txt-mail-wrapper'>
              <TextField className='txt-mail' type='email' variant="outlined" value={mail} onChange={updateMail}
                id="txt-emailid"
                helperText={isSubmitted && !valid && (mail ? formatMessage({ id: 'email_form_validation' }) : formatMessage({ id: 'newsletter_validation' }))}
                error={isSubmitted && !valid}
              />
              <Button className='btn-signup' variant="outlined"
                onClick={onSubmit} disabled={buttonDisable}
              >{formatMessage({ id: 'signup_text' })}</Button>
            </div>
            <div className='terms-conditions-main'>
              <Grid container direction={'row'} className='terms-conditions-main-inner-container'>
                <Grid md sm xs={12}>
                  <div className='terms-conditions-text'>
                    <Checkbox className='chk-terms' value={isChecked} onChange={updateTerms} />
                    <span className='terms-conditions-text-content'>
                      {formatMessage({ id: 'signup_privacy_policy_text' })} &nbsp;
                      <span className='terms-conditions-link' onClick={navigateToPolicy}>
                        {formatMessage({ id: 'signup_privacy_policy_link_text' })}
                      </span>
                      {formatMessage({ id: 'signup_privacy_policy_text_tail' })}
                    </span>

                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="error-msg">
              {isSubmitted && !isChecked && formatMessage({ id: 'terms_validation' })}
            </div>
          </div>)}
          {
            props.data && props.data.message && <div className="success-msg">{props.data.message} </div>
          }
          {
            props.data && props.data.error && <div className="error-msg">{props.data.error}</div>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => ({
  data: state.home.subscribe
})

const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (mail) => dispatch(subscribeNewsLetter(mail)),
  clearSubscribeInfo: () => dispatch(clearSubscribeInfo())
})
export default connect(mapStateToProps, mapDispatchToProps)(HomeSignUp);
