import '../../css/position.scss'
import { Grid } from "@mui/material";
import ApplyJobCard from "./ApplyJob";

function JobDetail(props) {

    const { job, handleApplyJob, applyRes } = props;
    return (
        <div className="root-position-job-detail" id="jobDetail">
            <Grid container columnGap={{lg: 10, md: 5, sm: 5}}>
                <Grid lg={7} md={6} sm={12} xs={12}>
                <div className='job-desc-container' dangerouslySetInnerHTML={{__html: job.descr}}></div>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12}>
                    <ApplyJobCard job={job} handleApplyJob={handleApplyJob} applyRes={applyRes} />
                </Grid>
            </Grid>
        </div>
    )
}

export default JobDetail; 