import * as React from 'react';
import '../../css/cs-highlights.scss'
import StyleOnTouchEvent from '../../common/styleOnTouchEvent';
import { Grid } from '@mui/material';

function ServiceHighlights(props) {

    StyleOnTouchEvent('label.next, label.prev'); 

    // add class to active label(arrow) of carousel slider
    window["setActiveArrow"] = function(lableId) {
        // check if 'active-arrow' class exists in DOM then remove
        document.querySelectorAll('.sl-container label.prev, .sl-container label.next')
            .forEach(el => el.classList.remove('active-arrow'));

        // add 'active-arrow' class to selected label(arrow) element
        document.getElementById(lableId).classList.add('active-arrow');
    }
    

    const { info } = props;
    return (
        <div id='service-highlights' className='cs-highlights'>
            {
                info.map((highLight, index) => {
                    const { title, data, sub_content, list_type, tail_text, data_2 } = highLight;
                    return <div data-aos="fade-up" className='cs-highlight fade-el' key={`cs-highlight-${index}`}>
                        {
                            title && <Grid container  columnGap={4} alignItems={'center'} className='cs-highlight-header'>
                                {info.length > 1 && <Grid ><div className='lt-gradient fade-el' data-aos="fade-up"></div></Grid>} {/* {index + 1} */}
                                <Grid md sm xs>
                                    <div className='title' data-aos="fade-up">{title}</div>
                                </Grid>
                            </Grid>
                        }

                        {sub_content && <div data-aos="fade-up" className='sub_content fade-el' dangerouslySetInnerHTML={{ __html: sub_content }}></div>}
                        {
                            list_type === 'bullet' &&
                            <Grid container direction={'column'} >
                                {
                                    data.map(((val, index) => {
                                        return <Grid container  data-aos="fade-up" key={`cs-major-tasks-b-${index}`} className='task-wrapper fade-el'>
                                            <Grid md={1.5}>
                                                <div className='lt-gradient'></div>
                                            </Grid>
                                            <Grid container md={10} alignItems={'center'} alignContent={'center'} >
                                                <div className='tasks-container'>
                                                    <div className='sub-desc' dangerouslySetInnerHTML={{ __html: val.head }}>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }))
                                }

                            </Grid>
                        }
                        {list_type === 'arrow' &&
                            <Grid container direction={'column'} rowGap={14} className='highlight-section fade-el'>
                                {
                                    data.map(((val, idx) => {
                                        return <Grid data-aos="fade-up" className='fade-el' key={`cs-hl-a-${idx}`}>
                                                <div className='desc' dangerouslySetInnerHTML={{ __html: val.head }}></div>
                                                <Grid container className='cs-hl-rt' columnGap={8}>
                                                    <Grid md={3} className='icon-align'>
                                                        <img src={val.arrow_text} alt="icon" />
                                                    </Grid>
                                                    <Grid md sm className='cs-hl-arrow-content'>
                                                        <div className='sub-desc' dangerouslySetInnerHTML={{ __html: val.text }}>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                        </Grid>
                                    }))
                                }

                            </Grid>
                        }
                        {
                            list_type === 'number_image' &&
                            <Grid container direction={'column'} rowGap={5}>
                                {
                                    data.map(((val, idx) => {
                                        return <Grid key={`cs-hl-ni-${idx}`}>
                                            <div data-aos="fade-up" className={`cs-hl-ni fade-el ${idx % 2 !== 0 ? 'flt-rt' : ''}`}>
                                                <Grid container alignItems={'center'} columnGap={5} className='cs-hl-ni-title'>
                                                    <Grid > <div className='lt-number'><div>{idx + 1}</div></div></Grid>
                                                    <Grid md sm xs>
                                                        <div className='ni-title' dangerouslySetInnerHTML={{ __html: val.head }}></div>
                                                    </Grid>
                                                </Grid>

                                                <img src={val.arrow_text} className='cs-hl-ni-image' alt='arrow text' />
                                                <div className='ni-desc' dangerouslySetInnerHTML={{ __html: val.text }}></div>
                                            </div>
                                        </Grid>
                                    }))
                                }

                            </Grid>
                        }
                        {
                            list_type === 'number' &&
                            <Grid container direction={'column'} className='cs-highlight-conainer'>
                                {
                                    data.map(((val, idx) => {
                                        return <Grid key={`cs-hl-n-${idx}`} className='cs-highlight-conainer-item'>
                                            <div>
                                                <Grid container alignItems={'center'} columnGap={4} className='cs-highlight-wrapper'>
                                                    <Grid><div data-aos="fade-up" className='lt-gradient-number fade-el'><span>{idx + 1}</span></div></Grid>
                                                    <Grid md sm xs>
                                                        <div data-aos="fade-up" className='+++ n-title fade-el' dangerouslySetInnerHTML={{ __html: val.head }}></div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    }))
                                }

                            </Grid>
                        } 
                        {
                            tail_text && <div className='tail_text fade-el' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: tail_text }}></div>
                        }
                        {
                            data_2 && data_2.length > 0 &&
                            <Grid container direction={'row'} columnGap={15} >
                                {
                                    data_2.map(((val, index) => {
                                        return <Grid md={3} key={`key-${index}`} className='gradient-wrapper'>
                                            <div>
                                                <div data-aos="fade-up" className='val-radient fade-el'></div>
                                                <div data-aos="fade-up" className='val-container fade-el'>
                                                    <div className='val'>
                                                        {val.count}
                                                    </div>
                                                    <div className='val-text' dangerouslySetInnerHTML={{ __html: val.text }}>
                                                    </div></div>
                                            </div>

                                        </Grid>
                                    }))
                                }

                            </Grid>
                        }
                    </div>
                })
            }

        </div >
    );
}
export default ServiceHighlights;
