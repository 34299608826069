import { connect } from 'react-redux';
import PositionDetail from '../components/PositionDetail';
import { getJobById, handleApplyJob } from '../Actions/career';

const mapStateToProps = state => ({
    job: state.career.job,
    applyRes: state.career.applyRes
})

const mapDispatchToProps = (dispatch) => ({
    getJobDetail: (id) => dispatch(getJobById(id)),
    handleApplyJob: (payload) => dispatch(handleApplyJob(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(PositionDetail);