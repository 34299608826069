import * as React from 'react';
import downArrow from '../../assets/Home/Down.svg'
import { useIntl } from 'react-intl';
import '../../css/about-banner.scss'

function AboutBanner() {
  const { formatMessage } = useIntl();
  const title = "about_us_title";
  return (
    <div className='about-banner'>
      <div className='about-banner-title'>
        <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: title }) }}></div>
      </div>
      <div data-aos="fade-up" className='about-banner-desc fade-el'>
        {formatMessage({ id: "about_us_desc1" })}<br />
        <div className='about-banner-desc1'>
        {formatMessage({ id: "about_us_desc2" })}
        </div>
      </div>
      <div className='down-arrow'>
        <a href='#focus' alt="Navigate to parners">
          <img className='down-arrow-img' src={downArrow} alt="down arrow" />
        </a>
      </div>
    </div >
  );
}
export default AboutBanner;
