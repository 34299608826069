import React from "react";
import { NextParticle } from "../assets/animation/js/next-particle";

const defaultOptions = {
  mouseForce: "-3",
  particleSize: 2,
  renderer: "webgl",
  color: "#ffffff",
  particleGap: 7,
};

export default function NextParticleAnimation({
  id,
  imageSrc,
  options = {},
  customOptions=true,
  ...props
}) {
  return (
    <img
      src={imageSrc}
      id={id}
      alt='next particle'
      onLoad={(e) => {
        new NextParticle({
          image: document.querySelector(`#${id}`),
          ...(customOptions === true ? { ...options } : { ...defaultOptions }),
          width: e.currentTarget.closest("div").offsetWidth,
          height: e.currentTarget.closest("div").offsetHeight,
        });
      }}
      {...props}
    />
  );
}
