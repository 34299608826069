import * as React from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';
import BasicInfo from './BasicInfo';
import HowCanWeHelp from './HowCanWeHelp';
import '../../css/terms-and-conditions.scss';
import { useIntl } from 'react-intl';
import { HelmetWrapper } from "../HelmetWrapper";
export default function LegalNotice({ infoEN, infoDE }) {
  const { formatMessage } = useIntl();
  const { heading, data } = formatMessage({ id: 'goUp' }) == 'goUpEN' ? infoEN : infoDE;
  const mainInfoData = data.length > 0 ? data[0] : [];
  const basicInfoData = _.get(mainInfoData, 'data', []);
  const remainingData = basicInfoData.length > 2 ? basicInfoData.slice(3, basicInfoData.length) : [];
  let metaData = {
    title: 'legal_page_title',
    description: 'legal_page_description',
    isStatic: true
  }
  return (
    <div>
      <HelmetWrapper data={metaData} />
      <Grid container className='tac-legal-notice'>
        <Grid item className="tac-legal-notice-heading">{heading}</Grid>
        <Grid container>
          {
            basicInfoData.length > 2 ?
              <>
                <Grid container xs={12} sm={6} md={6} lg={6}>
                  <Grid item><BasicInfo info={{ data: [basicInfoData[0]] }} /></Grid>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={6} className='tac-legal-notice-rt-cont'>
                  <Grid item className='tac-legal-notice-rt-cont1'><BasicInfo info={{ data: [basicInfoData[1]] }} /></Grid>
                  <Grid item><BasicInfo info={{ data: [basicInfoData[2]] }} /></Grid>
                </Grid>
              </>
              : ""
          }
          <BasicInfo info={{ data: remainingData }} />
        </Grid>
        <HowCanWeHelp />

      </Grid>
      {/* <HomeSignUp /> */}
    </div>
  )
}