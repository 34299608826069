import * as React from 'react';
import '../../css/blog-banner.scss'
import { useIntl } from 'react-intl';
function BlogBanner() {
  const { formatMessage } = useIntl();
  const title = "blog_banner_title";
  return (
    <div className='blog-banner'>
      <div className='blog-banner-title'>
        <div data-aos="fade-up" className='fade-el' dangerouslySetInnerHTML={{ __html: formatMessage({ id: title }) }}></div>
      </div>
      <div data-aos="fade-up" className='blog-banner-desc fade-el'>
        {formatMessage({ id: "blog_banner_desc" })}
      </div>
    </div >
  );
}
export default BlogBanner;
