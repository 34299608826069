import * as React from 'react';
import '../../css/terms-and-conditions.scss';
import MainInfo from './MainInfo';
import HowCanWeHelp from './HowCanWeHelp';
import { useIntl } from 'react-intl';
import { HelmetWrapper } from "../HelmetWrapper";
export default function PrivacyPolicy({ infoEN, infoDE }) {
  const { formatMessage } = useIntl();
  const { data } = formatMessage({ id: 'goUp' }) == 'goUpEN' ? infoEN : infoDE;
  let metaData = {
    title: 'privacy_page_title',
    description: 'privacy_page_description',
    isStatic: true
  }
  return (
    <div>
      <div className='tac-privacy-policy'>
        <HelmetWrapper data={metaData} />
        {
          data.map((item, index) => {
            return (<MainInfo key={index} info={item} />)
          })
        }
        <HowCanWeHelp />
      </div>
      {/* <HomeSignUp /> */}
    </div>
  );
}